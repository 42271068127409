const state = {
  loader: false,
};
const getters = {
  loading: (state) => state.loader,
};
const actions = {
};
const mutations = {
  showLoader(state) {
    state.loader = true;
  },
  hideLoader(state) {
    state.loader = false;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
