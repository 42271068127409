import axios from "axios";

class SettingsService {
  saveSettings(settings) {
    return axios
      .post("/account/settings", { settings: settings })
      .then((response) => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
}

export default new SettingsService();
