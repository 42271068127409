<template>
  <v-card width="600">
    <v-card-title class="text-h5"
      >{{ id > 0 ? "Редактировать" : "Создать" }} шаблон
      <v-spacer></v-spacer>
      <v-btn style="float: right" icon @click="cancelClick">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title class="text-h5">
      <div class="mh-tabs-div"></div>
      <v-tabs class="mh-demo-tabs" fixed-tabs v-model="currTab">
        <v-tab class="mh-tab preset">Общее</v-tab>
        <v-tab class="mh-tab preset">Фильтры</v-tab>
      </v-tabs>
      <!--      <v-spacer></v-spacer>-->
    </v-card-title>

    <v-card-text style="min-height: 446px" v-if="currTab === 0">
      <v-row class="mt-1">
        <v-col>
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-1"
            hide-details="auto"
            label="Тип"
            mandatory
            v-model="reportType"
            :items="reportTypes"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="имя"
            dense
            outlined
            hide-details="auto"
            v-model="presetName"
            placeholder="Введите имя"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pr-1 pt-0">
          <span class="black--text">Период</span>
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-1"
            hide-details="auto"
            label=""
            mandatory
            v-model="presetPeriod"
            :items="periodsTypes"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" class="pr-1 pl-1 pt-0" v-if="presetPeriod == 'other'">
          <span class="black--text">Последние</span>
          <v-text-field
            label=""
            class="pt-1"
            dense
            :background-color="presetPeriod != 'other' ? 'grey lighten-2' : ''"
            outlined
            hide-details="auto"
            v-model="periodValue"
            type="number"
            placeholder=""
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="pl-1 pt-0" v-if="presetPeriod == 'other'">
          <span class="black--text">&nbsp;</span>
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-1"
            hide-details="auto"
            :background-color="presetPeriod != 'other' ? 'grey lighten-2' : ''"
            label=""
            mandatory
            v-model="presetRepeatType"
            :items="repeatTypes"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="start" class="mt-0">
        <v-col cols="auto" class="mh-first-col">
          <v-btn-toggle multiple dense v-model="workDaysBtns">
            <v-btn
              :x-small="isXSM"
              :small="isMobile && !isXSM"
              v-for="btn in workDays"
              class="mh-noactive-toggle"
              :active-class="'white black--text mh-active-toggle'"
              :key="btn.id"
              :value="btn.id"
              @click="fastBtnsClick(btn)"
            >
              {{ btn.text }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="auto">
          <v-btn-toggle multiple dense v-model="weekDaysBts">
            <v-btn
              :x-small="isXSM"
              :small="isMobile && !isXSM"
              v-for="btn in weekDays"
              :key="btn.id"
              :value="btn.id"
              class="mh-noactive-toggle"
              :active-class="'white black--text mh-active-toggle'"
              @click="fastBtnsClick(btn)"
            >
              {{ btn.text }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row class="mt-n4">
        <v-col>
          <v-checkbox
            :disabled="reportType == 'general'"
            :input-value="schedule"
            v-model="schedule"
            label="Расписание"
            hide-details="auto"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="pr-1">
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-1"
            hide-details="auto"
            :background-color="
              !schedule || sheduleRepeatType == 'day' ? 'grey lighten-2' : ''
            "
            :disabled="!schedule || sheduleRepeatType == 'day'"
            label="день предоставления отчета"
            mandatory
            v-model="sheduleDay"
            :items="days"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>

        <v-col cols="5" class="pl-1 pr-0">
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-1"
            :disabled="!schedule"
            :background-color="!schedule ? 'grey lighten-2' : ''"
            hide-details="auto"
            label="суффикс"
            mandatory
            v-model="suffixType"
            :items="suffixTypes"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="1" class="pl-1">
          <help-btn
            title="Суффикс"
            text="Суффикс будет добавлен каждому новому отчету, сформированному в рамках этого расписания"
          ></help-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pt-1 pr-1">
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-1"
            hide-details="auto"
            label="период предоставления отчета"
            mandatory
            :disabled="!schedule"
            :background-color="!schedule ? 'grey lighten-2' : ''"
            v-model="shedulePeriodType"
            :items="shedulePeriodTypes"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="3"
          class="pr-1 pl-1 pt-1"
          v-if="shedulePeriodType == 'other'"
        >
          <v-text-field
            label=""
            class="pt-1"
            dense
            :background-color="
              !schedule || shedulePeriodType != 'other' ? 'grey lighten-2' : ''
            "
            :disabled="!schedule || shedulePeriodType != 'other'"
            outlined
            hide-details="auto"
            v-model="sheduleRepeatValue"
            type="number"
            placeholder=""
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="pl-1 pt-1" v-if="shedulePeriodType == 'other'">
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-1"
            hide-details="auto"
            :background-color="
              !schedule || shedulePeriodType != 'other' ? 'grey lighten-2' : ''
            "
            :disabled="!schedule || shedulePeriodType != 'other'"
            label=""
            mandatory
            v-model="sheduleRepeatType"
            :items="repeatTypes"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text style="min-height: 446px" v-else>
      <v-row class="mt-1">
        <v-col cols="6" class="pr-1">
          <mh-drop-down
            :items="cities"
            v-model="selectedCityId"
            label="город"
            :item-text="isAdmin ? 'name_admin' : 'name'"
            item-value="num"
            :hide-details="true"
            :multiple="false"
            item-group="name_head2"
          >
          </mh-drop-down>
        </v-col>

        <v-col cols="6" class="pl-1">
          <mh-drop-down
            :items="tvs"
            v-model="selectTVId"
            :disabled="!selectedCityId"
            :label="
              all_channel
                ? 'все телеканалы'
                : selectTVId && selectTVId.length > 1
                ? 'телеканалы'
                : 'телеканал'
            "
            :item-text="isAdmin ? 'ns1_name_admin' : 'ns1_name_ru'"
            item-value="num"
            :multiple="
              this.reportType === 'tvchannels' ||
              this.reportType === 'compare-tv'
            "
            :item-group="isAdmin ? 'ns2_name_admin' : 'ns2_name_ru'"
            @input="checkTvCount"
            :show-select-all="showSelectAll()"
            :select-all-to-null="true"
            @all="allChannel"
          >
          </mh-drop-down>
        </v-col>
      </v-row>
      <v-row
        class=""
        v-if="reportType === 'incomes' || reportType === 'tvshows'"
      >
        <v-col>
          <span class="black--text">Передача</span>
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-1"
            hide-details="auto"
            label="Телепередача"
            mandatory
            v-model="selectProgrammId"
            :items="shows"
            item-text="name"
            item-value="id"
            :multiple="reportType === 'tvshows'"
            @input="selectProgram"
            :show-select-all="reportType === 'show'"
            @all="selectAllProgram"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="6">
          <span class="black--text">Время</span>
          <v-row class="pt-5">
            <v-col cols="4" class="" align-self="center">
              <span class="black--text">Начало</span>
            </v-col>
            <v-col cols="4" class="pr-0 pl-0">
              <v-autocomplete
                full-width
                dense
                no-data-text="Ничего не найдено"
                outlined
                class="pt-1"
                hide-details="auto"
                label="час"
                mandatory
                v-model="startH"
                :items="hours"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="">
              <v-autocomplete
                full-width
                dense
                no-data-text="Ничего не найдено"
                outlined
                class="pt-1"
                hide-details="auto"
                label="мин"
                mandatory
                disabled
                v-model="startMin"
                :items="['00']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" align-self="center">
              <span class="black--text">Конец</span>
            </v-col>
            <v-col cols="4" class="pr-0 pl-0">
              <v-autocomplete
                full-width
                dense
                no-data-text="Ничего не найдено"
                outlined
                class="pt-1"
                hide-details="auto"
                label="час"
                mandatory
                v-model="endH"
                :items="hours"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                full-width
                dense
                no-data-text="Ничего не найдено"
                outlined
                class="pt-1"
                hide-details="auto"
                label="мин"
                disabled
                mandatory
                v-model="endMin"
                :items="['59']"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pt-5">
          <span class="black--text">&nbsp;</span>
          <v-radio-group v-model="through" class="mh-period-type">
            <v-radio label="Сквозной период" :value="'through'"></v-radio>
            <v-radio label="Посуточно" :value="'dayli'"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row
        class=""
        v-if="reportType === 'incomes' || reportType === 'tvshows'"
      >
        <v-col>
          <span class="black--text"
            >Если передачи нет в указанные часы, то</span
          >
          <v-autocomplete
            dense
            no-data-text="Ничего не найдено"
            outlined
            class="pt-5"
            hide-details="auto"
            :background-color="!schedule ? 'grey lighten-2' : ''"
            :disabled="!schedule"
            label="условие"
            mandatory
            v-model="showCondition"
            :items="showConditions"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mb-3"></v-divider>
    <v-card-actions class="pb-5">
      <v-spacer></v-spacer>
      <v-btn
        class="grey lighten-4 mh-tertiary-border"
        outlined
        elevation="0"
        @click="cancelClick"
      >
        Отмена
      </v-btn>
      <v-btn
        color="primary"
        class="mh-primary-border"
        elevation="0"
        @click="okClick"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import HelpBtn from "@/components/HelpBtn.vue";
import MhDropDown from "@/components/MhTvDropDown.vue";
import { mapActions } from "vuex";
import retypeLangRu from "../services/retypeLangRu";
import retypeLangEn from "../services/retypeLangEn";
import axios from "axios";
import moment from "moment";

export default {
  name: "CreatePreset",
  props: ["id"],
  components: { HelpBtn, MhDropDown },
  async mounted() {
    console.log("mounted");
    //this.selectedSourceId = this.storedFilters.selectedSourceId;
    if (
      this.reportType === "tvshows" ||
      this.reportType === "incomes" ||
      this.reportType === "compare-tv-period"
    ) {
      if (Array.isArray(this.storedFilters.selectedTVId)) {
        this.selectTVId = this.storedFilters.selectedTVId[0];
      } else {
        this.selectTVId = this.storedFilters.selectedTVId;
      }
    } else {
      if (Array.isArray(this.storedFilters.selectedTVId)) {
        this.selectTVId = this.storedFilters.selectedTVId;
      } else {
        this.selectTVId = [this.storedFilters.selectedTVId];
      }
    }
    this.selectedCityId = this.storedFilters.selectCityId;

    // this.indicator = this.startIndicator;
    // let startItem = null;
    // for (let j = 0; j < this.indicatorsList.length; j++) {
    //   if (this.indicatorsList[j].visible && !this.indicatorsList[j].disabled) {
    //     startItem = this.indicatorsList[j];
    //     break;
    //   }
    // }

    // = this.indicatorsList.find(el=> el.visible && !el.disabled);
    // this.indicator = startItem.id;
    this.demography = "";
    await this.getSources();
    if (this.cities.length === 0) {
      this.getCities();
    }
    this.init = false;
    if (this.reportType === "tvshows" || this.reportType === "incomes") {
      this.getPrograms();
      if (this.reportType === "tvshows") {
        this.aggregate = "None";
      }
    }
  },
  data() {
    return {
      startH: "00",
      startMin: "00",
      endH: "23",
      endMin: "59",
      through: "through",
      currTab: 0,
      selectedSourceId: 1,
      selectedCityId: null,
      selectProgrammId: null,
      radioHelpersType: "mh",
      selectTVId: null,
      expanded: false,
      all_channel: false,
      manytv: false,
      cities: [],
      tvss: [],
      range: null,
      sources: [],
      shows: [],
      sourceName: "",
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      selectedTV: {
        id: 1,
        name: "",
      },
      selectedCity: {
        id: 1,
        name: "",
      },
      selectedSource: {
        id: 1,
        name: "",
      },
      schedule: false,
      sheduleDay: "mon",
      sheduleRepeatType: null,
      sheduleRepeatValue: null,
      showCondition: "skip",
      showConditions: [
        {
          id: "skip",
          name: "Пропустить отчет",
        },
        // {
        //   id: "best",
        //   name: "Наилучший выбор",
        // },
        {
          name: "Отчет по всем передачам в указанные часы",
          id: "all",
        },
      ],
      days: [
        {
          id: "mon",
          name: "Понедельник",
        },
        {
          id: "tue",
          name: "Вторник",
        },
        {
          id: "wed",
          name: "Среда",
        },
        {
          id: "thu",
          name: "Четверг",
        },
        {
          id: "fri",
          name: "Пятница",
        },
        {
          id: "sat",
          name: "Суббота",
        },
        {
          id: "sun",
          name: "Воскресенье",
        },
      ],
      btns: [
        {
          id: "mon",
          text: "пн",
          value: true,
          day: [2],
        },
        {
          id: "tue",
          text: "вт",
          value: true,
          day: [3],
        },
        {
          id: "wed",
          text: "ср",
          value: true,
          day: [4],
        },
        {
          id: "thu",
          text: "чт",
          value: true,
          day: [5],
        },
        {
          id: "fri",
          text: "пт",
          value: true,
          day: [6],
        },
        {
          id: "sat",
          text: "сб",
          value: true,
          day: [7],
        },
        {
          id: "sun",
          text: "вс",
          value: true,
          day: [1],
        },
        {
          id: "weekdays",
          text: "будни",
          value: true,
          day: [2, 3, 4, 5, 6],
        },
        {
          id: "weekend",
          text: "выходные",
          value: true,
          day: [1, 7],
        },
      ],
      lastBtns: [],
      presetName: "",
      reportType: "general",
      periodValue: "",
      presetPeriod: "today",
      shedulePeriod: "",
      presetRepeatType: "",
      reportTypes: [
        { id: "general", name: "Общий шаблон" },
        { id: "tvchannels", name: "Показатели телеканалов" },
        { id: "compare-tv-period", name: "Анализ программирования" },
        { id: "incomes", name: "Приток-отток" },
        { id: "tvshows", name: "Показатели телепередач" },
        { id: "compare-tv", name: "Сетки вещания" },
      ],
      periodsTypes: [
        { id: "today", name: "Сегодня" },
        { id: "yesterday", name: "Вчера" },
        { id: "curentweek", name: "Текущая неделя" },
        { id: "lastweek", name: "Прошлая неделя" },
        { id: "14days", name: "14 дней" },
        { id: "currentmonth", name: "Текущий месяц" },
        { id: "lastmonth", name: "Прошлый месяц" },
        { id: "other", name: "Другой" },
      ],
      suffixType: "date",
      suffixTypes: [
        { id: "num", name: "Порядковый номер" },
        { id: "date", name: "Дата создания" },
      ],
      repeatTypes: [
        { id: "day", name: "день" },
        { id: "week", name: "неделя" },
        { id: "month", name: "месяц" },
        { id: "quarter", name: "квартал" },
        { id: "year", name: "год" },
      ],
      shedulePeriodTypes: [
        { id: "week", name: "неделя" },
        { id: "month", name: "месяц" },
        { id: "quarter", name: "квартал" },
        { id: "year", name: "год" },
        { id: "other", name: "другой" },
      ],
      shedulePeriodType: "week",
    };
  },
  methods: {
    ...mapActions(["storeFilters", "storeCities", "storeTvs", "storePrograms"]),

    initForm() {
      this.reportType = "general";
      this.presetName = null;
      this.schedule = false;
      this.sheduleDay = "mon";
      this.suffixType = "date";
      this.shedulePeriodType = "week";
      this.sheduleRepeatValue = null;
      this.sheduleRepeatType = null;
      this.showCondition = "skip";

      this.through = "through";
      this.selectProgrammId = null;
      this.selectTVId = null;
      this.selectedCityId = null;
      this.presetRepeatType = "";
      this.periodValue = "";
      this.presetPeriod = "today";
      this.startH = "00";
      this.endH = "23";
      for (let i = 0; i < this.btns.length; i++) {
        this.btns[i].value = true;
      }
    },
    setData(data) {
      console.log(data);

      this.reportType = data.report;
      this.presetName = data.name;
      this.schedule = data.schedule;
      this.sheduleDay = data.schedule_params.sheduleDay;
      this.suffixType = data.schedule_params.suffixType;
      this.shedulePeriodType = data.schedule_params.shedulePeriodType;
      this.sheduleRepeatValue = data.schedule_params.sheduleRepeatValue;
      this.sheduleRepeatType = data.schedule_params.sheduleRepeatType;
      this.showCondition = data.filters.showCondition;

      this.through = data.filters.through ? "through" : "dayli";
      this.selectProgrammId = data.filters.selectProgrammId;
      this.selectTVId = data.filters.selectTVId;
      this.selectedCityId = data.filters.selectedCityId;
      this.presetRepeatType = data.filters.presetRepeatType;
      this.periodValue = data.filters.periodValue;
      this.presetPeriod = data.filters.presetPeriod;
      this.startH = data.filters.startTime.split(":")[0];
      this.endH = data.filters.endTime.split(":")[0];
      for (let i = 0; i < this.btns.length; i++) {
        this.btns[i].value = false;
      }
      for (const d of data.filters.weekdays) {
        this.fastBtnsClick(
          this.btns.find((el) => {
            return el.day.length == 1 && el.day[0] == d;
          })
        );
      }
    },
    async saveFilters() {
      await this.storeFilters({
        selectCityId: this.selectedCityId,
        selectedTVId: this.selectTVId,
        selectProgrammId: this.selectProgrammId,
        selectedSourceId: this.selectedSourceId,
      });
    },
    showSelectAll() {
      return (
        this.reportType !== "tvshows" &&
        this.reportType !== "compare-tv" &&
        this.reportType !== "compare-tv-period" &&
        this.reportType !== "incomes"
      );
    },
    allChannel(e) {
      this.all_channel = e;
      this.selectTVId = null;
    },
    filterAutoComplete(item, queryText, itemText) {
      if (item.header) {
        return true;
      }
      queryText = queryText.toLocaleLowerCase();
      let queryTextRu = retypeLangRu(queryText);
      let queryTextEn = retypeLangEn(queryText);
      itemText = itemText.toLocaleLowerCase();
      return (
        itemText.indexOf(queryTextEn) > -1 || itemText.indexOf(queryTextRu) > -1
      );
    },
    selectProgram() {
      if (this.reportType === "incomes") {
        let prog = this.shows.find(
          (value) => value.id === this.selectProgrammId
        );
        this.$emit("programSelected", prog);
      }
    },
    fastBtnsClick(btn) {
      this.lastBtns = this.btns;
      btn.value = !btn.value;
      let enabled = btn.value;
      if (btn.id.length === 3) {
        if (btn.id === "sun" || btn.id === "sat") {
          this.btns[8].value = this.btns[6].value && this.btns[5].value;
        } else {
          this.btns[7].value =
            this.btns[0].value &&
            this.btns[1].value &&
            this.btns[2].value &&
            this.btns[3].value &&
            this.btns[4].value;
        }
      } else {
        if (btn.id === "weekend") {
          this.btns[5].value = enabled;
          this.btns[6].value = enabled;
        }
        if (btn.id === "weekdays") {
          this.btns[0].value = enabled;
          this.btns[1].value = enabled;
          this.btns[2].value = enabled;
          this.btns[3].value = enabled;
          this.btns[4].value = enabled;
        }
      }
    },
    cancelClick() {
      this.$emit("cancel");
    },
    okClick() {
      let json = {
        id: this.id,
        reportType: this.reportType,
        presetName: this.presetName,
        filtersJson: {
          presetPeriod: this.presetPeriod,
          periodValue: this.periodValue,
          presetRepeatType: this.presetRepeatType,
          weekdays: this.weekdays,
          selectedCityId: this.selectedCityId,
          selectTVId: this.selectTVId,
          selectProgrammId: this.selectProgrammId,
          startTime: `${this.startH}:00`,
          endTime: `${this.endH}:59`,
          through: this.through == "through",
          showCondition: this.showCondition,
        },
        schedule: this.schedule,
        scheduleParams: {
          sheduleDay: this.sheduleDay,
          suffixType: this.suffixType,
          shedulePeriodType: this.shedulePeriodType,
          sheduleRepeatValue: this.sheduleRepeatValue,
          sheduleRepeatType: this.sheduleRepeatType,
        },
      };
      this.$emit("ok", json);
    },

    async checkTvCount() {
      if (
        typeof this.selectTVId !== "string" &&
        this.selectTVId.length > this.maxChannels
      ) {
        this.selectTVId = this.selectTVId.slice(0, this.maxChannels);
      }
    },
    async selectAllProgram(e) {
      this.all_programs = e; //!this.all_programs;

      if (this.all_programs) {
        this.selectProgrammId = [];
        this.shows.forEach((sh) => {
          this.selectProgrammId.push(sh.id);
        });
      } else {
        this.selectProgrammId = [];
      }
    },
    async getPrograms() {
      this.selectProgrammId = null;

      let api_url = "/params/programs";
      if (this.reportType === "incomes") {
        api_url = "/params/fullprograms";
      }
      if (this.selectTVId) {
        let res = await axios.post(api_url, {
          city_id: this.selectedCityId,
          tv_id: this.selectTVId,
          startDate: "2022-01-01",
          startTime: `${this.startH}:00`,
          endDate: moment().format("YYYY-MM-DD"),
          endTime: `${this.endH}:00`,
        });
        if (res.data) {
          this.shows = [...res.data];
          this.$emit("shows", this.shows);
          // await this.storePrograms(this.shows);
          if (this.all_programs) {
            await this.selectAllProgram(this.all_programs);
          }
        }
      }
    },
    async getTvs() {
      //this.selectTVId = null;
      console.log("get TVS");
      let res = await axios.post("/params/tvs", {
        source_id: this.selectedSourceId,
        helper: this.radioHelpersType,
        city_id: this.selectedCityId,
      });
      if (res.data && Array.isArray(res.data)) {
        // console.log(res.data, res.data.length);
        this.tvss = [...res.data];
        for (const item of this.tvss) {
          item.export_name = this.isAdmin
            ? item.ns1_name_admin
            : item.ns1_name_ru;
        }
        this.$emit("tvList", this.tvss);
        await this.storeTvs(this.tvss);
      }
    },
    async getCities() {
      let res = await axios.post("/params/cities", {
        source_id: this.selectedSourceId,
        helper: this.radioHelpersType,
      });
      if (res.data && Array.isArray(res.data)) {
        this.cities = [...res.data];
        this.$emit("cities", this.cities);
        await this.storeCities(this.cities);
      }
    },
    async getSources() {
      this.cities = this.$store.getters.getCitiesList;
      this.$emit("cities", this.cities);
      this.tvss = this.$store.getters.getTvsList;
      this.$emit("tvList", this.tvss);
      let res = await axios.post("/params/sources");
      if (res.data) {
        this.sources = [...res.data];
        this.$emit("sources", this.sources);
        this.selectedSourceId = this.sources[0].id;
      }
    },
  },
  computed: {
    weekdays() {
      let days = [];
      this.btns.forEach((btn) => {
        if (btn.value) {
          days.push(...btn.day);
        }
      });
      days = [...new Set(days)];
      return days;
    },
    storedFilters() {
      return this.$store.getters.getFilters;
    },
    tvs() {
      if (this.all_channel) {
        return [];
      }
      let items = this.tvss;
      if (
        (this.reportType === "tvshows" ||
          this.reportType === "compare-tv" ||
          this.reportType === "compare-tv-period") &&
        this.selectedCityId == 1143
      ) {
        items = items.filter((el) => {
          return el.mplex === null && el.reg === null;
        });
      }
      return items;
    },
    selectTvCount() {
      if (typeof this.selectTVId !== "string") {
        if (this.selectTVId === null) {
          return 0;
        }
        return this.selectTVId.length;
      }
      return 1;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isXSM() {
      return this.$vuetify.breakpoint.width < 375;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    weekDaysBts() {
      return this.weekDays
        .filter((el) => el.value)
        .map((el) => {
          return el.id;
        });
    },
    workDaysBtns() {
      return this.workDays
        .filter((el) => el.value)
        .map((el) => {
          return el.id;
        });
    },
    workDays() {
      return this.btns.slice(0, 7);
    },
    weekDays() {
      return this.btns.slice(7);
    },
  },
  watch: {
    selectedCityId(val, oldval) {
      // console.log('were', oldval, 'now', val)

      if (val !== oldval && !this.init) {
        this.selectTVId = null;
      }
      this.getTvs();
      // this.all_programs = false;

      if (this.reportType === "tvshows" || this.reportType === "incomes") {
        // this.getPrograms();
      }
      this.saveFilters();
    },

    selectTVId() {
      // this.all_programs = false;
      if (this.selectTVId == "") {
        this.selectTVId = null;
        // if (this.indicator === "TVRTotal") {
        //   this.indicator = "TVR";
        // }
      }
      if (
        typeof this.selectTVId !== "string" &&
        this.selectTVId.length > this.maxChannels
      ) {
        this.reportIndex = 0;
        this.manytv = true;
      } else {
        this.manytv = false;
      }
      if (this.reportType === "tvshows" || this.reportType === "incomes") {
        this.getPrograms();
        this.manytv = false;
      }
      this.saveFilters();
    },
    all_channel() {
      if (this.all_channel) {
        this.manytv = true;
        this.reportIndex = 0;
      } else {
        this.manytv = false;
      }
    },
    presetPeriod(val) {
      if (val == "other") {
        this.periodValue = 1;
        this.presetRepeatType = "day";
      } else {
        this.periodValue = null;
        this.presetRepeatType = null;
      }
    },
    shedulePeriodType(val) {
      if (val == "other") {
        this.sheduleRepeatValue = 1;
        this.sheduleRepeatType = "day";
      } else {
        this.sheduleRepeatValue = null;
        this.sheduleRepeatType = null;
      }
    },
  },
};
</script>

<style scoped></style>
