<template>
  <div class="modal" v-if="$store.getters.loading === true">
<!--     <lottie-animation
      path="img/animation_loader_estim-int.json"
      :height="107"
      :width="188"
    /> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8) url("../assets/loading.gif") 50% 50%
    no-repeat;
}
</style>
