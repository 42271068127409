<template>
  <v-dialog v-model="dialog" max-width="350" persistent>
    <v-card>
      <v-card-title class="text-h5"> Имя отчета</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="reportname"
          dense
          class="mt-4 mb-0 pb-0"
          label="Имя"
          placeholder="Введите имя отчета"
          outlined
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="reportname.length < 1"
          color="primary"
          elevation="0"
          class="mh-primary-border"
          dense
          @click="saveReportName"
        >
          Сохранить
        </v-btn>
        <v-btn
            class="grey lighten-4 mh-tertiary-border"
            outlined
            elevation="0"
            @click="cancelClick"
        >
          Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "ReportNameDialog",
  props: ["dialog", "reportname", "uuid"],
  data() {
    return {};
  },
  methods: {
    async saveReportName() {
      let req = new FormData();
      req.append("reportname", this.reportname);
      let uuid = this.$route.params.uuid ?? this.uuid;
      await axios.post("name/" + uuid, req);
      this.$emit("save", this.reportname);
      this.dialog = false;
    },
    cancelClick() {
      this.dialog = false;
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
