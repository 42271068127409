const isAlternate = process.env.VUE_APP_ALTERNATE_UI == "true";
const mainTitle = process.env.VUE_APP_APP_NAME;

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import store from "../store";
import LoginView from "@/views/LoginView.vue";
import Indicators from "@/views/Indicators.vue";
import TvShows from "@/views/TvShows.vue";
import Page404 from "@/components/404.vue";
import IncomesOutcomes from "@/views/IncomesOutcomes.vue";
import CompareTVs from "@/views/CompareTVs.vue";
import CompareTVPeriod from "@/views/CompareTVPeriod.vue";

Vue.use(VueRouter);

let routes = [
  {
    path: "*",
    name: "bad-not-found",
    component: Page404,
    meta: { requiresAuth: true, noFooter: true },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
  },

  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Login | " + mainTitle,
    },
  },
];

const altRoutes = [
  {
    path: "/cast/incomes",
    name: "incomes",
    component: IncomesOutcomes,
    meta: {
      requiresAuth: true,
      title: "Приток-отток зрителей | " + mainTitle,
    },
  },
  {
    path: "/cast/incomes/:uuid",
    name: "incomes-uuid",
    component: IncomesOutcomes,
    meta: {
      requiresAuth: true,
      title: "Приток-отток зрителей | " + mainTitle,
    },
  },
  {
    path: "/cast/compare/tv",
    name: "compare-tv",
    component: CompareTVs,
    props: {},
    meta: {
      requiresAuth: true,
      title: "Сетки вещания | " + mainTitle,
    },
  },
  {
    path: "/cast/compare/tv/:uuid",
    name: "compare-tv-uuid",
    component: CompareTVs,
    props: {},
    meta: {
      requiresAuth: true,
      title: "Сетки вещания | " + mainTitle,
    },
  },

  {
    path: "/cast/compare/period",
    name: "compare-tv-period",
    component: CompareTVPeriod,
    props: {},
    meta: {
      requiresAuth: true,
      title: "Анализ программирования | " + mainTitle,
    },
  },
  {
    path: "/cast/compare/period/:uuid",
    name: "compare-tv-period-uuid",
    component: CompareTVPeriod,
    props: {},
    meta: {
      requiresAuth: true,
      title: "Анализ программирования | " + mainTitle,
    },
  },

  {
    path: "/tv",
    name: "indicators",
    component: Indicators,
    meta: {
      requiresAuth: true,
      title: "Показатели телеканалов | " + mainTitle,
    },
  },
  {
    path: "/tv/:uuid",
    name: "indicators-uuid",
    component: Indicators,
    meta: {
      requiresAuth: true,
      title: "Показатели телеканалов | " + mainTitle,
    },
  },
  {
    path: "/cast/tvshows",
    name: "tvshows",
    component: TvShows,
    meta: {
      requiresAuth: true,
      title: "Показатели телепередач | " + mainTitle,
    },
  },
  {
    path: "/cast/tvshows/:uuid",
    name: "tvshows-uuid",
    component: TvShows,
    meta: {
      requiresAuth: true,
      title: "Показатели телепередач | " + mainTitle,
    },
  },
];

// сюда добавлять новые роуты для ЛК
const fullRoutes = [
  {
    path: "/admin/users",
    name: "users",
    component: () => import("@/views/AdminUsers.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/accounts",
    name:"account",
    component: () => import("@/views/AdminAccount.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/my",
    name: "my",
    component: () => import("@/views/MyReports.vue"),
    meta: {
      title: "Мои отчеты | " + mainTitle,
      requiresAuth: true,
      noFooter: true,
    },
  },
  {
    path: "/online",
    name: "online",
    component: () =>
      import(`@/views/${isAlternate ? "Empty" : "OnlineReport"}.vue`),
    meta: {
      requiresAuth: true,
      title: "ТОП-20 Телеканалов | " + mainTitle,
      help: {
        title: "Онлайн отчет ТОП-20",
        text:
          "Онлайн-отчет показывает предварительные данные наиболее популярных телеканалов за последние 24 часа.<br />" +
          "<br />" +
          "Основой для телеизмерений являются RPD данные более 250 операторов связи. Статистика о переключениях телеканалов собирается на серверах и обрабатывается в соответствии с <a href='https://mediahills.ru/telemetering/methodology'>Методологией MediaHills</a>. Данные в отчете отображаются с задержкой от текущего времени. Для обновления онлайн-отчета нажмите кнопку обновления в браузере.",
        subtitle: "Показатели",
        text2:
          "<b>Доля (Share)</b> - количество зрителей телепередачи или телеканала, выраженное в процентах (%) от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Рейтинг (TVR)</b> - среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+",
        img: null,
      },
    },
  },
  {
    path: "/advertising",
    name: "advertising",
    component: () => import("@/components/InDevelop.vue"),
    props: {
      text: "Раздел «Реклама» построит отчет по выбранным категориям рекламы на телеканале.",
    },
    meta: { requiresAuth: true, noFooter: true },
  },
  {
    path: "/help",
    name: "help",
    component: () => import(`@/views/${isAlternate ? "Empty" : "Help"}.vue`),
    meta: {
      requiresAuth: true,
      title: "Справка | " + mainTitle,
      noFooter: true,
    },
  },
  {
    path: "/views/devices",
    name: "devices",
    component: () => import("@/views/DevicesView.vue"),
    props: {
      page: "equipment",
    },
    meta: {
      requiresAuth: true,
      title: "Типы просмотров - По оборудованию | " + mainTitle,
      help: {
        title: "Типы просмотров - По оборудованию",
        text: "Отчет «По оборудованию» показывает соотношение устройств, с которых просматривали контент в тот или иной момент в указанном городе.",
        subtitle: "Показатели",
        text2:
          "Web, Операторская приставка, Smart TV, Планшет, Телефон, Apple TV, Медиаплеер",
        img: null,
      },
    },
  },
  {
    path: "/views/ratio",
    name: "ratio",
    component: () => import("@/views/TypesView.vue"),
    props: {
      page: "view-type",
    },
    meta: {
      requiresAuth: true,
      title: "Типы просмотров - Live / Archive | " + mainTitle,
      help: {
        title: "Типы просмотров - Live / Archive",
        text: "Отчет Live / Archive показывает соотношение видов просмотров определенного контента в тот или иной момент в указанном городе.",
        subtitle: "Показатели",
        text2:
          "<b>Live</b> – просмотры прямого эфира, просмотры в процессе вещания, “вживую”.<br />" +
          "<b>Archive</b> – просмотры в записи, через архив",
        img: null,
      },
    },
  },
  {
    path: "/reach",
    name: "reach",
    component: () => import("@/views/Reach.vue"),
    props: {
      text: "«Накопленный охват» покажет процент зрителей, видевших ту или иную передачу хотя бы один раз за период.",
    },
    meta: { requiresAuth: true, title: "Накопленный охват | " + mainTitle },
  },
  {
    path: "/queues",
    name: "queues",
    component: () => import("@/views/Queues.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/content_producers",
    name: "content_producers",
    component: () => import("@/views/ContentProducers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/stories/news",
    name: "stories-news",
    component: () => import("@/views/PresidentShows.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/special/tv/:type",
    name: "special-tv",
    component: () => import("@/views/SpecialTV.vue"),
    meta: {
      requiresAuth: true,
      title: "ТВ-показатели | " + mainTitle,
      help: {
        title: "ТВ-показатели",
        text:
          "Дашборд «ТВ-показатели» представляет данные по телеканалу за выбранный относительный период в графиках:<br/>" +
          "- Сравнение регионов по доле телесмотрения новостых передач<br/>" +
          "- Телесмотрение новостных сюжетов и раскладка по ним<br/>" +
          "<br /><p class='caption mh_caption'>Сравнение регионов по доле телесмотрения новостных передач</p>" +
          "График доступен в отображении плиткой или географических границ регионов.<br />" +
          "Этот график берет данные за выбранный период (Вчера, Неделя, Месяц, Год) и сравнивает их с тем, что вы выбираете в меню:<br />" +
          "- <b>Регионы РФ</b>: показывает региональное значение доли телесмотрения новостных передач в каждом регионе<br />" +
          "- <b>«Россия 1»</b>: отношение доли телесмотрения новостных передач к доле телесмотрения всех передач в регионе<br />" +
          "- <b>Прошлый период</b>: сравнивает данные о телесмотрении в регионе с данными того же региона в прошлом за 30 дней<br />" +
          "Справа от карты расположен слайдер выпуска новостей. На нем можно выбрать, какие выпуски в течение дня будут браться для сравнения.<br />",
        subtitle: "Телесмотрение новостей",
        text2:
          "В графике можно выбрать показатель: Аудитория, Доля, Рейтинг, Охват.<br />" +
          "Нажатие на новость в графике построит еще два графика по этой новости: Поминутная детализация телепередачи и Приток-отток зрителей. Также будет доступен список интернет-новостей из этого региона за выбранный период.<br />" +
          "<br />",
        img: null,
      },
    },
  },
  {
    path: "/special/internet",
    name: "special-internet",
    component: () => import("@/views/SpecialInternet.vue"),
    meta: {
      requiresAuth: true,
      title: "Интернет-показатели | " + mainTitle,
      help: {
        title: "Интернет-показатели",
        text:
          "Дашборд «Интернет-показатели» представляет информацию по телеканалу за выбранный относительный период. Выбрать период можно сверху экрана, доступные опции: Вчера, Сегодня, Неделя, Месяц, Год.<br/>" +
          "<br /><p class='caption mh_caption'>Тепловая карта регионов</p>" +
          "Тепловая карта представлена в виде плитки и в виде геогрфических границ регионов.<br />" +
          "По умолчанию отображаются данные для всей России. Чтобы посмотреть данные для конкретного региона, необходимо нажать на него. Чтобы вернуться к просмотру всей РФ, нужно нажать на кнопку на появившемся рыжем баннере Смотреть всю Россию.<br />" +
          "Тепловое окрашивание показывает отклонение показателя региона от среднего значения показателя за выбранный период.<br />" +
          "<br /><p class='caption mh_caption'>Самые читаемые новости</p>" +
          "<b>Показы</b> строятся по количеству показов новостей (было зафиксировано открытие старницы новости).<br />" +
          "<b>Устройства</b> — такой график строится на раскладке новости по устройствам (десктоп, планшет или смартфон), с которых они были просмотрены.<br />" +
          "По умолчанию отображаются самые читаемые новости по всем темам. Чтобы посмотреть наиболее популярные новостные сюжеты по конкретной теме, нажмите на интересующую тему на графике Упоминаемые темы.<br />" +
          "Нажатие на новость в этом графике откроет источник этой новости.<br />",
        subtitle: "Упоминаемые темы",
        text2:
          "График показывает визуальное представление частоты упоминаемых тегов для новостей. Чем чаще новость упоминалась, тем крупнее она отображается на этом графике.<br />" +
          "<br /><p class='caption mh_caption'>Типы используемых устройств</p>" +
          "Инфографика состоит из двух частей: раскладка по устройствам и раскладка по производителям.<br />" +
          "<br /><p class='caption mh_caption'>ТОП городов по доле посещения</p>" +
          "График состоит из двух частей: ТОП городов и типы населенных пунктов. Показывает долю городов, из которых пользователи смотрят новости на сайте.<br />",
        img: null,
      },
    },
  },
  {
    path: "/special/tv-internet",
    name: "special-tv-internet",
    component: () => import("@/components/InDevelop.vue"),
    meta: {
      requiresAuth: true,
      title: "Интернет+ТВ | " + mainTitle,
      help: {
        title: "Интернет+ТВ",
        text:
          "Отчет «Показатели телеканалов» позволяет увидеть показатели одного, нескольких или всех телеканалов (для сравнения их между собой). Построить график можно для 12 телеканалов.<br/>" +
          "<br /><p class='caption mh_caption'>Режимы работы</p>" +
          "<b>Детализация</b> работает в двух режимах:<br />" +
          "- От 1 минуты до 60 минут - можно выбрать вывод По дням или За период. За период означает, что данные в один и тот же час за каждые сутки в периоде будут обобщены в среднее значение за этот час.<br />" +
          "- Установка ползунка на День, Неделю, Месяц и Год будет агрегировать данные в соответствии с названием<br />",
        subtitle: "Показатели",
        text2:
          "<b>Аудитория (Audience) </b>- среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR) </b>- среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share) </b>- количество зрителей телепередачи или телеканала, выраженное в процентах (%) от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %) </b>- количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз<br />" +
          "<br />",
        img: null,
      },
    },
  },

  {
    path: "/cast/incomes",
    name: "incomes",
    component: IncomesOutcomes,
    meta: {
      requiresAuth: true,
      title: "Приток-отток зрителей | " + mainTitle,
      help: {
        title: "Приток-отток телезрителей",
        text:
          "«Приток-отток зрителей» показывает движение потока зрителей на телеканале." +
          "<br />" +
          "График отображает положительную величину пришедших на телеканал зрителей во время эфира данной телепередачи (зеленый показатель) и отрицательную величину ушедших зрителей (рыжий показатель).",
        subtitle: null,
        text2: null,
        img: null,
      },
    },
  },
  {
    path: "/cast/incomes/:uuid",
    name: "incomes-uuid",
    component: IncomesOutcomes,
    meta: {
      requiresAuth: true,
      title: "Приток-отток зрителей | " + mainTitle,
      help: {
        title: "Приток-отток телезрителей",
        text:
          "«Приток-отток зрителей» показывает движение потока зрителей в рамках одной телепередачи.<br />" +
          "<br />" +
          "График отображает положительную величину пришедших на телеканал зрителей во время эфира данной телепередачи (синий показатель) и отрицательную величину ушедших зрителей (красный показатель).",
        subtitle: null,
        text2: null,
        img: null,
      },
    },
  },
  {
    path: "/cast/compare/tv",
    name: "compare-tv",
    component: CompareTVs,
    props: {
      text: "Этот раздел позволит выбрать несколько телеканалов и сравнить их сетку вещания за один день. Также можно отследить динамику выбранного показателя в течение дня на каждом канале.",
    },
    meta: {
      requiresAuth: true,
      title: "Сетки вещания | " + mainTitle,
      help: {
        title: "Сетки вещания",
        text:
          "Отчет позволяет сравнить эфирную сетку нескольких телеканалов за один день.<br />" +
          "<br /><p class='caption mh_caption'>Информация из графика</p>" +
          "<b>Блок телепередачи</b> - рамка с названием телепередачи и средним значением выбранного показателя, ограничивающая продолжительность этой телепередачи на временной шкале<br />" +
          "<b>Тип телепередачи</b> - окраска блока.<br />" +
          "<b>Среднее значение показателя</b> - пишется внутри блока телепередачи<br />" +
          "<b>Детализированное значение показателя</b> - справа от блока телепередачи отображается значение показателя за 15-минутный временной интервал. Чтобы отобразить его, включите настройку “Показать значения”<br />" +
          "<b>Временная шкала</b> отображает интервал в 15 минут, например, 00:00 - 00:14 означает, что последняя минута 14 рассчитывается включительно с 00 секунд до 59 секунд",
        subtitle: "Показатели",
        text2: isAlternate
          ? ""
          : "<b>Аудитория (Audience)</b> - среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR)</b> - среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share)</b> - количество зрителей телепередачи или телеканала, выраженное в процентах (%) от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз",
        img: null,
      },
    },
  },
  {
    path: "/cast/compare/tv/:uuid",
    name: "compare-tv-uuid",
    component: CompareTVs,
    props: {
      text: "Этот раздел позволит выбрать несколько телеканалов и сравнить их сетку вещания за один день. Также можно отследить динамику выбранного показателя в течение дня на каждом канале.",
    },

    meta: {
      requiresAuth: true,
      title: "Сетки вещания | " + mainTitle,
      help: {
        title: "Сетки вещания",
        text:
          "Отчет позволяет сравнить эфирную сетку нескольких телеканалов за один день.<br />" +
          "<br /><p class='caption mh_caption'>Информация из графика</p>" +
          "<b>Блок телепередачи</b> - рамка с названием телепередачи и средним значением выбранного показателя, ограничивающая продолжительность этой телепередачи на временной шкале<br />" +
          "<b>Тип телепередачи</b> - окраска блока.<br />" +
          "<b>Среднее значение показателя</b> - пишется внутри блока телепередачи<br />" +
          "<b>Детализированное значение показателя</b> - справа от блока телепередачи отображается значение показателя за 15-минутный временной интервал. Чтобы отобразить его, включите настройку “Показать значения”<br />" +
          "<b>Временная шкала</b> отображает интервал в 15 минут, например, 00:00 - 00:14 означает, что последняя минута 14 рассчитывается включительно с 00 секунд до 59 секунд",
        subtitle: "Показатели",
        text2: isAlternate
          ? ""
          : "<b>Аудитория (Audience)</b> - среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR)</b> - среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share)</b> - количество зрителей телепередачи или телеканала, выраженное в процентах (%) от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз",
        img: null,
      },
    },
  },

  {
    path: "/cast/compare/period",
    name: "compare-tv-period",
    component: CompareTVPeriod,
    props: {
      text: "Анализ программирования покажет данные по сетке вещания одного телеканала в разрезе нескольких дней. Окрашивание показателей от лучшего к худшему позволит увидеть слабые и сильные места в своем телеканале или телеканале конкурента и отталкиваться от них в построении стратегии развития.",
    },
    meta: {
      requiresAuth: true,
      title: "Анализ программирования | " + mainTitle,
      help: {
        title: "Анализ программирования",
        text:
          "Этот отчет позволяет сравнить показатели телепередач, выходивших на одном телеканале, в течение нескольких дней.<br />" +
          "<br /><p class='caption mh_caption'>Информация из графика</p>" +
          "<b>Блок телепередачи </b>- Рамка с названием телепередачи и средним значением выбранного показателя, ограничивающая продолжительность этой телепередачи на временной шкале. График не показывает блок телепередачи, если она началась до начала календарных суток.<br />" +
          "<b>Среднее значение показателя </b>- пишется внутри блока телепередачи<br />" +
          "<b>Детализированное значение показателя</b> - справа от блока телепередачи можно включить отображение значения показателя за 15-минутный временной интервал. Чтобы отобразить его, включите настройку “Показать значения”<br />" +
          "<b>Временная шкала</b> отображает интервал в 15 минут, например, 00:00 - 00:14 означает, что последняя минута 14 рассчитывается включительно с 00 секунд до 59 секунд.",
        subtitle: "Показатели",
        text2: isAlternate
          ? ""
          : "<b>Аудитория (Audience)</b> - среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR)</b> - среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share)</b> - количество зрителей телепередачи или телеканала, выраженное в процентах (%) от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз<br />" +
          "<br />" +
          "Показатель <b>Сводный </b>строит отчет только в виде таблицы (без разделения на отдельные блоки телепередач).",
        img: null,
      },
    },
  },
  {
    path: "/cast/compare/period/:uuid",
    name: "compare-tv-period-uuid",
    component: CompareTVPeriod,
    props: {
      text: "Анализ программирования покажет данные по сетке вещания одного телеканала в разрезе нескольких дней. Окрашивание показателей от лучшего к худшему позволит увидеть слабые и сильные места в своем телеканале или телеканале конкурента и отталкиваться от них в построении стратегии развития.",
    },
    meta: {
      requiresAuth: true,
      title: "Анализ программирования | " + mainTitle,
      help: {
        title: "Анализ программирования",
        text:
          "Этот отчет позволяет сравнить показатели телепередач, выходивших на одном телеканале, в течение нескольких дней.<br />" +
          "<br /><p class='caption mh_caption'>Информация из графика</p>" +
          "<b>Блок телепередачи </b>- Рамка с названием телепередачи и средним значением выбранного показателя, ограничивающая продолжительность этой телепередачи на временной шкале. График не показывает блок телепередачи, если она началась до начала календарных суток.<br />" +
          "<b>Среднее значение показателя </b>- пишется внутри блока телепередачи<br />" +
          "<b>Детализированное значение показателя</b> - справа от блока телепередачи можно включить отображение значения показателя за 15-минутный временной интервал. Чтобы отобразить его, включите настройку “Показать значения”<br />" +
          "<b>Временная шкала</b> отображает интервал в 15 минут, например, 00:00 - 00:14 означает, что последняя минута 14 рассчитывается включительно с 00 секунд до 59 секунд.",
        subtitle: "Показатели",
        text2: isAlternate
          ? ""
          : "<b>Аудитория (Audience)</b> - среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR)</b> - среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share)</b> - количество зрителей телепередачи или телеканала, выраженное в процентах (%) от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз<br />" +
          "<br />" +
          "Показатель <b>Сводный </b>строит отчет только в виде таблицы (без разделения на отдельные блоки телепередач).",
        img: null,
      },
    },
  },

  {
    path: "/tv",
    name: "indicators",
    component: Indicators,
    meta: {
      requiresAuth: true,
      title: "Показатели телеканалов | " + mainTitle,
      help: {
        title: "Показатели телеканалов",
        text:
          "Отчет «Показатели телеканалов» позволяет увидеть показатели одного, нескольких или всех телеканалов (для сравнения их между собой). Построить график можно для 12 телеканалов.<br/>" +
          "<br /><p class='caption mh_caption'>Режимы работы</p>" +
          "<b>Детализация</b> работает в двух режимах:<br />" +
          "- От 1 минуты до 60 минут - можно выбрать вывод По дням или За период. За период означает, что данные в один и тот же час за каждые сутки в периоде будут обобщены в среднее значение за этот час.<br />" +
          "- Установка ползунка на День, Неделю, Месяц и Год будет агрегировать данные в соответствии с названием<br />",
        subtitle: "Показатели",
        text2: isAlternate
          ? ""
          : "<b>Аудитория (Audience) </b>- среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR) </b>- среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share) </b>- количество зрителей телепередачи или телеканала, выраженное в процентах (%) от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %) </b>- количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз<br />" +
          "<br />",
        img: null,
      },
    },
  },
  {
    path: "/tv/:uuid",
    name: "indicators-uuid",
    component: Indicators,
    meta: {
      requiresAuth: true,
      title: "Показатели телеканалов | " + mainTitle,
      help: {
        title: "Показатели телеканалов",
        text:
          "Отчет «Показатели телеканалов» позволяет увидеть показатели одного, нескольких или всех телеканалов (для сравнения их между собой). Построить график можно для 12 телеканалов.<br/>" +
          "<br /><p class='caption mh_caption'>Режимы работы</p>" +
          "<b>Детализация</b> работает в двух режимах:<br />" +
          "- От 1 минуты до 60 минут - можно выбрать вывод По дням или За период. За период означает, что данные в один и тот же час за каждые сутки в периоде будут обобщены в среднее значение за этот час.<br />" +
          "- Установка ползунка на День, Неделю, Месяц и Год будет агрегировать данные в соответствии с названием<br />",
        subtitle: "Показатели",
        text2: isAlternate
          ? ""
          : "<b>Аудитория (Audience) </b>- среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR) </b>- среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share) </b>- количество зрителей телепередачи или телеканала, выраженное в процентах (%) от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %) </b>- количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз<br />" +
          "<br />" +
          "<b>Демография</b> доступна только при выборе показателя Аудитория.",
        img: null,
      },
    },
  },
  {
    path: "/cast/tvshows",
    name: "tvshows",
    component: TvShows,
    meta: {
      requiresAuth: true,
      title: "Показатели телепередач | " + mainTitle,
      help: {
        title: "Показатели телепередач - Аудиторные показатели",
        text: "Отчет «Показатели телепередач» позволяет увидеть показатели одной, нескольких или всех телепередач в рамках одного телеканала. Для нескольких телепередач действует ограничение в пять одновременно анализируемых телепередач.",
        subtitle: "Показатели",
        text2: isAlternate
          ? ""
          : "<b>Аудитория (Audience) </b>- среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR)</b> - среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share)</b> - количество зрителей телепередачи или телеканала, выраженное в процентах (%), от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз<br />" +
          "<br />" +
          "Показатель <b>Сводный</b> строит отчет только в виде таблицы.",
        img: null,
      },
    },
  },
  {
    path: "/cast/tvshows/:uuid",
    name: "tvshows-uuid",
    component: TvShows,
    meta: {
      requiresAuth: true,
      title: "Показатели телепередач | " + mainTitle,
      help: {
        title: "Показатели телепередач - Аудиторные показатели",
        text: "Отчет «Показатели телепередач» позволяет увидеть показатели одной, нескольких или всех телепередач в рамках одного телеканала. Для нескольких телепередач действует ограничение в пять одновременно анализируемых телепередач.",
        subtitle: "Показатели",
        text2: isAlternate
          ? ""
          : "<b>Аудитория (Audience) </b>- среднее количество зрителей, смотревших телеканал на протяжении анализируемого временного интервала, выраженное в тысячах человек. По умолчанию рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Рейтинг (TVR)</b> - среднее количество зрителей, смотревших телепередачу или телеканал, выраженное в процентах (%) от размера целевой аудитории (ЦА). По умолчанию ЦА рассчитывается из населения города в возрасте 4+<br />" +
          "<b>Доля (Share)</b> - количество зрителей телепередачи или телеканала, выраженное в процентах (%), от всех зрителей телевидения, которые смотрели любую другую телепередачу или телеканал в анализируемый период времени<br />" +
          "<b>Охват (Reach)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в тысячах человек, где каждый зритель учитывается только один раз<br />" +
          "<b>Охват, % (Reach, %)</b> - количество телезрителей, которые провели на телеканале не менее 20 секунд. Показатель выражен в процентах (%) от ЦА, где каждый зритель учитывается только один раз<br />" +
          "<br />" +
          "Показатель <b>Сводный</b> строит отчет только в виде таблицы.",
        img: null,
      },
    },
  },
];

if (process.env.VUE_APP_ALTERNATE_UI == "true") {
  routes = routes.concat(altRoutes);
} else {
  routes = routes.concat(fullRoutes);
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      let role = to.meta.requiresRole;
      if (role) {
        if (store.getters.StateUser.role === role) {
          next();
          return;
        } else {
          next("/");
          return;
        }
      }
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
