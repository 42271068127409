import axios from "axios";

class AuthService {
  login(user) {
    return axios.post("/login", user).then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        return response.data;
      } else {
        return [];
      }
    });
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
