import axios from "axios";

class StoriesService {
  getSelectors(data) {
    console.log('get', data)
    return axios.post("stories/selectors", data).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }
}

export default new StoriesService();
