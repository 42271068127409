<template>
  <v-navigation-drawer
    clipped
    :permanent="!isMobile"
    app
    v-if="isAuth && showDrawer"
    :class="'mh-drawer '"
    :width="drawerWidth"
    :value="drawer"
    :mini-variant="mini"
    :expand-on-hover="false"
    :color="isAlternate ? '#1D1A05' : ''"
  >
    <v-list dense expand v-if="isAlternate">
      <v-subheader class="white--text" style="opacity: 0.5"
        >Телеканалы</v-subheader
      >
      <v-list-item
        class="white--text pl-6"
        link
        to="/tv"
        active-class="primary white--text"
        v-if="hasPrivTvchannels"
      >
        <v-list-item-title> Показатели телеканалов </v-list-item-title>
      </v-list-item>
      <v-list-item
        class="white--text pl-6"
        link
        to="/cast/compare/tv"
        active-class="primary white--text"
        v-if="hasPrivCompareTv"
      >
        <v-list-item-title> Сетки вещания </v-list-item-title>
      </v-list-item>

      <v-subheader class="white--text" style="opacity: 0.5"
        >Телепередачи</v-subheader
      >
      <v-list-item
        link
        class="white--text pl-6"
        to="/cast/tvshows"
        active-class="primary white--text"
        v-if="hasPrivTvshows"
      >
        <v-list-item-title> Показатели телепередач </v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        class="white--text pl-6"
        to="/cast/incomes"
        active-class="primary white--text"
        v-if="hasPrivIncomes"
      >
        <v-list-item-title> Приток-отток зрителей </v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        class="white--text pl-6"
        to="/cast/compare/period"
        active-class="primary white--text"
        v-if="hasPrivCompareTvPeriod"
      >
        <v-list-item-title> Анализ программирования </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list dense expand v-if="!isAlternate">
      <v-list-item link @click="menuActionClick('hide')" v-if="!isAlternate">
        <v-list-item-icon class="mr-3">
          <v-icon v-if="mini">mdi-menu</v-icon>
          <v-icon v-if="!mini">mdi-arrow-left</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Скрыть меню</v-list-item-title>
      </v-list-item>
      <v-list-item-group>
        <v-list-item
          v-if="hasPrivMy"
          link
          to="/my"
          active-class="primary white--text"
        >
          <v-list-item-icon class="mr-3">
            <v-badge
              dot
              v-if="$store.getters.unreadingCount > 0"
              color="orange"
              overlap
            >
              <v-icon>mdi-bookmark</v-icon>
            </v-badge>
            <v-icon v-if="$store.getters.unreadingCount == 0"
              >mdi-bookmark</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title> Мои отчеты </v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/online"
          active-class="primary white--text"
          v-if="hasPrivOnline"
        >
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-access-point</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="pl-0"> Онлайн </v-list-item-title>
        </v-list-item>
        <v-list-group
          v-if="!mini && (hasPrivTvchannels || hasPrivCompareTv)"
          no-action
          :append-icon="'$expand'"
          active-class="white mh-elevation-4"
          :value="activeRoute === 'indicators' || activeRoute == 'compare-tv'"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-television</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-wrap">Телеканалы</v-list-item-title>
          </template>
          <v-list-item
            class="grey-lighten-4"
            link
            to="/tv"
            active-class="primary white--text"
            v-if="hasPrivTvchannels"
          >
            <v-list-item-title> Показатели телеканалов </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="grey-lighten-4"
            link
            to="/cast/compare/tv"
            active-class="primary white--text"
            v-if="hasPrivCompareTv"
          >
            <v-list-item-title> Сетки вещания </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-menu
          v-if="mini && (hasPrivTvchannels || hasPrivCompareTv)"
          offset-x
          content-class="elevation-0 rounded-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              class="v-list-item v-list-item--link theme--light mb-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-television</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Телеканалы </v-list-item-title>
            </p>
          </template>

          <v-card outlined elevation="0" class="elevation-0 rounded-0">
            <v-list dense>
              <p class="v-list-item theme--light mb-0 mt-n2 grey--text">
                <v-list-item-title> Телеканалы </v-list-item-title>
              </p>
              <v-list-item
                link
                to="/tv"
                active-class="primary white--text"
                v-if="hasPrivTvchannels"
              >
                <v-list-item-title> Показатели телеканалов </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/cast/compare/tv"
                active-class="primary white--text"
                v-if="hasPrivCompareTv"
              >
                <v-list-item-title> Сетки вещания </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <!--          -->
        <v-list-group
          v-if="
            !mini &&
            (hasPrivTvshows || hasPrivIncomes || hasPrivCompareTvPeriod)
          "
          no-action
          :append-icon="'$expand'"
          active-class="white mh-elevation-4"
          :value="
            activeRoute === 'tvshows' ||
            activeRoute === 'incomes' ||
            activeRoute == 'compare-tv-period'
          "
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-television-guide</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-wrap"
              >Телепередачи</v-list-item-title
            >
          </template>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/cast/tvshows"
            active-class="primary white--text"
            v-if="hasPrivTvshows"
          >
            <v-list-item-title> Показатели телепередач </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/cast/incomes"
            active-class="primary white--text"
            v-if="hasPrivIncomes"
          >
            <v-list-item-title> Приток-отток зрителей </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/cast/compare/period"
            active-class="primary white--text"
            v-if="hasPrivCompareTvPeriod"
          >
            <v-list-item-title> Анализ программирования </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-menu
          v-if="
            mini && (hasPrivTvshows || hasPrivIncomes || hasPrivCompareTvPeriod)
          "
          offset-x
          content-class="elevation-0 rounded-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              class="v-list-item v-list-item--link theme--light mb-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-television-guide</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Телепередачи </v-list-item-title>
            </p>
          </template>

          <v-card outlined elevation="0" class="elevation-0 rounded-0">
            <v-list dense>
              <p class="v-list-item theme--light mb-0 mt-n2 grey--text">
                <v-list-item-title> Телепередачи </v-list-item-title>
              </p>
              <v-list-item
                link
                to="/cast/tvshows"
                active-class="primary white--text"
                v-if="hasPrivTvshows"
              >
                <v-list-item-title> Показатели телепередач </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/cast/incomes"
                active-class="primary white--text"
                v-if="hasPrivIncomes"
              >
                <v-list-item-title> Приток-отток зрителей </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/cast/compare/period"
                active-class="primary white--text"
                v-if="hasPrivCompareTvPeriod"
              >
                <v-list-item-title> Анализ программирования </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-list-group
          no-action
          active-class="white mh-elevation-4"
          :append-icon="'$expand'"
          v-if="!mini && (hasPrivViewsDevices || hasPrivViewsRatio)"
          :value="activeRoute === 'devices' || activeRoute === 'ratio'"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-chart-pie</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-wrap"
              >Типы просмотров</v-list-item-title
            >
          </template>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/views/devices"
            active-class="primary white--text"
            v-if="hasPrivViewsDevices"
          >
            <v-list-item-title class="text-wrap">
              По оборудованию
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/views/ratio"
            active-class="primary white--text"
            v-if="hasPrivViewsRatio"
          >
            <v-list-item-title class="text-wrap"
              >Live / Archive
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-menu
          v-if="mini && (hasPrivViewsDevices || hasPrivViewsRatio)"
          offset-x
          content-class="elevation-0 rounded-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              class="v-list-item v-list-item--link theme--light mb-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-chart-pie</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Типы просмотров </v-list-item-title>
            </p>
          </template>

          <v-card outlined elevation="0" class="elevation-0 rounded-0">
            <v-list dense>
              <p
                class="v-list-item theme--light mb-0 mt-n2 font-weight-bold grey--text"
              >
                <v-list-item-title> Типы просмотров </v-list-item-title>
              </p>
              <v-list-item
                link
                to="/views/devices"
                active-class="primary white--text"
                v-if="hasPrivViewsDevices"
              >
                <v-list-item-title class="text-wrap">
                  По оборудованию
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/views/ratio"
                active-class="primary white--text"
                v-if="hasPrivViewsRatio"
              >
                <v-list-item-title class="text-wrap"
                  >Live / Archive
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

          <v-list-group
            no-action
            active-class="white mh-elevation-4"
            :append-icon="'$expand'"
            v-if="!mini && hasPrivPres"
            :value="activeRoute === 'stories-news'
              "
          >
            <template v-slot:activator>
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-briefcase-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-wrap"
                ><span style="float: left; padding-top: 4px">Сюжеты</span
                ></v-list-item-title>
            </template>
            <v-list-item
              link
              class="grey-lighten-4"
              to="/stories/news"
              active-class="primary white--text"
            >
              <v-list-item-title class="text-wrap">
                Новости и дебаты
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-menu
            v-if="mini && hasPrivPres"
            offset-x
            content-class="elevation-0 rounded-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                class="v-list-item v-list-item--link theme--light mb-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title
                  ><v-list-item-icon class="mr-3">
                      <v-icon>mdi-briefcase-variant</v-icon>
                    </v-list-item-icon>
                </v-list-item-title>
              </p>
            </template>

            <v-card outlined elevation="0" class="elevation-0 rounded-0">
              <v-list dense>
                <p
                  class="v-list-item theme--light mb-0 mt-n2 font-weight-bold grey--text"
                >
                  <v-list-item-title> Сюжеты </v-list-item-title>
                </p>
                <v-list-item
                  link
                  to="/stories/news"
                  active-class="primary white--text"
                >
                  <v-list-item-title class="text-wrap">
                    Новости и дебаты
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

        <v-list-group
          no-action
          active-class="white mh-elevation-4"
          :append-icon="'$expand'"
          v-if="!mini && hasPrivGtrk"
          :value="
            activeRoute === 'special-tv' ||
            activeRoute === 'special-tv-internet' ||
            activeRoute === 'special-internet'
          "
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap"
              ><span style="float: left; padding-top: 4px">ГТРК</span
              ><v-img
                :width="35"
                style="margin-right: -4px"
                :src="require('@/assets/vgtrk.svg')"
            /></v-list-item-title>
          </template>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/tv/gtrk"
            active-class="primary white--text"
          >
            <v-list-item-title class="text-wrap">
              ТВ-Показатели
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/internet"
            active-class="primary white--text"
          >
            <v-list-item-title class="text-wrap"
              >Интернет-показатели
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/tv-internet"
            v-if="false"
            active-class="primary white--text"
          >
            <v-list-item-title class="text-wrap"
              >Интернет+ТВ
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-menu
          v-if="mini && hasPrivGtrk"
          offset-x
          content-class="elevation-0 rounded-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              class="v-list-item v-list-item--link theme--light mb-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-title
                ><v-img
                  :width="35"
                  style="margin-right: -4px"
                  :src="require('@/assets/vgtrk.svg')"
                />
              </v-list-item-title>
            </p>
          </template>

          <v-card outlined elevation="0" class="elevation-0 rounded-0">
            <v-list dense>
              <p
                class="v-list-item theme--light mb-0 mt-n2 font-weight-bold grey--text"
              >
                <v-list-item-title> ГТРК </v-list-item-title>
              </p>
              <v-list-item
                link
                to="/special/tv/gtrk"
                active-class="primary white--text"
              >
                <v-list-item-title class="text-wrap">
                  ТВ-показатели
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/special/internet"
                active-class="primary white--text"
              >
                <v-list-item-title class="text-wrap"
                  >Интернет-показатели
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="false"
                link
                to="/special/tv-internet"
                active-class="primary white--text"
              >
                <v-list-item-title class="text-wrap"
                  >Интернет+ТВ
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-list-group
          no-action
          active-class="white mh-elevation-4"
          :append-icon="'$expand'"
          v-if="!mini && hasPrivMultalndiaSection"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap"
              ><span style="float: left; padding-top: 4px"></span
              ><v-img
                :width="mini ? 35 : 186"
                style="margin-right: -4px"
                :src="
                  mini
                    ? require('@/assets/multilandia_logo_short.svg')
                    : require('@/assets/multlandia.svg')
                "
            /></v-list-item-title>
          </template>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/tv/multilandia"
            active-class="primary white--text"
          >
            <v-list-item-title class="text-wrap">
              ТВ-Показатели
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/internet/multilandia"
            active-class="primary white--text"
            disabled
          >
            <v-list-item-title class="text-wrap"
              >Интернет-показатели
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-menu
          v-if="mini && hasPrivMultalndiaSection"
          offset-x
          content-class="elevation-0 rounded-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              class="v-list-item v-list-item--link theme--light mb-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-title
                ><v-img
                  :width="mini ? 35 : 186"
                  style="margin-right: -4px"
                  :src="
                    mini
                      ? require('@/assets/multilandia_logo_short.svg')
                      : require('@/assets/multlandia.svg')
                  "
                />
              </v-list-item-title>
            </p>
          </template>

          <v-card outlined elevation="0" class="elevation-0 rounded-0">
            <v-list dense>
              <p
                class="v-list-item theme--light mb-0 mt-n2 font-weight-bold grey--text"
              >
                <v-list-item-title
                  ><v-img
                    :width="126"
                    style="margin-left: 0px"
                    :src="require('@/assets/multlandia.svg')"
                  />
                </v-list-item-title>
              </p>
              <v-list-item
                link
                to="/special/tv/multilandia"
                active-class="primary white--text"
              >
                <v-list-item-title class="text-wrap">
                  ТВ-показатели
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/special/internet/multilandia"
                active-class="primary white--text"
                disabled
              >
                <v-list-item-title class="text-wrap"
                  >Интернет-показатели
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-list-group
          no-action
          active-class="white mh-elevation-4"
          :append-icon="'$expand'"
          v-if="!mini && hasPrivAmediaSection"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap"
              ><span style="float: left; padding-top: 4px"></span
              ><v-img
                :width="mini ? 50 : 126"
                style="margin-right: -4px"
                :src="
                  mini
                    ? require('@/assets/logo_vert_short.svg')
                    : require('@/assets/amkalogo_full.svg')
                "
            /></v-list-item-title>
          </template>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/tv/amedia"
            active-class="primary white--text"
          >
            <v-list-item-title class="text-wrap">
              ТВ-Показатели
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/internet/amedia"
            active-class="primary white--text"
            disabled
          >
            <v-list-item-title class="text-wrap"
              >Интернет-показатели
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-menu
          v-if="mini && hasPrivAmediaSection"
          offset-x
          content-class="elevation-0 rounded-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              class="v-list-item v-list-item--link theme--light mb-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-title
                ><v-img
                  :width="mini ? 50 : 126"
                  style="margin-right: -4px"
                  :src="
                    mini
                      ? require('@/assets/logo_vert_short.svg')
                      : require('@/assets/amkalogo_full.svg')
                  "
                />
              </v-list-item-title>
            </p>
          </template>

          <v-card outlined elevation="0" class="elevation-0 rounded-0">
            <v-list dense>
              <p
                class="v-list-item theme--light mb-0 mt-n2 font-weight-bold grey--text"
              >
                <v-list-item-title
                  ><v-img
                    :width="126"
                    style="margin-left: 0px"
                    :src="require('@/assets/amkalogo_full.svg')"
                  />
                </v-list-item-title>
              </p>
              <v-list-item
                link
                to="/special/tv/amedia"
                active-class="primary white--text"
              >
                <v-list-item-title class="text-wrap">
                  ТВ-показатели
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/special/internet/amedia"
                active-class="primary white--text"
                disabled
              >
                <v-list-item-title class="text-wrap"
                  >Интернет-показатели
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-list-group
          no-action
          active-class="white mh-elevation-4"
          :append-icon="'$expand'"
          v-if="!mini && hasPriv21ButtonSection"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-remote</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-wrap">21 Кнопка</v-list-item-title>
          </template>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/tv/21button"
            active-class="primary white--text"
          >
            <v-list-item-title class="text-wrap">
              ТВ-Показатели
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/internet/21button"
            active-class="primary white--text"
            disabled
          >
            <v-list-item-title class="text-wrap"
              >Интернет-показатели
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-menu
          v-if="mini && hasPriv21ButtonSection"
          offset-x
          content-class="elevation-0 rounded-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              class="v-list-item v-list-item--link theme--light mb-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-remote</v-icon>
              </v-list-item-icon>
              <v-list-item-title> 21 Кнопка </v-list-item-title>
            </p>
          </template>

          <v-card outlined elevation="0" class="elevation-0 rounded-0">
            <v-list dense>
              <p
                class="v-list-item theme--light mb-0 mt-n2 grey--text"
              > <v-list-item-title> 21 Кнопка </v-list-item-title>
              </p>
              <v-list-item
                link
                to="/special/tv/21button"
                active-class="primary white--text"
              >
                <v-list-item-title class="text-wrap">
                  ТВ-показатели
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/special/internet/21button"
                active-class="primary white--text"
                disabled
              >
                <v-list-item-title class="text-wrap"
                  >Интернет-показатели
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>


        <v-list-group
          no-action
          active-class="white mh-elevation-4"
          :append-icon="'$expand'"
          v-if="!mini && hasPriv22ButtonSection"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-remote-tv</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-wrap">22 Кнопка</v-list-item-title>
          </template>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/tv/22button"
            active-class="primary white--text"
          >
            <v-list-item-title class="text-wrap">
              ТВ-Показатели
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="grey-lighten-4"
            to="/special/internet/22button"
            active-class="primary white--text"
            disabled
          >
            <v-list-item-title class="text-wrap"
              >Интернет-показатели
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-menu
          v-if="mini && hasPriv21ButtonSection"
          offset-x
          content-class="elevation-0 rounded-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              class="v-list-item v-list-item--link theme--light mb-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-remote-tv</v-icon>
              </v-list-item-icon>
              <v-list-item-title> 22 Кнопка </v-list-item-title>
            </p>
          </template>

          <v-card outlined elevation="0" class="elevation-0 rounded-0">
            <v-list dense>
              <p
                class="v-list-item theme--light mb-0 mt-n2 grey--text"
              > <v-list-item-title> 22 Кнопка </v-list-item-title>
              </p>
              <v-list-item
                link
                to="/special/tv/22button"
                active-class="primary white--text"
              >
                <v-list-item-title class="text-wrap">
                  ТВ-показатели
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                to="/special/internet/22button"
                active-class="primary white--text"
                disabled
              >
                <v-list-item-title class="text-wrap"
                  >Интернет-показатели
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-list-item
          link
          to="/content_producers"
          v-if="hasPrivContentProducers"
          active-class="primary white--text"
        >
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-briefcase-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Производителям контента </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="hasPrivAbout"
          link
          to="/help"
          active-class="primary white--text"
        >
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Справка </v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/admin/users"
          v-if="hasPrivUsers"
          active-class="primary white--text"
        >
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Пользователи </v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/admin/accounts"
          v-if="hasPrivAccounts"
          active-class="primary white--text"
        >
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Управление аккаунтами </v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/queues"
          v-if="hasPrivQueues"
          active-class="primary white--text"
        >
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-menu-open</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Очереди построения </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <div
        :class="(isShowBanner ? '' : 'pt-3') + ' pl-3 body-2'"
        style="opacity: 0.58"
        v-if="!mini && !isAlternate"
      >
        <p>
          Разработано {{ appName }}. {{ currentYear }} v{{ majorVersion }}.{{
            frontVersion
          }}
        </p>
      </div>
      <div
        class="pl-3 pt-3 body-2"
        style="opacity: 0.58"
        v-if="mini && !isAlternate"
      >
        <p>v{{ majorVersion }}.{{ frontVersion }}</p>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import moment from "moment";
import { CONSTS } from "../services/constants.js";
export default {
  props: {
    mini: {
      type: Boolean,
    },
    drawer: {
      type: Boolean,
    },
    menuActionClick: {
      type: Function,
    },
  },
  mounted() {
    console.log(this.$router.currentRoute.name);
  },
  computed: {
    appName() {
      return process.env.VUE_APP_APP_NAME;
    },
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    isShowBanner() {
      if (this.isAuth) {
        return !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.show_banner
        ];
      }
      return false;
    },
    majorVersion() {
      return CONSTS.MAJOR_VERSION;
    },
    activeRoute() {
      return String(this.$router.currentRoute.name).replace("-uuid", "");
    },
    currentYear() {
      return moment().format("YYYY");
    },
    frontVersion() {
      return process.env.VUE_APP_FRONT_VER;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    role() {
      return this.$store.getters.StateUser.role;
    },
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
    hasPrivGtrk() {
      return (
        !!this.$store.getters.StateUser.privs[CONSTS.PRIVS_KEYS.section_gtrk] &&
        !this.isAlternate
      );
    },
    hasPrivPres() {
      return (
        !!this.$store.getters.StateUser.privs[CONSTS.PRIVS_KEYS.section_pres] &&
        !this.isAlternate
      );
    },
    hasPrivMy() {
      return (
        (!!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.my_reports_section
        ] ||
          !!this.$store.getters.StateUser.privs[
            CONSTS.PRIVS_KEYS.presets_sections
          ]) &&
        !this.isAlternate
      );
    },
    hasPrivUsers() {
      return (
        !!this.$store.getters.StateUser.privs[CONSTS.PRIVS_KEYS.users_admin] &&
        !this.isAlternate
      );
    },
    hasPrivAccounts() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_accounts
      ] && !this.isAlternate;
    },
    hasPrivQueues() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_queues
        ] && !this.isAlternate
      );
    },
    hasPrivMultalndiaSection() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_multilandia
        ] && !this.isAlternate
      );
    },
    hasPrivAmediaSection() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_amedia
        ] && !this.isAlternate
      );
    },
    hasPriv21ButtonSection() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_21button
        ] && !this.isAlternate
      );
    },
    hasPriv22ButtonSection() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_22button
        ] && !this.isAlternate
      );
    },
    hasPrivContentProducers() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_content_producers
        ] && !this.isAlternate
      );
    },
    hasPrivTvchannels() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_tvchannels
      ];
    },
    hasPrivCompareTv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_compare_tv
      ];
    },
    hasPrivTvshows() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_tvshows
      ];
    },
    hasPrivIncomes() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_incomes
      ];
    },
    hasPrivCompareTvPeriod() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_compare_tv_period
      ];
    },
    hasPrivViewsDevices() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_views_devices
        ] && !this.isAlternate
      );
    },
    hasPrivViewsRatio() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_views_ratio
        ] && !this.isAlternate
      );
    },
    hasPrivOnline() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_online
        ] && !this.isAlternate
      );
    },
    hasPrivAbout() {
      return (
        !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.section_about
        ] && !this.isAlternate
      );
    },
    drawerWidth() {
      if (this.isMobile) {
        if (!this.drawer) {
          return 0;
        }
      }
      if (this.mini) {
        return 56;
      }
      return 256;
    },
  },
  data: () => ({
    showDrawer: true,
  }),
  watch: {
    isMobile() {
      this.mini = false;
    },
  },
};
</script>
<style lang="scss">
.mh-drawer {
  //.v-navigation-drawer__append {
  //
  //  margin-top: -64px !important;
  //  @media (max-width: 960px) { margin-top: -100px !important;};
  //}
}
</style>
