export default function authHeader() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return {
      authorization: "Bearer " + user.token,
      "X-Version": process.env.VUE_APP_FRONT_VER,
      "X-Major": process.env.VUE_APP_ALTERNATE_UI=='true' ? 11 : 4,
    };
  } else {
    return {};
  }
}
