<template>
  <v-card outlined class="mh-main-filter">
    <v-card-title
      class="text-lg-h5 mh-page-title text-md-h5 justify-space-between pb-2 flex-row"
    >
      {{
        typeof $route.params.uuid == "string" && $route.params.uuid.length > 0
          ? "Просмотр отчета "
          : ""
      }}{{ page.title }}
    </v-card-title>
    <v-row class="ma-1 pr-2 pl-2">
      <v-col cols="auto" class="pb-0 pt-0 pr-1 pl-1 text-body-2 black38">
        Исходные данные
      </v-col>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="ma-1 pr-2 pl-2">
      <v-col class="col-12 col-xl-6">
        <v-row>
          <v-col class="col-3 col-xl-2 header-title"> Тип отчета </v-col>
          <v-col class="header-value">
            <span style="float: left">{{ report }}</span>
            <help-btn class="mt-n2"></help-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 col-xl-2 header-title"> Город </v-col>
          <v-col class="header-value">
            {{ city }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 col-xl-2 header-title"> Период </v-col>
          <v-col class="header-value">
            {{ period }}
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-12 col-xl-6">
        <v-row>
          <v-col class="col-3 col-xl-2 header-title"> Телеканалы </v-col>
          <v-col class="header-value">
            {{ channels }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ma-1 pr-2 pl-2">
      <v-col cols="auto" class="pb-0 pt-0 pr-1 pl-1 text-body-2 black38">
        Построение отчета
      </v-col>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="ma-1 pr-2 pl-2">
      <v-col class="col-12 col-xl-6">
        <v-row>
          <v-col class="col-3 col-xl-2 header-title"> Показатель </v-col>
          <v-col class="header-value">
            {{ indicator }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 col-xl-2 header-title"> Агрегация </v-col>
          <v-col class="header-value">
            {{ aggregation }}
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-12 col-xl-6" v-if="detalisation">
        <v-row>
          <v-col class="col-3 col-xl-2 header-title"> Детализация </v-col>
          <v-col class="header-value">
            {{ detalisation }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="ma-1 pr-2 pl-2" v-if="shows">
      <v-col>
        <v-row>
          <v-col cols="1" class="header-title"> Телепередача </v-col>
          <v-col class="header-value">
            {{ shortShowsStr }}
            <div class="mt-2" v-if = "showsLength>3">
              <a href="#" @click="allShowsDialog=true">Показать все ({{showsLength}})</a>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col class="ma-4">
        <v-row>
          <v-col cols="auto"
            ><v-btn elevation="0" @click="copyLink" class="primary-text">
              <v-icon small>mdi-content-copy</v-icon>
              Копировать ссылку на отчет
            </v-btn>
            <v-snackbar
              v-model="snackbarCopy"
              :timeout="1000"
              right
              color="success"
              class="mb-16"
            >
              Ссылка скопирована
            </v-snackbar></v-col
          >
          <v-col></v-col>
          <v-col cols="auto"
            ><v-btn
              color="primary"
              class="mh-primary-border"
              elevation="0"
              @click="newReportFromUuid"
            >
              Новый отчет
            </v-btn></v-col
          >
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="allShowsDialog" max-width="697">
      <v-card class="pb-2">
        <v-card-title>
          <v-icon color="info" large class="mr-2">mdi-information</v-icon
          >Телепередачи
          <v-spacer></v-spacer>
          <v-btn large icon @click="allShowsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div class="header-value">
            {{allShowsStr}}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import HelpBtn from "@/components/HelpBtn.vue";
export default {
  name: "ViewReportHeader",
  data() {
    return {
      allShowsDialog: false, // окно со всеми передачами
      snackbarCopy: false,
    };
  },
  components: { HelpBtn },
  methods: {
    newReportFromUuid() {
      this.$emit("newReportFromUuid");
    },
    copyLink() {
      window.location.href;

      navigator.clipboard.writeText(window.location.href);
      this.snackbarCopy = true;
    },
  },
  computed: {
    allShowsStr() {
      return this.shows?.split("|").join(", ");
    },
    shortShowsStr() {
      return this.shows?.split("|").slice(0,3).join(", ");
    },
    showsLength() {
      return this.shows?.split("|").length;
    },
  },
  props: {
    indicator: {
      type: String,
      default: "Доля",
    },
    aggregation: {
      type: String,
      default: "По дням",
    },
    shows: {
      type: String,
      default:"",
    },
    detalisation: {
      type: String,
      default: "15 мин",
    },
    report: {
      type: String,
      default: "Показатели телепередач",
    },
    city: {
      type: String,
      default: "Москва",
    },
    period: {
      type: String,
      default:
        "14 апр 2020 - 14 окт 2021, 00:00 - 23:59,пн, ср, пт (посуточный)",
    },
    channels: {
      type: String,
      default:
        "Матч ТВ, РЕН ТВ, Карусель, НТВ (0+), Россия 1, Россия 24, ОТР, Россия К",
    },
    page: {
      type: Object,
      // Для объектов или массивов значения по умолчанию
      // должны возвращаться из функции
      default: function () {
        return {
          title: "Показатель",
          type: "default",
          many_channel: false,
        };
      },
    },
  },
};
</script>

<style scoped>
.header-title {
  color: rgba(0,0,0, .58);
  min-width: 130px;
}

.header-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0, .87);
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;
}
.primary-text {
  color: #1976d2 !important;
}
</style>
