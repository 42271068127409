<template>
  <div
    :class="
      $vuetify.breakpoint.mdAndDown
        ? 'loginContainer'
        : 'd-flex flex-row-reverse loginContainer'
    "
  >
    <v-row
      class="mt-0 pt-0 pb-0 mb-0 mr-0 pr-0 loginFormContainer"
      :justify="
        $vuetify.breakpoint.mdAndDown || isAlternate ? 'center' : 'left'
      "
    >
      <login-form />
    </v-row>
    <v-row
      class="loginInfoBlock mt-0 pt-0 pb-0 mb-0 mr-0 pr-0 d-flex"
      justify="center"
      v-if="!isAlternate"
    >
      <div class="loginInfoBlockContent" id="loginInfoBlockContent">
        <v-img
          max-width="196"
          max-height="130"
          class="ml-n5"
          :src="require('@/assets/login_logo_white.svg')"
          v-if="!$vuetify.breakpoint.mdAndDown"
        />
        <h3 class="mt-4 ml-n5" v-if="!$vuetify.breakpoint.mdAndDown">
          Мы делаем телеизмерения доступными
        </h3>
        <div
          v-bar
          v-if="!$vuetify.breakpoint.mdAndDown"
          style="margin-left: -20px; margin-bottom: 40px"
        >
          <component :is="loginTabsComponent" />
        </div>
        <div v-if="$vuetify.breakpoint.mdAndDown" style="margin-bottom: 40px">
          <component :is="loginTabsComponent" />
        </div>
      </div>
    </v-row>
  </div>
</template>
<style lang="scss">
.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(255, 255, 255, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(255, 255, 255, 0.7);
}
.html {
  overflow-y: hidden !important;
}

.infoText,
.infoText a {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
}
.loginContainer {
  min-height: 100%;
  background-color: #1976d2;
  @if $VUE_APP_ALTERNATE_UI == true {
    background-color: #fe4e00;
  }
}
.loginFormContainer {
  background-color: white;
}
.loginInfoBlock {
  flex: 4;
  min-height: 100%;
  @media screen and (min-width: 944px) {
    align-items: center;
  }
}
.loginInfoBlockContent {
  margin-left: 20px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 80px;
  @media screen and (min-width: 944px) {
    padding-left: 80px;
    padding-right: 80px;
    display: inline-block;
    max-width: fit-content;
  }
}
.loginInfoBlockContent h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: #ffffff;
}
</style>
<script>
import LoginForm from "@/components/LoginForm.vue";
// import axios from "axios";
export default {
  name: "LoginView",
  components: {
    LoginForm,
  },
  data() {
    return {
      openedPanel: null,
      form: {
        username: "",
        password: "",
      },
      showError: false,
    };
  },
  computed: {
    loginTabsComponent() {
      return () =>
        import(`@/components/${this.isAlternate ? "Empty" : "LoginTabs"}.vue`);
    },
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
  },

  mounted() {
    if (this.isAuth) {
      return this.$router.push("/tv");
    }
    this.handleResize();
    window.addEventListener("resize", this.handleResize, true);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      if (this.$vuetify.breakpoint.mdAndDown) return;
      const yLogin = document
        .getElementById("loginFormAnchor")
        ?.getBoundingClientRect()?.y;
      const yTabs = document
        .getElementById("loginPageTabs")
        ?.getBoundingClientRect()?.y; yTabs;
      const screenHeight = window.innerHeight;
      let y = yLogin - 200;
      if (y < 50) y = 50;
      const height = screenHeight - y - 100;
      //console.log(y, height, yTabs);
      document.getElementById(
        "loginInfoBlockContent"
      ).style.marginTop = `${y}px`;
      document.getElementById(
        "loginInfoBlockContent"
      ).style.height = `${height}px`;
      const yTabsNew = document
        .getElementById("loginPageTabs")
        ?.getBoundingClientRect()?.y;
      const tabsHeight = height - (yTabsNew - y) - 50;
      //console.log(yTabsNew, tabsHeight)
      if (document.getElementById("loginPageTabs")) {
        document.getElementById("loginPageTabs").style.height = `${tabsHeight}px`;
        document.getElementById("loginPageTabs").style.maxHeight = `${tabsHeight}px`;
      }  
    },
  },
};
</script>
