<template>
  <v-col class="text-center">
    <svg v-if="!isAlternate"
      width="75"
      height="78"
      viewBox="0 0 75 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.6143 37.6417C29.4491 38.6469 30.9569 41.8034 29.9517 44.6583L19.4368 74.2125C18.4316 77.0674 15.2751 78.5551 12.4001 77.5499C9.56532 76.5446 8.07755 73.3882 9.06269 70.5333L19.5776 40.9791C20.5828 38.1443 23.7393 36.6565 26.6143 37.6417Z"
        fill="#DA3A10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60.5318 10.52C63.2259 11.9073 64.2713 15.2246 62.8841 17.9186L56.5109 30.2831C55.1236 32.9571 51.7661 34.0226 49.0921 32.6354C46.4182 31.2482 45.3727 27.9309 46.7399 25.2368L53.1131 12.8723C54.5004 10.1983 57.8579 9.15289 60.5318 10.52Z"
        fill="#990099"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.5224 0.628454C42.518 0.930028 44.7296 3.64419 44.4079 6.65992L39.1806 57.1433C38.8791 60.1389 36.1448 62.3505 33.1291 62.0288C30.1334 61.7272 27.942 59.0131 28.2436 56.0174L33.4708 5.51394C33.7724 2.51831 36.5067 0.326881 39.5224 0.628454Z"
        fill="#109619"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7704 38.1243C24.3237 36.4958 27.7415 37.2397 29.37 39.7528L38.3569 53.585C39.9854 56.1383 39.2616 59.536 36.7284 61.1846C34.1751 62.8332 30.7573 62.0893 29.1087 59.5561L20.1419 45.724C18.5134 43.1907 19.2573 39.7729 21.7704 38.1243Z"
        fill="#FF9801"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.1255 1.27215C38.719 -0.23572 42.1167 0.628791 43.6447 3.24243L56.3912 25.0361C57.9192 27.6699 57.0346 31.0475 54.4209 32.5755C51.8073 34.1034 48.4096 33.2188 46.9017 30.6052L34.1552 8.79138C32.6473 6.15764 33.5118 2.78001 36.1255 1.27215Z"
        fill="#990099"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.4796 11.1435C56.7715 9.21339 60.2497 9.49485 62.1998 11.7868L72.9962 24.4931C74.9463 26.7851 74.6649 30.3034 72.3729 32.2536C70.0609 34.2038 66.5827 33.9223 64.6325 31.6102L53.8362 18.9039C51.9061 16.5919 52.1876 13.0936 54.4796 11.1435Z"
        fill="#0070C0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.23404 52.8408C5.86778 51.3932 9.2253 52.3181 10.7332 54.9518L19.0164 69.8093C20.484 72.4632 19.5391 75.8408 16.9054 77.3085C14.2716 78.7761 10.9141 77.8312 9.40624 75.1974L1.12303 60.3399C-0.324524 57.666 0.600302 54.3085 3.23404 52.8408Z"
        fill="#0070C0"
      />
    </svg>
    <div class="text-md-body-1 body-2 pt-2 mw-295 mr-auto ml-auto">
      Задайте параметры и постройте отчет, он будет отображаться тут
    </div>
  </v-col>
</template>

<script>
export default {
  name: "PreReportMount",
  data() {
    return {
    };
  },
  methods: {},
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
  }
};
</script>

<style scoped></style>
