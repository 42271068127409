<template>
  <v-navigation-drawer
    absolute
    clipped
    right
    v-if="false && havePrivPresets && !isMobile"
    class="mt-100 mh-drawer"
    v-model="drawer"
  >
    <v-row>
      <v-col class="pl-8 pr-8">
        <p class="text-h6 pt-6">Шаблоны</p>
        <v-btn
          class="grey lighten-4 mh-tertiary-border"
          outlined
          block
          elevation="0"
          @click="newPreset"
        >
          Сохранить шаблон
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-8"></v-divider>
    <v-list dense>
      <v-list-item
        v-for="preset in presets"
        :key="preset.id"
        @mouseover="preset.hover = true"
        @mouseleave="preset.hover = false"
      >
        <v-list-item-title> {{ preset.name }} </v-list-item-title
        ><v-spacer></v-spacer>
        <v-btn
          v-if="preset.hover"
          x-small
          style=""
          class="grey lighten-4 mh-tertiary-border preset-btn"
          outlined
          elevation="0"
          @click="loadPreset(preset)"
        >
          <v-icon small> mdi-upload </v-icon>
        </v-btn>
        <v-btn
          v-if="preset.hover"
          x-small
          style=""
          class="ml-1 grey lighten-4 mh-tertiary-border preset-btn"
          outlined
          elevation="0"
        >
          <v-icon small> mdi-eye </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <p
      class="pl-4 pr-4 text-h6 pt-6 mb-0"
      v-if="general.length"
      style="color: rgba(0, 0, 0, 0.58)"
    >
      Общие шаблоны
    </p>
    <v-list dense class="mt-2">
      <v-list-item
        v-for="preset in general"
        :key="preset.id"
        @mouseover="preset.hover = true"
        @mouseleave="preset.hover = false"
      >
        <v-list-item-title> {{ preset.name }} </v-list-item-title
        ><v-spacer></v-spacer>
        <v-btn
          v-if="preset.hover"
          x-small
          style=""
          class="grey lighten-4 mh-tertiary-border preset-btn"
          outlined
          elevation="0"
        >
          <v-icon small> mdi-upload </v-icon>
        </v-btn>
        <v-btn
          v-if="preset.hover"
          x-small
          style=""
          class="ml-1 grey lighten-4 mh-tertiary-border preset-btn"
          outlined
          elevation="0"
        >
          <v-icon small> mdi-eye </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <v-dialog v-model="dialogNewEditPreset" width="600">
      <create-preset
        ref="createp1"
        :id="-1"
        @cancel="dialogNewEditPreset = false"
        @ok="savePreset"
      ></create-preset>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
import CreatePreset from "@/components/CreatePreset.vue";
import { CONSTS } from "../services/constants";

export default {
  name: "PresetDrawer",
  props: ["report"],
  components: { CreatePreset },
  data() {
    return {
      drawer: true,
      presets: [],
      general: [],
      dialogNewEditPreset: false,
    };
  },
  mounted() {
    this.getPresets();
  },
  methods: {
    newPreset() {
      this.dialogNewEditPreset = true;
      this.$refs.createp.initForm();
    },
    async savePreset(json) {
      this.dialogNewEditPreset = false;
      let apiPath = json.id > 0 ? `/presets/${json.id}` : "/preset/new";
      let reqData = new FormData();
      reqData.append("name", json.presetName);
      reqData.append("report", json.reportType);
      reqData.append("filters", JSON.stringify(json.filtersJson));
      reqData.append("schedule", json.schedule ? 1 : 0);
      reqData.append("schedule_params", JSON.stringify(json.scheduleParams));
      await axios.post(apiPath, reqData);
      await this.getPresets();
    },
    loadPreset(preset) {
      this.$eventHub.$emit("loadPreset", preset);
    },
    async getPresets() {
      let res = await axios.post(`/preset/all`);
      if (res.data) {
        res.data = res.data.map((el) => {
          return {
            hover: false,
            id: el.id,
            name: el.name,
            report: el.report,
            schedule: el.schedule == 1,
            schedule_params: JSON.parse(el.schedule_params),
            filters: JSON.parse(el.filters),
          };
        });
        this.presets = res.data
          .filter((el) => {
            return el.report == this.report;
          })
          .slice(0, 5);
        this.general = res.data
          .filter((el) => {
            return el.report == "general";
          })
          .slice(0, 5);
      }
    },
  },
  computed: {
    havePrivPresets() {
      return !!this.$store.getters.StateUser.indicators[
        CONSTS.PRIVS_KEYS.presets_sections
      ];
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

<style lang="scss">
.preset-btn {
  width: 30px;
  height: 28px;
}
</style>
