import axios from "axios";

class UsersService {
  getUsers(user_id) {
    return axios.post("/users/get", user_id).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }
  GetUserAllPrivs({ user_id }) {
    return axios.post("/users/allPrivs", { user_id }).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }
  UpdateUserTags({ user_id, tags }) {
    return axios.post("/users/updateTagsAction", { user_id, tags }).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }
  SaveUser({ user_id, targetUser }) {
    return axios
      .post("/users/update", { user_id, target_user: targetUser })
      .then((response) => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
  CopyUserPrivs({ user_id, users }) {
    return axios
      .post("/users/copyPrivs", { user_id, target_users: users })
      .then((response) => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
  UpdateUserPriv({ user_id, priv_id, priv_value }) {
    return axios
      .post("/users/updatePriv", { user_id, priv_id, priv_value })
      .then((response) => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
  UpdateUserSourcePriv({ user_id, source_id, source_value }) {
    return axios
      .post("/users/updatePriv", { user_id, source_id, source_value, action: "source" })
      .then((response) => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
  KickUser({ user_id, targetUser }) {
    return axios
      .post("/users/kick", { user_id, target_user: targetUser })
      .then((response) => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
  AddUser({ user_id, name }) {
    return axios.post("/users/add", { user_id, name }).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }

  SaveUserPeriod({ user_id, period }) {
    return axios.post("/users/updatePriv", { 
      user_id, period, action: "periods"
    }).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }

  SaveUserCity({ user_id, city }) {
    return axios.post("/users/updatePriv", { 
      user_id, city, action: "cities"
    }).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }
  SaveUserChannel({ user_id, channel }) {
    return axios.post("/users/updatePriv", { 
      user_id, channel, action: "channels"
    }).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }
}

export default new UsersService();
