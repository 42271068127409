import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";
import VCalendar from "v-calendar";
import Vuebar from 'vuebar';
import LottieAnimation from "lottie-vuejs";
import ru from "vuetify/es5/locale/ru";

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(LottieAnimation);
Vue.use(Vuetify);
Vue.use(Vuebar);
const std_colors= {
    primary: "#1976D2",
    secondary: "#5CB85C",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  };

const alternate_colors= {
    primary: "#FE4E00",
    secondary: "#801D67",
    accent: "#29CAD4",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  };

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1264,
      xl: 1500,
    },
  },
  theme: {
    themes: {
      light: process.env.VUE_APP_ALTERNATE_UI=='true' ? alternate_colors : std_colors,
    },
  },
});
