<template>
  <v-card-text>
    <v-container>
      <v-row
        justify="center"
        align="center"
        style="min-height: 200px; margin: 0"
      >
        <lottie-animation :path="urlLoader" :height="107" :width="188" />
      </v-row>
      <v-row justify="center" align="center" v-if="$route.params.uuid">
        <p class="text-center chartHelper">
          <v-icon small color="#FFA91B" class="pr-1">mdi-lightbulb</v-icon>
          Отчет
          <a @click="dialog = true">{{ $route.params.uuid }}</a> в процессе
          построения. <br />
          Вы можете закрыть эту вкладку. Отчет будет доступен
          <a :href="$route.fullPath">по ссылке</a>
          <!--            <a href="/my">Мои отчеты </a>-->
        </p>
        <report-name-dialog
          :dialog="dialog"
          reportname=""
          @cancel="dialog = false"
        >
        </report-name-dialog>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
import ReportNameDialog from "@/components/ReportNameDialog.vue";
export default {
  name: "BaseLoader",
  components: { ReportNameDialog },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {},
  computed: {
    urlLoader() {
      if (process.env.VUE_APP_ALTERNATE_UI == "true") {
        return "img/loader_2.json";
      }
      return "img/loader.json";
    },
  },
};
</script>

<style scoped></style>
