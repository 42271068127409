import StoriesService from "../services/stories.service.js";
//import moment from "moment";

const state = {
  cities: [],
  channels: [],
  shows: [],
  tags: [],
  pers: [],
};
const getters = {
  getCities: (state) => state.cities,
  getChannels: (state) => state.channels,
  getShows: (state) => state.shows,
  getTags: (state) => state.tags,
  getPersons: (state) => state.pers,
};
const actions = {
  async GetSelectors({ commit, getters }, { sdate, edate, shows, city_id }) {
    const user = getters.StateUser;
    if (!user) return;
    commit("showLoader");
    const data = await StoriesService.getSelectors({city_id, sdate, edate, shows});
    commit("hideLoader");
    commit("setCities", data.cities);
    commit("setChannels", data.channels);
    //if (!shows || !shows.length) 
    commit("setShows", data.shows);
    commit("setTags", data.tags);
    commit("setPers", data.pers);
  },
};
const mutations = {
  setCities(state, data) {
    state.cities = data;
  },
  setChannels(state, data) {
    state.channels = data;
  },
  setShows(state, data) {
    state.shows = data;
  },
  setTags(state, data) {
    state.tags = data;
  },
  setPers(state, data) {
    state.pers = data;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
