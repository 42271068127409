import AuthService from "../services/auth.service";
import SettingsService from "../services/settings.service";
const state = {
  user: null,
  error: false,
  errorMsg: null,
  errorCode: null,
  unreadingCount: 0,
};
const getters = {
  isAuthenticated: (state) => !!state.user,
  isAdmin: (state) =>
    state.user
      ? state.user.role
        ? state.user.role === "administrator"
        : false
      : false,
  StateUser: (state) => state.user,
  isError: (state) => state.error,
  errorMsg: (state) => state.errorMsg,
  errorCode: (state) => state.errorCode,
  unreadingCount: (state) => state.unreadingCount,
};
const actions = {
  async LogIn({ commit }, user) {
    let userData = await AuthService.login(user);
    await commit("setUser", userData);
  },
  async LogOut({ commit }) {
    await AuthService.logout();
    let user = null;
    commit("clearUsers"); // for users admin
    commit("clearFilters");
    commit("setLastCities", []);
    commit("logout", user);
  },
  async setError({ commit }, { data }) {
    commit("error", true);
    if (data?.code >= 1) { // дополняем вывод кастомных сообщений для ошибок с кодом >=1
      commit("errorData", data);
    }
  },
  async resetError({ commit }) {
    commit("error", false);
  },
  async saveSettings({ commit }, settings) {
    commit("setSettings", settings);
  },
  async saveUnreadingCount({ commit }, count) {
    console.log("setUnreadingCount", count);
    commit("setUnreadingCount", count);
  },
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setSettings(state, settings) {
    let fullSettings = Object.keys(state.user.settings);
    for (const key of fullSettings) {
      // eslint-disable-next-line no-prototype-builtins
      if (!settings.hasOwnProperty(key)) {
        settings[`${key}`] = state.user.settings[`${key}`];
      }
    }
    state.user.settings = settings;
    SettingsService.saveSettings(settings).then((value) => {
      state.user.settings = value;
    });
  },
  error(state, isError) {
    state.error = isError;
    if (isError == false) {
      state.errorMsg = null;
      state.errorCode = null;
    }
  },
  errorData(state, data) {
    if (data.code && data.error) {
      state.errorMsg = data.error;
      state.errorCode = data.code;
    }
  },
  setUnreadingCount(state, count) {
    state.unreadingCount = count;
  },
  logout(state, user) {
    state.user = user;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
