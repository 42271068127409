var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},on:{"input":function($event){_vm.searchStr = ''}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mh-dropdown-slot",attrs:{"label":_vm.label,"hide-details":_vm.isMobile ? false : 'auto',"outlined":"","dense":"","append-icon":_vm.opened ? 'mdi-menu-up' : 'mdi-menu-down',"disabled":_vm.disabled,"readonly":"","value":_vm.formatTextFieldValue()},on:{"click:append":function($event){_vm.opened = true}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.invalidItems.length > 0)?_c('v-tooltip',{attrs:{"top":"","content-class":"text-center"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"50px"},attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Некоторые телеканалы недоступны в выбранном городе."),_c('br'),_vm._v("Отчет по ним не будет построен")])]):_vm._e()]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"clearable":"","label":"Поиск","hide-details":"auto","autofocus":"","outlined":"","dense":""},model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}})],1),_c('v-card-text',{staticClass:"scroll"},[(
          _vm.selectedAll ||
          (_vm.showSelectAll && _vm.itemsFiltered.length > 0 && _vm.multiple)
        )?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":_vm.selectAll}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.selectedAll}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.selectedAll ? "Выбраны все" : "Выбрать все"))])],1)],1)],1):_vm._e(),_c('v-virtual-scroll',{attrs:{"bench":"2","items":_vm.itemsFiltered,"item-height":50,"height":_vm.itemsFiltered.length < 5 ? _vm.itemsFiltered.length * 60 + 50 : 300 + 50},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [(typeof item === 'object')?_c('v-list-item',{class:_vm.isSelectOneItem(item[_vm.itemValue])
                ? 'blue lighten-5'
                : item.invalid
                ? 'orange lighten-5'
                : '',attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.selectItem(item[_vm.itemValue], item.invalid)}}},[_c('v-list-item-action',[(_vm.multiple)?_c('v-checkbox',{attrs:{"readonly":"","input-value":item.invalid ||
                  (_vm.selectedItems === null
                    ? false
                    : _vm.selectedItems.includes(item[_vm.itemValue]))},on:{"input":function($event){return _vm.selectItem(item[_vm.itemValue], item.invalid)}}}):_vm._e(),(_vm.isSelectOneItem(item[_vm.itemValue]))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check ")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"item-text"},[_vm._v(_vm._s(item[_vm.itemText]))]),(item.invalid)?_c('p',{staticClass:"caption invalid-item"},[_vm._v(" недоступен в этом городе ")]):_vm._e()],1)],1):_c('v-list-item',{attrs:{"dense":""}},[(item === '%divider%')?_c('v-divider'):_c('v-list-item-content',{class:_vm.getParentClass(item) + ' item-group',on:{"click":function($event){return _vm.selectChildren(item)}}},[_vm._v(" "+_vm._s(item)+" ")])],1)]}}])}),(_vm.itemsFiltered.length === 0 && !_vm.selectedAll)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ничего не найдено")])],1)],1)],1):_vm._e()],1),(_vm.multiple)?_c('v-card-actions',[_c('v-col',{staticClass:"pt-6",staticStyle:{"border-top":"1px solid rgb(231, 231, 231)"}},[_c('v-row',{staticClass:"justify-space-between"},[_c('v-btn',{attrs:{"color":"grey lighten-4","elevation":"0","small":""},on:{"click":_vm.clearDropdown}},[_vm._v("Очистить")]),_c('v-btn',{staticClass:"mh-primary-border",attrs:{"small":"","color":"primary","elevation":"0"},on:{"click":function($event){_vm.opened = false;
              _vm.searchStr = '';}}},[_vm._v("Применить")])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }