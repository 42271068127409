<template>
  <v-container
    :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'"
    ref="content_card"
    id="content_card"
  >
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <main-filters
          :reportRequest="reportRequest"
          :page="pageData"
          @cubeDataClick="cubeData"
          @stopCalc="stopCube"
          :show-hourly="false"
          @calendarRange="(val) => (this.range = val)"
          :aggregate-visible="false"
          @cities="(val) => (this.cities = val)"
          @tvList="(val) => (this.channels = val)"
          @sources="(val) => (this.sources = val)"
          :loading="chart_loading"
          :with-range="false"
          startIndicator="Share"
          :maxChannels="12"
        >
        </main-filters>
        <v-card
          class="stickySliderBlock"
          elevation="10"
          v-if="chart_loading === false"
        >
          <v-row class="ml-2 mr-2">
            <v-col
              class="col-9 col-sm-6 col-md-5 col-lg-3 mt-0"
              style="padding-top: 6px"
            >
              <div
                style="
                  float: left;
                  margin-right: 20px;
                  height: 30px;
                  line-height: 30px;
                  color: #1e1e21;
                "
              >
                Подробные&nbsp;значения
              </div>
              <v-switch
                style="margin-top: 0; height: 30px"
                v-model="showValues"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          class="mt-6"
          v-if="chart_loading === false"
          id="highcharts_png"
        >
          <v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            <div>
              {{ chartTitle }}
              <v-chip small class="ml-2 pl-1 pr-2" color="orange lighten-2" style="border-radius: 4px;">
                <v-icon class="mr-1 pl-0 pr-0" small>{{demographyIcon}}</v-icon>
                {{demographyStr}}
              </v-chip>
            </div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  elevation="0"
                  class="mh-secondary-border ml-2"
                  style="text-transform: none !important"
                  v-bind="attrs"
                  v-on="on"
                  data-html2canvas-ignore
                >
                  <v-icon small>mdi-download</v-icon>Скачать</v-btn
                >
              </template>
              <v-list dense>
                <v-list-item dense link @click="toExcel" v-if="!isAlternate">
                  <v-list-item-title>Таблица (xlsx)</v-list-item-title>
                </v-list-item>
                <v-list-item dense link @click="saveChartToPng">
                  <v-list-item-title>Изображение (png)</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text>
            <v-col class="ml-0 pl-0">
              <div class="chartTitle">{{ subtitle }}</div>
              <div class="legends">
                <div>
                  <div style="float: left; display: flex">
                    <div
                      class="legend_color"
                      :style="`background: linear-gradient(180deg, ${
                        getColorForCategory(1)[0]
                      } 0%, ${getColorForCategory(1)[1]} 100%)`"
                    ></div>
                    <div
                      class="legend_title"
                      :style="isMobile ? 'font-size:.9em' : ''"
                    >
                      Художественный фильм, Телесериал, Мультфильм
                    </div>
                  </div>
                  <div style="float: left; display: flex">
                    <div
                      class="legend_color"
                      :style="`background: linear-gradient(180deg, ${
                        getColorForCategory(8)[0]
                      } 0%, ${getColorForCategory(8)[1]} 100%)`"
                    ></div>
                    <div
                      class="legend_title"
                      :style="isMobile ? 'font-size:.9em' : ''"
                    >
                      Новости
                    </div>
                  </div>
                  <div style="float: left; display: flex">
                    <div
                      class="legend_color"
                      :style="`background: linear-gradient(180deg, ${
                        getColorForCategory(13)[0]
                      } 0%, ${getColorForCategory(13)[1]} 100%)`"
                    ></div>
                    <div
                      class="legend_title"
                      :style="isMobile ? 'font-size:.9em' : ''"
                    >
                      Ток-шоу, ТВ-шоу
                    </div>
                  </div>
                  <div style="float: left; display: flex">
                    <div
                      class="legend_color"
                      :style="`background: linear-gradient(180deg, ${
                        getColorForCategory(0)[0]
                      } 0%, ${getColorForCategory(0)[1]} 100%)`"
                    ></div>
                    <div
                      class="legend_title"
                      :style="isMobile ? 'font-size:.9em' : ''"
                    >
                      Остальное
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col class="mt-2">
              <div id="highchartDiv" :style="contentBlockStyles">
                <div class="airRowTimes" style="left: 0">
                  <div class="airRowTitle">Время</div>
                  <div
                    v-for="(category, index) in data.charts[0].categories"
                    :key="index"
                    class="airRowTime"
                    :class="index == 0 && indicator == 'All' && 'first'"
                    :style="timeColumnStyles"
                  >
                    {{ drawTimeValues(category) }}
                  </div>
                </div>
                <horizontal-scrollbar
                  classes="my-scrollbar"
                  ref="contentScrollbar"
                >
                  <div
                    class="airRowsContent"
                    :style="mainColumnStyles"
                    v-if="indicator !== 'All'"
                  >
                    <div
                      v-for="(chart, column) in data.charts"
                      class="airRowData"
                      :key="column"
                      :style="dataColumnStyles"
                    >
                      <div class="airRowProgs" :style="progsColumnStyles">
                        <div class="airRowTitle">
                          {{ chart.series[0].name }}
                        </div>
                        <div
                          v-for="(prog, row) in chart.programs"
                          :key="row"
                          :style="getProgStyle(prog, row, column)"
                          class="airProgData"
                        >
                          <v-menu :nudge-width="300" offset-x>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <div
                                  :style="`max-height: ${prog.height - 7}px;`"
                                >
                                  <b>{{
                                    prog.height >= cellHeight ? prog.y : ""
                                  }}</b>
                                  <br v-if="prog.height >= cellHeight" />
                                  {{
                                    prog.height >= cellHeight
                                      ? prog.prog.name
                                      : ""
                                  }}
                                </div>
                              </div>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="popupBlockTitle mb-0 pb-0"
                                    >Название</v-col
                                  >
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="popupBlockContent mt-0 pt-0 mb-4 pb-0"
                                    >{{ prog.prog.name }}</v-col
                                  >
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="popupBlockContent mt-0 pt-0 mb-4 pb-0 font-weight-regular"
                                    >{{ drawProgDates(prog) }}</v-col
                                  >
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="popupBlockTitle mt-0 pt-0 mb-0 pb-1"
                                    >{{ chartTitle }}</v-col
                                  >
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="popupBlockContentBig mt-0 pt-0 mb-4 pb-0"
                                    >{{ prog.y }}</v-col
                                  >
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="popupBlockTitle mt-0 pt-0 mb-0 pb-0"
                                    >Канал</v-col
                                  >
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="popupBlockContent mt-0 pt-0 mb-4 pb-0"
                                    >{{ chart.series[0].name }}</v-col
                                  >
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="popupBlockTitle mt-0 pt-0 mb-0 pb-0"
                                    >Описание программы</v-col
                                  >
                                </v-row>
                                <v-row class="mt-0 pt-0">
                                  <v-col
                                    cols="12"
                                    class="popupBlockContentSmall"
                                  >
                                    {{ prog.prog.programDesc }}
                                    <br />
                                    {{ prog.prog.programTags }}
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </div>
                      </div>

                      <div class="airRowValues" v-if="showValues">
                        <div class="airRowTitle">
                          <div
                            style="
                              border-left: 1px solid gray;
                              width: 100%;
                              margin-top: 5px;
                              height: 17px;
                            "
                          >
                            <div style="text-align: center; line-height: 17px">
                              {{
                                chartTitle
                                  .replace("Аудитория,", "Ауд.")
                                  .replace("тыс. чел.", "тыс.")
                              }}
                            </div>
                          </div>
                        </div>
                        <div
                          v-for="(value, chartId) in chart.series[0].data"
                          :style="`height: ${cellHeight}px; line-height: ${cellHeight}px; position: relative;`"
                          :key="chartId"
                        >
                          <img
                            v-if="value.y"
                            :src="require('@/assets/share.png')"
                            :style="`position: absolute; left: ${(left =
                              -100 +
                              (value.y / chart.series[0].maxY) * 97)}px;`"
                          />
                          <div style="position: absolute; right: 0">
                            {{ value.y }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </horizontal-scrollbar>
                <div
                  class="airRowTimes"
                  id="airRowTimes2"
                  :style="rightColumnStyles"
                >
                  <div class="airRowButtons" v-if="!hideScrollButtons">
                    <v-btn v-on:click="scrollDataRight" outlined x-small>
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn v-on:click="scrollDataLeft" outlined x-small>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                  <div class="airRowTitle">Время</div>
                  <div
                    v-for="(category, index) in data.charts[0].categories"
                    :key="index"
                    class="airRowTime"
                    :class="index == 0 && indicator == 'All' && 'first'"
                    :style="timeColumnStyles"
                  >
                    {{ drawTimeValues(category) }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-row justify="center" v-if="chart_loading === true">
              <v-col justify="center"
                ><v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular></v-col
            ></v-row>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          :height="heightChart + 120"
          v-else-if="chart_loading === true"
        >
          <base-loader></base-loader>
        </v-card>
        <v-row
          v-else
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
        >
          <pre-report-mount />
        </v-row>
      </v-col>
    </v-row>
    <script
      defer
      type="application/javascript"
      :src="
        'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=bb38ba5b-71c5-463f-b819-bf5757feaad3&event_type=visit&product=CompareTVsReport&order_id=' +
        check_cookie_name('_ym_uid')
      "
    ></script>
    <preset-drawer
      report="compare-tv"
      v-if="!$route.params.uuid"
    ></preset-drawer>
  </v-container>
</template>
<style lang="scss">
$borderStyle: 1px solid rgba(0, 0, 0, 0.87);

.legends {
  clear: both;
  padding-top: 8px;
  float: left;
}
.legends > div {
  float: left;
  margin-right: 22px;
}
.legend_color {
  float: left;
  width: 20px;
  height: 20px;
  border: 1px solid white;
  border-radius: 4px;
}
.legend_title {
  float: left;
  padding-left: 8px;
  font-weight: 400;
  font-size: 14px;
  height: 16px;
  color: #000;
  padding-right: 20px;
  margin-bottom: 4px;
}

.popupBlockTitle {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.popupBlockContent {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: bold;
  max-width: 500px;
}
.popupBlockContentBig {
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: bold;
  max-width: 500px;
}
.popupBlockContentSmall {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  max-width: 500px;
}
</style>

<script>
import MainFilters from "@/components/MainFilters.vue";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import HorizontalScrollbar from "vue2-scrollbar";
import BaseLoader from "@/components/BaseLoader.vue";
import uuidv4 from "uuid/v4";
import PresetDrawer from "@/components/PresetDrawer.vue";
import PreReportMount from "@/components/PreReportMount.vue";
import { CONSTS } from "../services/constants.js";
import Utils from "../services/utils";

export default {
  name: "CompareTVs",
  components: {
    BaseLoader,
    MainFilters,
    HorizontalScrollbar,
    PresetDrawer,
    PreReportMount,
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    if (!this.hasPriv) return this.$router.push("/");
    window.addEventListener("resize", this.handleResize, true);
  },
  methods: {
    drawProgDates(prog) {
      const sdt = prog.prog.start_loc;
      const edt = prog.prog.stop_loc;

      const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
      const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");

      if (sdate != edate) {
        return `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm")}
               - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                 "DD.MM.YYYY HH:mm"
               )}
              `;
      }

      return `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm")}
              - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
    },
    drawRowDate(dt) {
      return `${moment(dt, "DD.MM.YYYY").locale("ru").format("dd")}, ${dt}`;
    },
    toggleScrolls() {
      if (this.dataWidth >= this.contentWidth - 180) {
        this.hideScrollButtons = false;
        // console.log("part");
      } else {
        this.hideScrollButtons = true;
        //  console.log("full");
      }
    },
    calcWidths() {
      if (!this.data?.charts?.length) return (this.dataWidth = 0);
      this.columnWidth = this.showValues * 97 + 127;
      if (this.indicator === "All") this.columnWidth = this.combinedColumnWidth;
      this.dataWidth = this.data.charts.length * this.columnWidth + 108;
    },
    scrollDataLeft() {
      this.$refs.contentScrollbar.scrollToX(
        this.$refs.contentScrollbar.left + this.columnWidth
      );
    },
    scrollDataRight() {
      this.$refs.contentScrollbar.scrollToX(
        this.$refs.contentScrollbar.left - this.columnWidth
      );
    },
    handleResize() {
      this.contentWidth = document.getElementById("content_card")?.clientWidth;
      if (this.$refs.contentScrollbar) this.$refs.contentScrollbar.scrollToX(0);
    },
    getColorForCategory(category_id) {
      switch (+category_id) {
        case 1:
        case 2:
        case 3:
          return CONSTS.TV_COMPARE_PROG_COLORS[0];
        case 8:
          return CONSTS.TV_COMPARE_PROG_COLORS[1];
        case 13:
        case 23:
          return CONSTS.TV_COMPARE_PROG_COLORS[2];
        default:
          return CONSTS.TV_COMPARE_PROG_COLORS[3];
      }
    },
    getProgStyle(prog, row, column) {
      //console.log(prog, row);
      let top = 0;
      let bottom = 60 * 24;
      let reportDate;
      const startDate = prog.prog.start_loc.substring(0, 10);
      const stopDate = prog.prog.stop_loc.substring(0, 10);
      if (row == 0) {
        reportDate = stopDate;
      } else {
        reportDate = startDate;
      } //tmp

      if (startDate == reportDate) {
        const [h, m] = prog.prog.start_loc_time.split(":");
        top = h * 60 + +m;
      }
      if (stopDate == reportDate) {
        const [h, m] = prog.prog.stop_loc_time.split(":");
        bottom = 1 + h * 60 + +m;
      }

      top = (top * this.cellHeight) / 15 + 28;
      bottom = (bottom * this.cellHeight) / 15 + 28;

      let height = bottom - top;

      //console.log(prog, top, bottom, height)
      const colors = this.getColorForCategory(prog.prog.category_id);
      prog.style = `top: ${top + 1}px; 
        height:${height - 2}px; 
        background: linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 100%);
`;
      if (row == 0) {
        // prog.style +=` border-top: 1px solid #fff;`
      }
      if (column > 0) {
        // prog.style +=` border-left: 1px solid rgba(0,0,0,.87);`
      }
      prog.height = height;
      return prog.style;
    },

    getValuesStyles(value, row) {
      value.dev = (value.y_tvr - this.avgValue) / this.avgValue;
      const color = this.getColorForCategory(value.prog.category_id)[0];
      return `height: ${this.cellHeight}px; line-height: ${
        this.cellHeight
      }px; top: ${
        this.cellHeight * (row + 1) - 4 + 22
      }px; background-color: ${color};`;
    },

    drawTimeValues(str) {
      let [h, m] = str.split(":");
      str += ` - ${h}:${+m + 14}`;
      return str;
    },
    viewBtnClick(e) {
      this.showValues = e;
      this.contentShift = 0;
    },
    exportBtnClick(e) {
      switch (e) {
        case "png":
          this.saveChartToPng();
          break;
        case "svg":
          break;

        case "pdf":
          break;

        default:
          //xls
          this.toExcel();
          break;
      }
    },
    stopCube() {
      this.chart_loading = null;
      this.isStoped = true;
      this.axiosCancel();
    },
    async saveChartToPng() {
      const canvas = await html2canvas(
        document.querySelector(`#highcharts_png`)
      );
      Utils.saveAs(canvas.toDataURL(), `${this.filename}.png`);
    },
    async toExcel() {
      let start = moment(this.range.start).locale("ru").format("DD.MM.YYYY");
      let end = moment(this.range.end).locale("ru").format("DD.MM.YYYY");
      let startTime = moment(this.range.start).locale("ru").format("HH:mm");
      let endTime = moment(this.range.end).locale("ru").format("HH:mm");
      const city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });

      const source = this.sources.find((el) => {
        return el.id === this.selectedSourceId;
      });

      const channels = this.channels.filter((el) => {
        return this.selectedChannelsIds.includes(el.id);
      });

      let jsonArray = {
        data: this.data,
        ranges: this.ranges,
      };
      const params = [
        "Id отчета: " + this.reportUuid,
        "Время: " + this.reportTime,
        "Период: " + `${start} ${startTime} - ${end} ${endTime}`,
        "Источник: " + source.name,
        "Справочник: " + this.helper,
        "Коэффициенты: " + this.coef,
        "Регион: " + city.name,
        "Демография: " + this.demographyStr,
      ];

      if (channels.length > 1) {
        params.push(
          `Телеканалы: ${channels.map((item) => item.export_name).join(", ")}`
        );
      } else {
        if (channels.length === 1)
          params.push(`Телеканал: ${channels[0].export_name}`);
      }
      let req = {
        jsonArray,
        params,
        reportName: this.reportName,
        indicator: this.indicator,
      };
      let response = await axios.post("jsonToExcel", req, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.filename}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    copyToClipboard(containerid) {
      var range = document.createRange();
      let containerNode = document.getElementById(containerid); //// this part
      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      alert("Данные скопированы");
    },
    async chartWithUuid() {
      //console.log(this.$route.params.uuid)
      if (!this.$route.params.uuid) return;
      this.chart_loading = true;
      let res = await axios
        .post(`result/${this.$route.params.uuid}`)
        .catch(function () {
          this.chart_loading = null;
          this.isStoped = true;
          alert("Произошла ошибка");
        });
      if (res.data.message == "not ready") {
        this.reportRequest = res.data.request;
        setTimeout(() => {
          if (!this.isStoped) this.chartWithUuid();
        }, 1000);
        return;
      }

      if (this.isStoped) return;
      this.reportRequest = res.data.request;
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      this.reportUuid = this.$route.params.uuid;
      this.report = res.data.request["report"];
      this.selectedCityId = res.data.request["geo"];
      this.selectedChannelsIds = res.data.request["tv"]?.split(",");
      this.selectedSourceId = res.data.request["source_id"];
      this.helper = res.data.request["helper"];
      this.coef = res.data.request["useCoeff"];
      this.indicator = res.data.request["indicator"];
      this.manytv = res.data.request["manytv"];
      this.aggregate = res.data.request["aggregate"];
      this.detalisation = res.data.request["detalisation"];
      this.demographyStr = Utils.demographyStr({
        demoFrom: res.data.request["demoFrom"],
        demoTo: res.data.request["demoTo"],
        demoGroup: res.data.request["demoGroup"],
      });
      this.demographyIcon = Utils.demographyIcon(this.demographyStr);
      this.range.through = res.data.request["through"] == true;
      this.range.weekdays = res.data.request["weekdays"].split(",");
      this.range.start = `${res.data.request.startDate}T${res.data.request.startTime}:00.000`;
      this.range.end = `${res.data.request.endDate}T${res.data.request.endTime}:00.000`;
      this.chart_loading = false;

      this.data = res.data.result;
      this.data.load = true;
      this.contentWidth = document.getElementById("content_card")?.clientWidth;
      this.avgValue = this.data.avg;
      this.data.charts.forEach((chart) => {
        chart.series[0].maxY = 0.000001;
        chart.series[0].data.forEach((item) => {
          if (item.y > chart.series[0].maxY) chart.series[0].maxY = item.y;
        });
      });
      this.contentShift = 0;
      this.calcWidths();
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
        this.initSlider();
      });
    },
    async cubeData(req) {
      this.isStoped = false;
      this.data = {};
      this.multiCount = 1;
      this.reportUuid = uuidv4();
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      req.set("uuid", this.reportUuid);
      req.set("detalisation", 15);
      req.set("report_type", "hour");
      req.set("aggregate", "Day");
      req.set("requestedReport", this.reportName);

      this.isMulti = false;
      this.tvForAccent = [req.get("tv")];
      this.chart_loading = true;
      let cubeObj = {};
      req.forEach(function (value, key) {
        cubeObj[key] = value;
      });
      cubeObj["email"] = this.$store.getters.StateUser.email;

      cubeObj["action"] = "MakeReport";
      // eslint-disable-next-line no-undef
      let reportUniqid = Utils.generateUID();
      cubeObj["uniqid"] = reportUniqid;
      cubeObj["uid"] = this.$store.getters.StateUser.uid;
      cubeObj["ym_uid"] = this.check_cookie_name("_ym_uid");
      if (process.env.VUE_APP_ALTERNATE_UI != "true") {
      // eslint-disable-next-line no-undef
        mh_px(300, cubeObj);
      }
      // eslint-disable-next-line no-unused-vars
      let timeReq = performance.now();
      const CancelToken = axios.CancelToken;
      let that = this;
      let res = await axios
        .post("reports/cubedata", req, {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            that.axiosCancel = c;
          }),
        })
        .catch(function () {
          this.chart_loading = null;
          this.isStoped = true;
          alert("Произошла ошибка");
        });

      if (this.isStoped) return;
      this.report = req.get("report_type");
      this.selectedCityId = req.get("geo");
      this.selectedChannelsIds = req.get("tv")?.split(",");
      this.selectedSourceId = req.get("source_id");
      this.helper = req.get("helper");
      this.coef = req.get("useCoeff");
      this.chart_loading = false;
      timeReq = performance.now() - timeReq;
      if (process.env.VUE_APP_ALTERNATE_UI != "true")
      // eslint-disable-next-line no-undef
        mh_px(300, {
          ym_uid: this.check_cookie_name("_ym_uid"),
          uniqid: reportUniqid,
          action: "DataReport",
          //  email: this.$store.getters.StateUser.email,
          uid: this.$store.getters.StateUser.uid,
          msecs: timeReq,
        });

      this.indicator = req.get("indicator");
      this.manytv = req.get("manytv");
      this.aggregate = req.get("aggregate");
      this.detalisation = req.get("detalisation");
      this.demographyStr = Utils.demographyStr({
        demoFrom: req.get("demoFrom"),
        demoTo: req.get("demoTo"),
        demoGroup: req.get("demoGroup"),
      });
      this.demographyIcon = Utils.demographyIcon(this.demographyStr);
      this.data = { charts: [] };
      if (res.data.res) {
        res.data.res.forEach((channel) => {
          this.data.charts.push(channel.charts[0]);
        });
      }
      this.data.load = true;
      this.contentWidth = document.getElementById("content_card")?.clientWidth;
      this.avgValue = this.data.avg;
      this.data.charts.forEach((chart) => {
        chart.series[0].maxY = 0.000001;
        chart.series[0].data.forEach((item) => {
          if (item.y > chart.series[0].maxY) chart.series[0].maxY = item.y;
        });
      });
      this.contentShift = 0;
      this.calcWidths();
      setTimeout(() => window.dispatchEvent(new Event("resize")));
    },
  },
  data: () => ({
    demographyStr: "", // тесктовое описание демографии, заполняется посли построения отчета
    demographyIcon: "", // иконка демографии, заполняется посли построения отчета
    reportRequest: {},
    axiosCancel: null,
    reportName: "compare-tv",
    avgValue: 1,
    hideScrollButtons: true,
    contentShift: 0,
    contentWidth: 0,
    columnWidth: 0,
    dataWidth: 0,
    rightColumnPos: 0,
    ranges: [-30, -11, 11, 30],
    cellHeight: 32,
    combinedColumnWidth: 66 * 3,
    showValues: true,
    toggle_graphType: 0,
    selectedCityId: null,
    selectedSourceId: null,
    selectedChannelsIds: [],
    range: {},
    tableSearch: "",
    report: null,
    isMulti: false,
    detalisation: 1,
    aggregate: null,
    multiCount: 1,
    visionType: "graph",
    isStoped: false,
    allProgramms: [],
    data: {},
    manytv: false,
    indicator: "",
    chart_loading: null,
    cities: [],
    channels: [],
    sources: null,
    pageData: {
      title: "Сетки вещания",
      type: "compare-tv",
      many_channel: true,
    },
  }),
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    hasPriv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_compare_tv
      ];
    },
    filename() {
      const city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });
      const channels = this.channels.filter((el) => {
        return this.selectedChannelsIds.includes(el.id);
      });
      const shows = [];
      const filename = Utils.createFilename({
        range: this.range,
        cities: city,
        channels: channels,
        shows: shows,
        indicator: this.chartTitle,
        reportTime: this.reportTime,
      });
      return filename;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    subtitle() {
      let start = moment(this.range.start).locale("ru").format("DD MMM YYYY");
      let end = moment(this.range.end).locale("ru").format("DD MMM YYYY");
      let startTime = moment(this.range.start).locale("ru").format("HH:mm");
      let endTime = moment(this.range.end).locale("ru").format("HH:mm");
      let city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });
      return `${city.name}, ${start} - ${end}, ${startTime} - ${endTime}`;
    },
    tableHeaders() {
      if (this.isMulti) {
        let headers = [
          {
            text: "№",
            value: "num",
            visible: true,
          },
          {
            text: "Дата",
            value: "date",
            visible: this.report === "hour" ? this.aggregate === "Day" : false,
          },
          {
            text:
              this.report === "hour"
                ? "Время"
                : this.manytv === "true"
                ? "Канал"
                : "Период",
            value: "time",
            visible: true,
          },
        ];

        if (this.data.charts.length) {
          for (let i = 0; i < this.data.charts[0].series.length; i++) {
            headers.push({
              text: this.data.charts[0].series[i].name,
              value: "y" + i,
              visible: true,
            });
          }
        }

        return headers.filter((el) => {
          return el.visible;
        });
      }

      if (this.indicator === "All") {
        return [
          {
            text: "№",
            value: "num",
            visible: true,
          },
          {
            text: "Канал",
            value: "channel",
            visible: true,
          },
          {
            text: this.chartTitle,
            value: "aud",
            visible: true,
          },
          {
            text: "Доля, %",
            value: "share",
            visible: true,
          },
          {
            text: "Рейтинг",
            value: "tvr",
            visible: true,
          },
          {
            text: "Охват, тыс. телезрителей",
            value: "reach",
            visible: true,
          },
          {
            text: "Охват, %",
            value: "reachPercent",
            visible: true,
          },
        ];
      }

      let headers = [
        {
          text: "№",
          value: "num",
          visible: true,
        },
        {
          text: "Дата",
          value: "date",
          visible: this.report === "hour" ? this.aggregate === "Day" : false,
        },
        {
          text:
            this.report === "hour"
              ? "Время"
              : this.manytv === "true"
              ? "Канал"
              : "Период",
          value: "time",
          visible: true,
        },
        {
          text: this.chartTitle,
          value: "y",
          visible: true,
        },
      ];
      if (this.indicator === "AudienceDemo") {
        headers[headers.length - 1]["text"] = "Мужчины";
        headers.push({
          text: "Женщины",
          value: "y2",
          visible: true,
        });
      }

      return headers.filter((el) => {
        return el.visible;
      });
    },
    heightChart() {
      let h = 400;
      if (this.data && this.data.charts && this.data.charts.length) {
        h = this.data.charts[0].categories.length * this.cellHeight + 28 + 10;
      }
      return h;
    },
    timeColumnStyles() {
      let top = 0;
      if (this.indicator === "All") top = 22;
      return `height: ${this.cellHeight}px; line-height: ${this.cellHeight}px; top: ${top}px; position:relative;`;
    },
    progsColumnStyles() {
      let height = this.heightChart;
      if (this.indicator === "All") height += 22;
      return `height: ${height}px; background-color: white; z-index:3; position: relative;`;
    },
    contentBlockStyles() {
      let height = this.heightChart;
      if (this.indicator === "All") height += 22;
      return `height: ${height}px; position: relative; width: ${
        this.contentWidth - 71
      }px; overflow: hidden;`;
    },
    rightColumnStyles() {
      let visibleDataWidth = this.dataWidth;
      if (visibleDataWidth >= this.contentWidth - 180) {
        visibleDataWidth = this.contentWidth - 180;
      }
      console.log(visibleDataWidth);
      if (this.isMobile) return `display: none; `;
      return `position: absolute; z-index: 3; left: ${visibleDataWidth}px; background-color:#fff;`;
    },
    mainColumnStyles() {
      let height = this.heightChart;
      let width = this.dataWidth + 1;
      if (this.indicator === "All") height += 22;
      if (!this.isMobile) width += 107;
      return `float: left; width: ${width}px; padding-left: 108px; height: ${height}px; overflow: hidden`;
    },
    dataColumnStyles() {
      return `width: ${this.columnWidth}px; float: left; position: relative; left: ${this.contentShift}px`;
    },
    chartTitle() {
      let title = "Показатель";
      switch (this.indicator) {
        case "Watchtime":
          title = "Время просмотра, с";
          break;
        case "UuidCount":
          title = "Уникальные устройства за час, шт.";
          break;
        case "SanCount":
          title = "Уникальные домохозяйства за час, шт.";
          break;
        case "UuidCountDay":
          title = "Уникальные устройства за сутки, шт.";
          break;
        case "SanCountDay":
          title = "Уникальные домохозяйства за сутки, шт.";
          break;
        case "Audience":
          title = "Аудитория, тыс.";
          break;
        case "All":
          title = "Аудитория, тыс.";
          break;
        case "AudienceDemo":
          title = "Аудитория, тыс.";
          break;
        case "TVR":
          title = "Рейтинг";
          break;
        case "TVRTotal":
          title = "Рейтинг общий";
          break;
        case "Share":
          title = "Доля, %";
          break;
        case "Reach":
          title = "Охват, тыс. чел.";
          break;
        case "ReachPercent":
          title = "Охват, %";
          break;
      }
      return title;
    },
  },
  watch: {
    dataWidth() {
      this.toggleScrolls();
    },
    contentWidth() {
      this.toggleScrolls();
    },
    showValues() {
      this.calcWidths();
      setTimeout(() => window.dispatchEvent(new Event("resize")));
    },
    indicator() {
      this.calcWidths();
      if (this.indicator === "All") {
        this.toggle_graphType = 1;
        // element.style.visibility = "hidden";
      } else {
        //element.style.visibility = "visible";
      }
    },
    toggle_graphType() {
      this.visionType = this.toggle_graphType === 0 ? "graph" : "table";
    },
  },
};
</script>
