<template>
  <div data-html2canvas-ignore>
    <v-dialog v-model="dialog" max-width="697" v-if="!isAlternate">
      <template v-slot:activator="{ on, attrs }">
        <span
          v-if="newVersion"
          v-bind="attrs"
          v-on="on"
          class="chartHelper help-btn ml-1"
        >
          <v-icon color="info" class="pr-1">mdi-help-circle</v-icon>
          <span style="text-decoration: underline">Что это за отчет?</span>
        </span>
        <v-btn v-else v-bind="attrs" v-on="on" icon color="info" class="ml-1"
          ><v-icon color="info">mdi-information</v-icon>
        </v-btn>
      </template>
      <v-card class="pb-2">
        <v-card-title>
          <v-icon color="info" large class="mr-2">mdi-information</v-icon
          >{{ titleStr }}
          <v-spacer></v-spacer>
          <v-btn large icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="body-2 black--text">
          <p v-html="textStr"></p>
          <v-img :src="imgSrcStr"></v-img>
          <p class="caption mh_caption" v-if="subtitleStr">
            {{ subtitleStr }}
          </p>
          <p v-if="text2Str" v-html="text2Str"></p>
        </v-card-text>

        <v-card-actions>
          <a :href="'mailto:' + supportEmail">Связаться с поддержкой</a>
          <v-spacer></v-spacer>
          <v-btn class="black--text" outlined @click="dialog = false">
            Понятно
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "HelpBtn",
  props: {
    title: {
      type: String,
      default: "Помощь",
    },
    text: {
      type: String,
      default: "Если у вас возникли вопросы, свяжитесь с поддержкой.",
    },
    text2: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "",
    },
    newVersion: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      dialog: false,
    };
  },
  methods: {
    getFieldFromMetaHelp(field) {
      if (this.$route.meta.help) {
        return this.$route.meta.help[field] || "";
      }
      return "";
    },
  },
  computed: {
    supportEmail() {
      return process.env.VUE_APP_SUPPORT_EMAIL;
    },
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    textStr() {
      return this.getFieldFromMetaHelp("text") || this.text;
    },
    titleStr() {
      return this.getFieldFromMetaHelp("title") || this.title;
    },
    text2Str() {
      return this.getFieldFromMetaHelp("text2") || this.text2;
    },
    subtitleStr() {
      return this.getFieldFromMetaHelp("subtitle") || this.subtitle;
    },
    imgSrcStr() {
      return this.getFieldFromMetaHelp("img") || this.imgSrc;
    },
  },
};
</script>

<style scoped>
.mh_caption {
  opacity: 0.7;
}
</style>
