<template>
  <v-menu
    class="mh-calendar"
    offset-y
    :close-on-content-click="false"
    v-model="openedCalendar"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        class="mh-dropdown-slot mh-calendar-field"
        dense
        :hide-details="isMobile ? false : 'auto'"
        icon-color="red"
        v-on:click:append="openedCalendar = true"
        :disabled="disabled"
        readonly
        :value="formatInputValue()"
        v-bind="attrs"
        v-on="on"
      >
      <template v-slot:append>
        <v-tooltip
          top
          v-if="dayCount == 0"
          content-class="text-center"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="warning" v-bind="attrs" v-on="on"
              >mdi-alert</v-icon
            >
          </template>
          <span class="caption"
            >Выбранные даты не пересекаются с фильтрами по дням недели.<br> Отчет будет пустым, уточните параметры календаря.</span
          >
        </v-tooltip>
        <v-icon v-if="dayCount != 0">mdi-calendar-range</v-icon>
      </template>
        <template v-slot:label>
          <span v-if="range">
            {{ startTime }} - {{ endTime }}
            <span
              v-if="String(formatCalendarLabel()).length && withRange"
              v-html="formatCalendarLabel()"
              >{{}}</span
            ></span
          >
          <span v-else> Период </span>
        </template>
      </v-text-field>
    </template>
    <v-card outlined class="mh-period-popup">
      <v-card-title
        class="text-lg-h5 mh-page-title text-md-h6 justify-space-between pb-2 flex-row"
        >Период
        <v-spacer></v-spacer>
        <v-btn style="float: right" icon @click="openedCalendar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <vc-date-picker
          ref="calendar"
          style="width: 100%"
          :step="1"
          :columns="$screens({ default: 1, lg: 3 })"
          v-model="range"
          :mode="withRange ? 'dateTime' : 'date'"
          :attributes="attrTes"
          :disabled-dates="[
            {
              start: null,
              end: null,
            },
          ]"
          :available-dates="enabledDates"
          :is-range="withRange"
          is24hr
          @drag="dragValue = $event"
          @dayclick="calendarDayClick"
          :drag-attribute="selectDragAttribute"
          :select-attribute="selectAttribute"
        >
          <template v-slot:header-title="props"
            ><span @click="monthClick(props)">{{ props.monthLabel }} {{props.yearLabel}}</span>
          </template>
          <template v-slot:footer>
            <div v-if="!withRange">
              <div class="mh-preset-btns footer-btns" style="clear: both">
                <v-btn
                  v-if="!isMobile"
                  color="grey lighten-4"
                  elevation="0"
                  class="mh-20"
                  @click="clearCalendar"
                  >Очистить
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  elevation="0"
                  class="grey mr-2 lighten-4 mh-tertiary-border"
                  @click="cancelCalendar"
                  >Отмена
                </v-btn>
                <v-btn
                  elevation="0"
                  color="primary"
                  class="mh-primary-border"
                  @click="openedCalendar = false"
                  >Применить
                </v-btn>
                <v-spacer v-if="isMobile"></v-spacer>
              </div>
            </div>
            <div v-if="withRange">
              <div
                ref="caption"
                class="mh-vc-time-caption"
                style="display: none"
              >
                <span class="primary--text">Временной интервал</span>
              </div>
              <v-radio-group
                v-if="!isMobile"
                v-model="through"
                :disabled="disabledThrough"
                class="mh-period-type"
              >
                <v-radio label="Сквозной" :value="'through'"></v-radio>
                <v-radio label="Посуточно" :value="'dayli'"></v-radio>
              </v-radio-group>
              <div class="mh-period-type-hint">
                <p class="mh-caption">
                  {{ through === "through" ? throughText : notThroughText }}
                </p>
              </div>

              <v-radio-group
                style="clear: both"
                row
                class="ml-3 mb-n5 mh-mobile-row"
                v-if="isMobile"
                v-model="through"
                :disabled="disabledThrough"
              >
                <v-radio label="Сквозной период" :value="'through'"></v-radio>
                <v-radio label="Посуточно" :value="'dayli'"></v-radio>
                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-card-text>
                        <p class="body-2 pt-5">
                          <v-icon color="primary" class="mr-2"
                            >mdi-information
                          </v-icon>
                          Справка
                          <v-btn
                            style="float: right"
                            icon
                            @click="dialog.value = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </p>
                        <p class="text-h6">Сквозной период</p>
                        <p class="body-2">{{ throughText }}</p>
                        <p class="text-h6">Посуточный период</p>
                        <p class="body-2">{{ notThroughText }}</p>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <v-btn
                          class="mb-3"
                          block
                          outlined
                          large
                          @click="dialog.value = false"
                          >Понятно
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-radio-group>
              <div class="mh-preset-btns" style="clear: both">
                <v-chip-group v-model="selectedPreset" active-class="primary">
                  <v-chip
                    small
                    v-for="(tag, index) in tags"
                    :key="tag"
                    @click="showMenu(index, $event)"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
                <v-menu
                  v-model="showWeeks"
                  :position-x="mousex"
                  :position-y="mousey"
                  absolute
                >
                  <v-list dense>
                    <v-list-item dense link class="primary white--text">
                      <v-list-item-title>Текущая</v-list-item-title>
                    </v-list-item>
                    <v-list-item dense link @click="selectLastWeek">
                      <v-list-item-title>Прошлая</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu
                  v-model="showMonths"
                  :position-x="mousex"
                  :position-y="mousey"
                  absolute
                >
                  <v-list dense>
                    <v-list-item dense link class="primary white--text">
                      <v-list-item-title>Текущий</v-list-item-title>
                    </v-list-item>
                    <v-list-item dense link @click="selectLastMonth">
                      <v-list-item-title>Прошлый</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu
                  v-model="showYears"
                  :position-x="mousex"
                  :position-y="mousey"
                  absolute
                >
                  <v-list dense>
                    <v-list-item dense link class="primary white--text">
                      <v-list-item-title>Текущий</v-list-item-title>
                    </v-list-item>
                    <v-list-item dense link @click="selectLastYear">
                      <v-list-item-title>Прошлый</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-menu
                  v-model="showQuarters"
                  :position-x="mousex"
                  :position-y="mousey"
                  absolute
                >
                  <v-list dense>
                    <v-list-item dense link class="primary white--text">
                      <v-list-item-title
                        >Текущий (Q{{ quarters.length + 1 }})
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      dense
                      v-for="q in quarters"
                      link
                      :key="q"
                      @click="selectQuarterNumber(q)"
                    >
                      <v-list-item-title>Q{{ q }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div
                class="bg-gray-100 text-center p-2 border-t rounded-b-lg pb-4 pt-4 mh-day-btns"
                style="clear: both"
              >
                <v-row justify="start">
                  <v-col cols="auto" class="mh-first-col">
                    <v-btn-toggle multiple dense v-model="workDaysBtns">
                      <v-btn
                        :x-small="isXSM"
                        :small="isMobile && !isXSM"
                        v-for="btn in workDays"
                        class="mh-noactive-toggle"
                        :active-class="'white black--text mh-active-toggle'"
                        :key="btn.id"
                        :value="btn.id"
                        @click="fastBtnsClick(btn)"
                      >
                        {{ btn.text }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn-toggle multiple dense v-model="weekDaysBts">
                      <v-btn
                        :x-small="isXSM"
                        :small="isMobile && !isXSM"
                        v-for="btn in weekDays"
                        class="mh-noactive-toggle"
                        :active-class="'white white--text mh-active-toggle'"
                        :key="btn.id"
                        :value="btn.id"
                        @click="fastBtnsClick(btn)"
                      >
                        {{ btn.text }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="auto pt-4">
                    Дней для отчета: {{ dayCount }}
                    <v-tooltip
                      top
                      v-if="dayCount == 0"
                      content-class="text-center"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning" v-bind="attrs" v-on="on"
                          >mdi-alert</v-icon
                        >
                      </template>
                      <span class="caption"
                        >Скорректируйте фильтры по дням</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>

              <div class="mh-preset-btns footer-btns" style="clear: both">
                <v-btn
                  v-if="!isMobile"
                  color="grey lighten-4"
                  elevation="0"
                  class="mh-20"
                  @click="clearCalendar"
                  >Очистить
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  elevation="0"
                  class="mr-2 grey lighten-4 mh-tertiary-border"
                  @click="cancelCalendar"
                  >Отмена
                </v-btn>
                <v-btn
                  elevation="0"
                  color="primary"
                  class="mh-primary-border"
                  @click="openedCalendar = false"
                  >Применить
                </v-btn>
                <v-spacer v-if="isMobile"></v-spacer>
              </div>
            </div>
          </template>
          <!--          <template v-slot:day-popover="{ format }">-->
          <!--            <div>-->
          <!--              {{ format(dragValue ? dragValue.start : range.start, "MMM D") }}-->
          <!--              - -->
          <!--              {{ format(dragValue ? dragValue.end : range.end, "MMM D") }}-->
          <!--            </div>-->
          <!--          </template>-->
        </vc-date-picker>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "MainCalendar",
  emits: ["calendarok", "ok"],
  props: {
    disabledThrough: {
      type: Boolean,
      default: true, //false,
    },
    withRange: {
      type: Boolean,
      default: true, //false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(["storeCalendarStart", "storeCalendarEnd", "storeFilters"]),
    monthClick(props) {
      //console.log(props)
      const [year, month] = props.key.split("-");
      const now = moment();
      let start = moment()
        .set("year", year)
        .set("month", month - 1)
        .startOf("month");
      let end = moment()
        .set("year", year)
        .set("month", month - 1)
        .endOf("month");
      if (end >= now) end = now.endOf("day");
      if (start <= now) {
        this.range = {
          start: start.toDate(),
          end: end.toDate(),
          weekdays: this.weekdays,
        };
      }
    },
    async saveFilters() {
      //console.log(this.range)
      const selectedStartDate = moment(this.range.start).format("YYYY-MM-DD");
      const selectedEndDate = moment(this.range.end).format("YYYY-MM-DD");
      const selectedStartTime = moment(this.range.start).format("HH:mm");
      const selectedEndTime = moment(this.range.end).format("HH:mm");
      await this.storeFilters({
        selectedWeekdays: this.weekdays.join(","),
        selectedThrough: this.through,
        selectedStartDate,
        selectedStartTime,
        selectedEndDate,
        selectedEndTime,
      });
    },
    async initCalendar(isClear = false) {
      if (isClear) {
        this.through = "through";
        for (let i = 0; i <= 8; i++) this.btns[i].value = true;
      }
      this.openedCalendar = false;
      this.allowedPeriods = [];
      this.attrTes = [
        {
          order: 5,
          highlight: {
            color: "green",
            fillMode: "solid",
            contentStyle: {
              color: "white",
            },
            style: {
              backgroundColor: this.isAlternate ? "#801D67" : "#5cb85c",
            },
          },
          dates: {},
        },
        {
          key: "today",
          highlight: {
            color: "white",
            fillMode: "light",
            contentClass: "bold",
          },
          dates: new Date(),
        },
      ];
      this.dragValue = null;
      this.range = {};
      this.lastRange = {};
      await this.getPeriods();
      let storeStart = null;
      if (this.storeStart) storeStart = moment(this.storeStart, "YYYY-MM-DD HH:mm:ss").toDate();
      let storeEnd = null;
      if (this.storeEnd) storeEnd = moment(this.storeEnd, "YYYY-MM-DD HH:mm:ss").toDate();
      if (this.allowedPeriods.length) {
        let start = moment().subtract(2, "days").startOf("day").toDate();
        let end = moment().endOf("day").toDate();
        if (!this.withRange) {
          start = moment().startOf("day").toDate();
        }
        for (let i = 0; i < this.enabledDates.length; i++) {
          if (
            this.enabledDates[i].start < start &&
            this.enabledDates[i].end < start
          ) {
            start = moment(this.enabledDates[i].end).startOf("day").toDate();
            end = moment(this.enabledDates[i].end).endOf("day").toDate();
          }
        }
        this.range = {
          start: start,
          end: end,
          weekdays: [1, 7],
        };

        this.openedCalendar = false;
      } else {
        //console.log(this.selectedStartDate)
        //storeStart =  this.range.start = "1970-01-01 00:00:00";
        //storeEnd = this.range.end = "1970-01-01 23:59:59";
      }

      if (
        storeStart != null &&
        storeEnd != null &&
        !isClear &&
        this.withRange
      ) {
        this.range = {
          start: storeStart,
          end: storeEnd,
          weekdays: this.weekdays,
        };
       // console.log(`range from filters`, this.range)
      }
      if (this.$route.params.uuid) {
        let res = await axios.post(`/calendar/` + this.$route.params.uuid);
        if (res.data) {
          this.allowedPeriods = res.data;
          this.range = {
            start: res.data.start,
            end: res.data.end,
            weekdays: res.data.weekdays.split(","),
          };
        }
      }
    },
    selectLastWeek() {
      let start = moment().subtract(1, "weeks").startOf("isoWeek").toDate();
      let end = moment().subtract(1, "weeks").endOf("isoWeek").toDate();
      this.range = {
        start: start,
        end: end,
      };
      const calendar = this.$refs.calendar;
      calendar.move(this.range.end);
    },
    selectLastMonth() {
      let start = moment().subtract(1, "months").startOf("month").toDate();
      let end = moment().subtract(1, "months").endOf("month").toDate();
      this.range = {
        start: start,
        end: end,
      };
      const calendar = this.$refs.calendar;
      calendar.move(this.range.end);
    },
    selectQuarterNumber(num) {
      let subq = moment().quarter() - num;
      let start = moment()
        .subtract(subq, "quarters")
        .startOf("quarter")
        .toDate();
      let end = moment().subtract(subq, "quarters").endOf("quarter").toDate();
      this.range = {
        start: start,
        end: end,
      };
      const calendar = this.$refs.calendar;
      calendar.move(this.range.end);
    },
    selectLastYear() {
      let start = moment().subtract(1, "years").startOf("year").toDate();
      let end = moment().subtract(1, "years").endOf("year").toDate();
      this.range = {
        start: start,
        end: end,
      };
      const calendar = this.$refs.calendar;
      calendar.move(this.range.end);
    },
    showMenu(tagIndex, e) {
      let menuName = "showWeeks";
      switch (tagIndex) {
        case 2:
          if (this.selectedPreset === 2) {
            return;
          }
          menuName = "showWeeks";
          break;
        case 3:
          if (this.selectedPreset === 3) {
            return;
          }
          menuName = "showMonths";
          break;
        case 4:
          if (this.selectedPreset === 4) {
            return;
          }
          menuName = "showQuarters";
          break;
        // case 5:
        //   if (this.selectedPreset === 5) {
        //     return;
        //   }
        //   menuName = "showYears";
        //   break;
        default:
          return;
      }
      this[menuName] = false;
     // console.log(e);
      this.mousex = e.clientX;
      this.mousey = e.clientY;
      this.$nextTick(() => {
        this[menuName] = true;
      });
    },
    //прирванивание выбранного дня ко всему выбранному диапазону
    calendarDayClick(e) {
      //нужно добавить проверку отчета
      if (!this.withRange) this.range = e.range;
    },
    formatCalendarLabel() {
      let days = [];
      for (let i = 0; i < 7; i++) {
        if (this.weekdays.includes(this.btns[i].day[0])) {
          let pre = "";
          let suf = "";

          if (this.through == "dayli") {
            pre = '<span class="mh-days-calendar">';
            suf = "</span>";
          }
          days.push(pre + this.btns[i].text + suf);
        }
      }
      if (this.through != "dayli") {
        days = days.join(", ");
        days = '<span class="mh-days-calendar">' + days + "</span>";
      } else {
        days = days.join("");
      }

      return `${days}`;
    },
    clearCalendar() {
      this.selectedPreset = null;
      let start = moment().subtract(2, "days").startOf("day").toDate();
      if (!this.withRange) {
        start = moment().startOf("day").toDate();
      }
      let end = moment().endOf("day").toDate();
      for (let i = 0; i < this.enabledDates.length; i++) {
        if (
          this.enabledDates[i].start < start &&
          this.enabledDates[i].end < start
        ) {
          start = moment(this.enabledDates[i].end).startOf("day").toDate();
          end = moment(this.enabledDates[i].end).endOf("day").toDate();
        }
      }
      this.range = {
        start: start,
        end: end,
        weekdays: [1, 7],
      };
      this.btns = [
        {
          id: "mon",
          text: "пн",
          value: true,
          day: [2],
        },
        {
          id: "tue",
          text: "вт",
          value: true,
          day: [3],
        },
        {
          id: "wed",
          text: "ср",
          value: true,
          day: [4],
        },
        {
          id: "thu",
          text: "чт",
          value: true,
          day: [5],
        },
        {
          id: "fri",
          text: "пт",
          value: true,
          day: [6],
        },
        {
          id: "sat",
          text: "сб",
          value: true,
          day: [7],
        },
        {
          id: "sun",
          text: "вс",
          value: true,
          day: [1],
        },
        {
          id: "weekdays",
          text: "будни",
          value: true,
          day: [2, 3, 4, 5, 6],
        },
        {
          id: "weekend",
          text: "выходные",
          value: true,
          day: [1, 7],
        },
      ];
    },
    cancelCalendar() {
      this.selectedPreset = null;
      if (this.lastBtns.length > 0) {
        this.btns = this.lastBtns;
      }
      if (JSON.stringify(this.lastRange) !== "{}") {
        this.range = this.lastRange;
      }
      this.openedCalendar = false;
    },
    timerHead() {
      if (this.openedCalendar) {
        let vctimes = document.getElementsByClassName("vc-date");
        if (vctimes.length) {
          if (this.through === "through") {
            let mstart = moment(this.range.start).locale("ru");
            let mend = moment(this.range.end).locale("ru");

            this.time1head = `<span class="vc-mh-weekday">${mstart.format(
              "ddd"
            )}</span><span class="vc-mh-month"> ${mstart.format(
              "MMM"
            )} </span><span class="vc-mh-day"> ${mstart.format(
              "DD"
            )} </span><span class="vc-mh-year"> ${mstart.format(
              "YYYY"
            )} </span>`;
            this.time2head = `<span class="vc-mh-weekday"> ${mend.format(
              "ddd"
            )} </span><span class="vc-mh-month"> ${mend.format(
              "MMM"
            )} </span><span class="vc-mh-day"> ${mend.format(
              "DD"
            )} </span><span class="vc-mh-year"> ${mend.format("YYYY")} </span>`;

            vctimes[0].innerHTML = this.time1head;
            vctimes[1].innerHTML = this.time2head;
            this.$refs.caption.style.display = "none";
          } else {
            vctimes[0].innerHTML = "<span class='primary--text'>&nbsp;</span>";
            vctimes[1].innerHTML = "<span class='primary--text'>&nbsp;</span>";
            this.$refs.caption.style.display = "block";
          }
        }
      }
    },
    async getPeriods() {
      let res = await axios.post("/params/periods");
      if (res.data) {
        this.allowedPeriods = res.data;
      }
    },
    formatInputValue() {
      let start = moment(this.range.start).locale("ru").format("DD MMM YYYY");
      let end = moment(this.range.end).locale("ru").format("DD MMM YYYY");
      return `${start} - ${end}`;
    },
    dayClick() {
      this.attrTes.dates = [];
    },
    fastBtnsClick(btn) {
      this.lastBtns = this.btns;
      btn.value = !btn.value;
      let enabled = btn.value;
      if (btn.id.length === 3) {
        if (btn.id === "sun" || btn.id === "sat") {
          this.btns[8].value = this.btns[6].value && this.btns[5].value;
        } else {
          this.btns[7].value =
            this.btns[0].value &&
            this.btns[1].value &&
            this.btns[2].value &&
            this.btns[3].value &&
            this.btns[4].value;
        }
      } else {
        if (btn.id === "weekend") {
          this.btns[5].value = enabled;
          this.btns[6].value = enabled;
        }
        if (btn.id === "weekdays") {
          this.btns[0].value = enabled;
          this.btns[1].value = enabled;
          this.btns[2].value = enabled;
          this.btns[3].value = enabled;
          this.btns[4].value = enabled;
        }
      }
    },
  },
  data() {
    return {
      mousex: 0,
      mousey: 0,
      showWeeks: false,
      showMonths: false,
      showQuarters: false,
      showYears: false,
      selectedPreset: null,
      lastBtns: [],
      tags: [
        "Сегодня",
        "Вчера",
        "Неделя",
        "Месяц",
        "Квартал",
        "Год",
        // "Текущая неделя",
        // "Прошлая неделя",
        // "14 дней",
        // "Текущий месяц",
        // "Прошлый месяц",
      ],
      // weekDaysBts: ["weekend", "weekdays"],
      // workDaysBtns: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
      time1head: false,
      time2head: false,
      throughText:
        "Начальный временной интервал задается для первых суток, а конечный – для последних суток",
      notThroughText:
        "Временные интервалы применяются к каждым суткам выбранного периода",
      openedCalendar: false,
      allowedPeriods: [],
      attrTes: [
        {
          order: 5,
          highlight: {
            color: "green",
            fillMode: "solid",
            contentStyle: {
              color: "white",
            },
            style: {
              backgroundColor: this.isAlternate ? "#801D67" : "#5cb85c",
            },
          },
          dates: {},
        },
        {
          key: "today",
          highlight: {
            color: "white",
            fillMode: "light",
            contentClass: "bold",
          },
          dates: new Date(),
        },
      ],
      btns: [
        {
          id: "mon",
          text: "пн",
          value: true,
          day: [2],
        },
        {
          id: "tue",
          text: "вт",
          value: true,
          day: [3],
        },
        {
          id: "wed",
          text: "ср",
          value: true,
          day: [4],
        },
        {
          id: "thu",
          text: "чт",
          value: true,
          day: [5],
        },
        {
          id: "fri",
          text: "пт",
          value: true,
          day: [6],
        },
        {
          id: "sat",
          text: "сб",
          value: true,
          day: [7],
        },
        {
          id: "sun",
          text: "вс",
          value: true,
          day: [1],
        },
        {
          id: "weekdays",
          text: "будни",
          value: true,
          day: [2, 3, 4, 5, 6],
        },
        {
          id: "weekend",
          text: "выходные",
          value: true,
          day: [1, 7],
        },
      ],
      dragValue: null,
      range: {},
      lastRange: {},
      through: "through", //
    };
  },
  async mounted() {
    const startWeekdays = this.storedFilters.selectedWeekdays;
    if (startWeekdays) {
      for (let i = 0; i <= 6; i++) this.btns[i].value = false;
      const wdArray = startWeekdays.split(",");
      for (const w of wdArray) {
        if (w == "2") this.btns[0].value = true;
        if (w == "3") this.btns[1].value = true;
        if (w == "4") this.btns[2].value = true;
        if (w == "5") this.btns[3].value = true;
        if (w == "6") this.btns[4].value = true;
        if (w == "7") this.btns[5].value = true;
        if (w == "1") this.btns[6].value = true;
      }

      this.btns[7].value = this.btns[0].value && this.btns[1].value && this.btns[2].value && this.btns[3].value && this.btns[4].value;
      this.btns[8].value = this.btns[5].value && this.btns[6].value;
    }

    if (this.storedFilters.selectedThrough) {
      this.through = this.storedFilters.selectedThrough;
    } else {
      this.through = "through";
    }
    await this.initCalendar();
    if (!this.range.start) return;
    this.$emit("calendarok", {
      dayCount: this.dayCount,
      start: this.range.start,
      end: this.range.end,
      through: this.through === "through",
      weekdays: this.weekdays,
    });
  },
  watch: {
    selectedPreset() {
      if (this.selectedPreset !== undefined) {
        if (JSON.stringify(this.lastRange) === "{}") {
          this.lastRange = this.range;
        }
        let start = moment().startOf("day").toDate();
        let end = moment().endOf("day").toDate();
        switch (this.selectedPreset) {
          case 1:
            start = moment().subtract(1, "days").startOf("day").toDate();
            end = moment().subtract(1, "days").endOf("day").toDate();
            break;
          case 2: //currentWeek
            start = moment().startOf("isoWeek").toDate();
            end = moment().endOf("day").toDate();
            break;
          // case 3: lastWeek
          //   start = moment().subtract(1, "weeks").startOf("isoWeek").toDate();
          //   end = moment().subtract(1, "weeks").endOf("isoWeek").toDate();
          //   break;
          // case 4: last 14 days
          //   start = moment().subtract(14, "days").startOf("day").toDate();
          //   end = moment().endOf("day").toDate();
          //   break;
          case 3: //currentMonth
            start = moment().startOf("month").toDate();
            end = moment().endOf("day").toDate();
            break;
          // case 4: //lastMonth
          //   // eslint-disable-next-line no-case-declarations
          //   start = moment().subtract(1, "months").startOf("month").toDate();
          //   end = moment().subtract(1, "months").endOf("month").toDate();
          //   break;
          case 4: //currentQuarter
            start = moment().startOf("quarter").toDate();
            end = moment().endOf("day").toDate();
            break;
          case 5: //currentYear
            start = moment().startOf("year").toDate();
            end = moment().endOf("day").toDate();
            break;
        }

        this.range = {
          start: start,
          end: end,
        };
      } else {
        this.range = this.lastRange;
      }
      const calendar = this.$refs.calendar;
      calendar.move(this.range.end, { position: -1 });
    },
    openedCalendar() {
      this.$nextTick(() => {
        if (this.openedCalendar) {
          const calendar = this.$refs.calendar;
          calendar.move(this.range.end, { position: -1 });
        }
      });
      this.timerHead();
      document.getElementsByClassName(
        "vc-date-time"
      )[0].children[1].children[2].children[0].disabled = true;
      document.getElementsByClassName(
        "vc-date-time"
      )[1].children[1].children[2].children[0].disabled = true;
    },
    startDate() {},
    startTime() {},
    endDate() {},
    endTime() {},
    async weekdays() {
      if (!this.range.start) return;
      
      this.attrTes[0].dates = {
        start: this.range.start,
        end: this.range.end,
        weekdays: this.weekdays,
      };

      await this.storeCalendarStart(this.range.start.toISOString());
      await this.storeCalendarEnd(this.range.end.toISOString());

      this.$emit("calendarok", {
        dayCount: this.dayCount,
        start: this.range.start,
        end: this.range.end,
        through: this.through === "through",
        weekdays: this.weekdays,
      });
      await this.saveFilters();
    },
    async range() {
      if (!this.range.start) return;
      this.attrTes[0].dates = {
        start: this.range.start,
        end: this.range.end,
        weekdays: this.weekdays,
      };
      this.timerHead();
      await this.storeCalendarStart(this.range.start.toISOString());
      await this.storeCalendarEnd(this.range.end.toISOString());
      this.$emit("calendarok", {
        dayCount: this.dayCount,
        start: this.range.start,
        end: this.range.end,
        through: this.through === "through",
        weekdays: this.weekdays,
      });
      await this.saveFilters();
    },
    async through() {
      this.timerHead();
      if (!this.range.start) return;

      await this.storeCalendarStart(this.range.start.toISOString());
      await this.storeCalendarEnd(this.range.end.toISOString());
      
      this.$emit("calendarok", {
        dayCount: this.dayCount,
        start: this.range.start,
        end: this.range.end,
        through: this.through === "through",
        weekdays: this.weekdays,
      });
      await this.saveFilters();
    },
  },
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    storedFilters() {
      return this.$store.getters.getFilters;
    },
    storeStart() {
      return this.$store.getters.getCalendarStart;
    },
    storeEnd() {
      return this.$store.getters.getCalendarEnd;
    },
    dayCount() {
      let days = 0;
      let start = moment(this.range.start);
      let end = moment(this.range.end);
      days = end.diff(start, "days") + 1;
      let daysCount = 0;
      for (let i = 0; i < days; i++) {
        let tempDate = moment(this.range.start);
        tempDate.add(i, "days");
        if (this.weekdays.includes(tempDate.weekday() + 1)) {
          daysCount++;
        }
      }
      return daysCount;
    },
    quarters() {
      let qrts = [];
      let qrt = moment().quarter() - 1;

      for (let i = qrt; i > 0; i--) {
        qrts.push(i);
      }
      return qrts;
    },
    isXSM() {
      return this.$vuetify.breakpoint.width < 375;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    weekDaysBts() {
      return this.weekDays
        .filter((el) => el.value)
        .map((el) => {
          return el.id;
        });
    },
    workDaysBtns() {
      return this.workDays
        .filter((el) => el.value)
        .map((el) => {
          return el.id;
        });
    },
    workDays() {
      return this.btns.slice(0, 7);
    },
    weekDays() {
      return this.btns.slice(7);
    },
    enabledDates() {
      if (this.allowedPeriods.length === 0) {
        return [
          {
            start: this.isAdmin
              ? new Date("2021-12-01")
              : new Date("2022-01-01"),
            end: new Date(),
          },
        ];
      }
      let dates = [];

      for (let i = 0; i < this.allowedPeriods.length; i++) {
        dates.push({
          start: new Date(this.allowedPeriods[i].from),
          end: new Date(this.allowedPeriods[i].to),
        });
      }
      return dates;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    startTime() {
      try {
        return moment(this.range.start).format("HH:mm");
      } catch (e) {
        return "";
      }
    },
    endTime() {
      try {
        return moment(this.range.end).format("HH:mm");
      } catch (e) {
        return "";
      }
    },
    weekdays() {
      let days = [];
      this.btns.forEach((btn) => {
        if (btn.value) {
          days.push(...btn.day);
        }
      });
      days = [...new Set(days)];
      return days;
    },
    selectAttribute() {
      return {
        highlight: {
          start: {
            fillMode: "light",
            style: {
              backgroundColor: this.isAlternate ? "#FCE4EC" : "#e8f5e9",
            },
            contentStyle: {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
          base: {
            fillMode: "light",
            style: {
              backgroundColor: this.isAlternate ? "#FCE4EC" : "#e8f5e9",
            },
            contentStyle: {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
          end: {
            fillMode: "light",
            style: {
              backgroundColor: this.isAlternate ? "#FCE4EC" : "#e8f5e9",
            },
            contentStyle: {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
        },
        // popover: {
        //   visibility: "hover",
        //   isInteractive: false, // Defaults to true when using slot
        // },
      };
    },
    selectDragAttribute() {
      return {
        highlight: {
          color: "green",
          start: {
            fillMode: "light",
            style: {
              backgroundColor: this.isAlternate ? "#FCE4EC" : "#e8f5e9",
            },
            contentStyle: {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
          base: {
            fillMode: "light",
            style: {
              backgroundColor: this.isAlternate ? "#FCE4EC" : "#e8f5e9",
            },
            contentStyle: {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
          end: {
            fillMode: "light",
            style: {
              backgroundColor: this.isAlternate ? "#FCE4EC" : "#e8f5e9",
            },
            contentStyle: {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
        },
        // popover: {
        //   visibility: "hover",
        //   isInteractive: false, // Defaults to true when using slot
        // },
      };
    },
    layout() {
      return this.$screens({
        // Default layout for mobile
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true,
        },
        // Override for large screens
        lg: {
          columns: 3,
          rows: 1,
          isExpanded: false,
        },
      });
    },
  },
};
</script>

<style lang="scss">
$mh-mobile-bp: 960px;

.mh-calendar {
}

.mh-calendar-field {
  .mh-days-calendar {
    color: black !important;
    padding: 0 5px;
    background-image: linear-gradient(to right, #ff9801, #fcd033);
    border-radius: 10px;
    @if $VUE_APP_ALTERNATE_UI == true {
      background: #29cad4;
    }
  }

  legend {
    width: 78px !important;
  }
}

.mh-period-popup {
  .vc-title {
    text-transform: capitalize;
  }

  .vc-weeks {
    .vc-weekday {
      font-style: normal;
      font-weight: 400;

      /* blue/darken-4 */

      color: #0d47a1;
      @if $VUE_APP_ALTERNATE_UI == true {
        color: #801d67;
      }
    }
  }

  .v-card__text {
    padding: 0;
  }

  .vc-container {
    border: none;

    .vc-bordered {
      border: none !important;
    }

    .vc-pane-layout {
      border-bottom: 1px solid var(--gray-400);
      margin-bottom: 10px;
    }

    .mh-vc-time-caption {
      position: absolute;
      width: 320px;
      text-align: center;
      font-weight: 500;
      padding-top: 4px;
      @media (max-width: 356px) {
        text-align: left;
        width: unset;
        padding-left: 12px;
      }
    }
    .vc-time-picker {
      .vc-time-icon {
        display: none;
      }
    }

    .vc-date-time {
      .vc-date {
        text-transform: capitalize;
      }

      .vc-time {
        .vc-select:nth-child(3) {
          select {
            cursor: default;
            pointer-events: none;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .mh-period-type-hint {
    width: 245px;
    height: 69px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    //border-top: 1px solid var(--gray-400);
    //border-bottom: 1px solid var(--gray-400);
    @media (max-width: $mh-mobile-bp) {
      display: none;
      border: unset;
    }
  }

  .mh-caption {
    font-size: 0.75rem !important;
    font-weight: 400;
    opacity: 0.58;
    vertical-align: middle;
    height: 100%;
    display: inline-block;
    margin-bottom: 0 !important;
    padding: 5px 10px 10px;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    font-family: "Roboto", sans-serif !important;
  }

  .mh-period-type {
    max-width: 185px;
    margin-left: 15px;
    margin-right: 20px;
    padding-top: 3px !important;
    float: left;
    margin-top: 0 !important;

    .v-messages {
      display: none;
    }

    @media (max-width: $mh-mobile-bp) {
      float: unset;
      border: unset;
    }
  }

  .w-full {
    width: 100%;
  }

  .weekdays_line span {
    margin: 2px 4px 0 4px;
    padding: 4px;
  }

  .vc-time-picker.vc-bordered {
    float: left;
    border-bottom: 1px solid var(--gray-400);
    min-width: 158px;
    @media (max-width: $mh-mobile-bp) {
      border: unset;
      width: 50%;
    }
  }

  .vc-mh-weekday {
    color: var(--gray-700);
    letter-spacing: var(--tracking-wide);
  }

  .vc-mh-month {
    color: var(--accent-600);
    text-transform: capitalize;
    margin-left: 4px;
    @if $VUE_APP_ALTERNATE_UI == true {
      color: #fe4e00;
    }
  }

  .vc-mh-day {
    color: var(--accent-600);
    margin-left: 4px;
    @if $VUE_APP_ALTERNATE_UI == true {
      color: #fe4e00;
    }
  }

  .vc-mh-year {
    color: var(--gray-500);
    margin-left: 4px;
  }

  .mh-preset-btns {
    border-top: 1px solid var(--gray-400);
    padding: 8px 8px 4px 8px;

    &.footer-btns {
      display: flex;
      align-items: center;
      padding: 18px 8px;
    }
  }

  .mh-day-btns {
    border-top: 1px solid var(--gray-400);
    margin-top: 5px;

    .row {
      @media (max-width: $mh-mobile-bp) {
        flex-direction: column;
      }
    }
  }

  .mh-first-col {
    margin-left: 12px;
    @media (max-width: $mh-mobile-bp) {
      margin-left: 0;
      padding-left: 25px;
      padding-right: 25px;
      @media (max-width: 375px) {
        padding-bottom: 0;
      }
    }
  }

  .v-menu__content {
    @media (max-width: 400px) {
      max-width: 100% !important;
      @media (max-width: 380px) {
        left: 0 !important;
      }
    }
  }

  .mh-mobile-row {
    @media (max-width: 360px) {
      margin-left: 4px !important;
      .v-radio,
      .v-input--selection-controls__input {
        margin-right: 4px !important;
      }
    }
  }
}

.mh-dropdown-slot {
  .v-input__slot {
    cursor: pointer !important;
  }
}

.vc-arrow {
  width: 32px;
  height: 32px;
  background: #ffffff !important;
  color: #000000 !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
  &:hover {
    /* teal/lighten-5 */

    background: #e0f2f1 !important;
    border-width: 0 !important  ;
  }
  svg {
    display: none !important;
  }

  &:after {
    //arrow
    width: 13px;
    height: 20px;
    color: #000000;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
  }

  &.is-left {
    &:after {
      content: "\F004D";
    }
  }
  &.is-right {
    &:after {
      content: "\F0054";
    }
  }
}
</style>
