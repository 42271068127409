<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <main-filters
          :reportRequest="reportRequest"
          :page="pageData"
          :aggregateVisible="false"
          :loading="chart_loading"
          :show-hourly="false"
          @cubeDataClick="cubeData"
          @graphType="graphTypeClick"
          @calendarRange="(val) => (this.range = val)"
          @cities="(val) => (this.cities = val)"
          @tvList="(val) => (this.channels = val)"
          @sources="(val) => (this.sources = val)"
          @shows="(val) => (this.shows = val)"
          @stopCalc="stopCube"
          @programSelected="(val) => (this.prog = val)"
          :maxChannels="12"
        >
        </main-filters>
        <v-card
          outlined
          class="mt-2"
          v-if="chart_loading === false"
          id="highcharts_png"
        >
          <v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            {{ chartTitle }}
            <v-spacer />
            <v-btn-toggle
              borderless
              v-model="toggle_graphType"
              mandatory
              dense
              v-if="this.indicator !== 'All'"
              data-html2canvas-ignore
            >
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                <v-icon
                  dense
                  :color="visionType == 'graph' ? 'purple darken-2' : ''"
                  class="pr-1"
                  >mdi-chart-line</v-icon
                >График
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                <v-icon
                  dense
                  :color="visionType != 'graph' ? 'green darken-4' : ''"
                  class="pr-1"
                  >mdi-table</v-icon
                >Таблица
              </v-btn>
            </v-btn-toggle>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  elevation="0"
                  class="mh-secondary-border ml-2"
                  style="text-transform: none !important"
                  v-bind="attrs"
                  v-on="on"
                  data-html2canvas-ignore
                >
                  <v-icon small>mdi-download</v-icon>Скачать</v-btn
                >
              </template>
              <v-list dense>
                <v-list-item dense link @click="toExcel" v-if="!isAlternate">
                  <v-list-item-title>Таблица (xlsx)</v-list-item-title>
                </v-list-item>
                <v-list-item dense link @click="saveChartToPng">
                  <v-list-item-title>Изображение (png)</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text>
            <v-col class="ml-0 pl-0">
              <div class="chartTitle">{{ subtitle }}</div>
              <div id="highchartDiv">
                <div v-for="chart in chartOptions" :key="chart.id">
                  <div class="chartSubtitle">
                    {{ chart.series[0].subtitle }}
                  </div>
                  <span class="chartInfoMessage mb-4" data-html2canvas-ignore v-if="range.dayCount == 0">
                    <v-icon small color="#FFA91B" class="pr-1"
                      >mdi-lightbulb</v-icon
                    >
                      Отчет пуст, так как в календаре заданы конфликтные параметры. Возможно, вы выбрали период, в который не входят выбранные дни недели. Уточните параметры в календаре и постройте отчет снова.
                  </span>                  
                  <highcharts
                    v-if="chart.series[0].data.length && visionType === 'graph'"
                    :options="chart"
                  ></highcharts>
                </div>
              </div>
              <v-btn
                v-if="false && visionType !== 'graph'"
                @click="copyToClipboard('dt-table')"
                >Копировать в буфер</v-btn
              >
              <v-btn v-if="false && visionType !== 'graph'" @click="toExcel()"
                >Xlsx</v-btn
              >
              <v-row align="center" data-html2canvas-ignore>
                <v-text-field
                  v-if="visionType !== 'graph'"
                  v-model="tableSearch"
                  label="Поиск по таблице"
                  class="mx-4"
                ></v-text-field>
                <v-tooltip bottom v-if="visionType !== 'graph'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="rounded grey lighten-4"
                      active-class="rounded"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      v-if="visionType !== 'graph'"
                      @click="copyToClipboard('dt-table')"
                      ><v-icon class="rounded">mdi-content-copy</v-icon></v-btn
                    >
                  </template>
                  <span>Скопировать в буфер</span>
                </v-tooltip>

                <v-snackbar
                  v-model="snackbarCopy"
                  :timeout="1000"
                  right
                  color="success"
                  class="mb-16"
                >
                  Данные скопированы
                </v-snackbar>
              </v-row>
              <v-data-table
                id="dt-table"
                v-if="visionType !== 'graph'"
                :headers="tableHeaders"
                :items="tableItems()"
                :hide-default-footer="true"
                multi-sort
                :search="tableSearch"
                disable-pagination
              >
                <template v-slot:item.y="{ item }">
                  <span>{{ item.y.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y1="{ item }">
                  <span>{{ item.y1.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y2="{ item }">
                  <span>{{ item.y2.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y3="{ item }">
                  <span>{{ item.y3.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y4="{ item }">
                  <span>{{ item.y4.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y5="{ item }">
                  <span>{{ item.y5.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.aud="{ item }">
                  <span>{{ item.aud.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.share="{ item }">
                  <span>{{ item.share.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.tvr="{ item }">
                  <span>{{ item.tvr.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.reach="{ item }">
                  <span>{{ item.reach.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.reachPercent="{ item }">
                  <span>{{
                    item.reachPercent.toString().replace(/\./g, ",")
                  }}</span>
                </template>
              </v-data-table>
            </v-col>
            <v-row justify="center" v-if="chart_loading === true">
              <v-col justify="center"
                ><v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular></v-col
            ></v-row>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          :height="heightChart"
          v-else-if="chart_loading === true"
        >
          <base-loader></base-loader>
        </v-card>
        <v-row
          v-else
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
        >
          <pre-report-mount />
        </v-row>
      </v-col>
    </v-row>
    <script
      defer
      type="application/javascript"
      :src="
        'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=bb38ba5b-71c5-463f-b819-bf5757feaad3&event_type=visit&product=IncomeOutcomeReport&order_id=' +
        check_cookie_name('_ym_uid')
      "
    ></script>
    <preset-drawer report="incomes" v-if="!$route.params.uuid"></preset-drawer>
    <video-player
      :shown="videoPlayerOn"
      :dt="videoPlayerDt"
      :videoChannelId="videoPlayerChannel"
      :videoChannelName="videoPlayerChannelName"
      :valueName="videoPlayerValueName"
      :values="videoPlayerValues"
      :program="videoPlayerProgram"
      @close="videoPlayerOn = false"
    />
  </v-container>
</template>
<style lang="scss"></style>
<script>
import MainFilters from "@/components/MainFilters.vue";
import axios from "axios";
import { Chart } from "highcharts-vue";
import moment from "moment";
import html2canvas from "html2canvas";
import uuidv4 from "uuid/v4";
import BaseLoader from "@/components/BaseLoader.vue";
import PresetDrawer from "@/components/PresetDrawer.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { CONSTS } from "../services/constants";
import PreReportMount from "@/components/PreReportMount.vue";
import Utils from "../services/utils";

export default {
  name: "IncomesOutcomes",
  components: {
    BaseLoader,
    MainFilters,
    highcharts: Chart,
    PresetDrawer,
    VideoPlayer,
    PreReportMount,
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    if (!this.hasPriv) return this.$router.push("/");
    if (this.$route.params.uuid) {
      this.chart_loading = true;
      this.chartWithUuid();
    }
    window.addEventListener("resize", this.handleResize, true);
  },
  methods: {
    handleResize() {
      this.contentWidth = document.getElementById("highchartDiv").clientWidth;
      this.contentWidth -= 70;
    },
    prepareValuesData(categories, series, series2 = null) {
      const values = {};
      for (let i = 0; i < categories.length; i++) {
        const key = moment(categories[i], "DD.MM.YYYY HH:mm").format(
          "YYYY-MM-DD HH:mm"
        );
        const newValues = [series.data[i].y];
        if (series2) {
          // приходы-уходы - 2 значения
          newValues.push(series2.data[i].y);
        }
        values[key] = newValues;
      }
      return values;
    },
    playPoint(videoChannelId, dt, channelName, valueName, programs, values) {
      console.log(
        `Play: ${videoChannelId} (${channelName}) time: ${dt} ${this.videoPlayerOn}`
      );

      if (this.videoPlayerOn) {
        this.videoPlayerOn = false;
        return setTimeout(() =>
          this.playPoint(
            videoChannelId,
            dt,
            channelName,
            valueName,
            programs,
            values
          )
        );
      }
      this.videoPlayerChannel = videoChannelId;
      this.videoPlayerChannelName = channelName;
      this.videoPlayerValueName = valueName;
      this.videoPlayerDt = dt;
      this.videoPlayerProgram = programs;
      this.videoPlayerValues = values;
      this.videoPlayerOn = true;
    },
    async chartWithUuid() {
      console.log(this.$route.params.uuid);
      if (!this.$route.params.uuid) return;
      this.chart_loading = true;
      let res = await axios
        .post(`result/${this.$route.params.uuid}`)
        .catch(function () {
          this.chart_loading = null;
          this.isStoped = true;
          alert("Произошла ошибка");
        });
      if (res.data.message == "not ready") {
        setTimeout(() => {
          if (!this.isStoped) this.chartWithUuid();
        }, 1000);
        return;
      }

      if (this.isStoped) return;
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      this.reportUuid = this.$route.params.uuid;
      this.reportRequest = res.data.request;
      console.log(this.reportRequest);
      this.filter_loading = false;
      this.report = res.data.request["report"];
      this.selectedCityId = res.data.request["geo"];
      this.selectedChannelsIds = res.data.request["tv"]?.split(",");
      this.selectedShowsIds = [res.data.request["programIds"]]; // ?? wtf no field
      this.selectedSourceId = res.data.request["source_id"];
      this.helper = res.data.request["helper"];
      this.coef = res.data.request["useCoeff"];
      this.filter_loading = false;
      this.range.through = res.data.request["through"] == true;
      this.range.weekdays = res.data.request["weekdays"].split(",");
      this.range.start = `${res.data.request.startDate}T${res.data.request.startTime}:00.000`;
      this.range.end = `${res.data.request.endDate}T${res.data.request.endTime}:00.000`;
      this.shows = [];
      if (Array.isArray(this.reportRequest.programs_obj)) {
        this.shows = this.reportRequest.programs_obj;
        this.selectedShowsIds = [this.shows?.[0].program_id];
      }

      this.data = res.data.result;
      //this.aggregatedData = this.aggregateData(this.data, 15);
      this.data.load = true;
      this.chart_loading = false;
      setTimeout(() => this.handleResize());
    },

    exportBtnClick(e) {
      switch (e) {
        case "png":
          this.saveChartToPng();
          break;
        case "svg":
          break;

        case "pdf":
          break;

        default:
          //xls
          this.toExcel();
          break;
      }
    },

    async toExcel() {
      const reportName = "ShowsLoss";
      const start = moment(this.range.start).locale("ru").format("DD.MM.YYYY");
      const end = moment(this.range.end).locale("ru").format("DD.MM.YYYY");
      const startTime = moment(this.range.start).locale("ru").format("HH:mm");
      const endTime = moment(this.range.end).locale("ru").format("HH:mm");
      const city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });

      const source = this.sources.find((el) => {
        return el.id === this.selectedSourceId;
      });

      const channels = this.channels.filter((el) => {
        return this.selectedChannelsIds.includes(el.id);
      });

      const shows = this.shows?.filter((el) => {
        return this.selectedShowsIds.includes(el.id);
      });

      let headers = [];

      for (let i = 0; i < this.tableHeaders.length; i++) {
        headers.push({ name: this.tableHeaders[i].text });
      }
      let tableItems = this.tableItems();
      let deleteDate = !(this.report === "hour"
        ? this.aggregate === "Day"
        : false);
      for (let i = 0; i < tableItems.length; i++) {
        if (deleteDate) {
          delete tableItems[i].date;
        }
      }

      const params = [
        "Id отчета: " + this.reportUuid,
        "Время: " + this.reportTime,
        "Период: " + `${start} ${startTime} - ${end} ${endTime}`,
        "Дни недели: " + this.range.weekdays.sort(),
        "Вычисление периода: " + this.range.through,
        "Источник: " + source.name,
        "Справочник: " + this.helper,
        "Коэффициенты: " + this.coef,
        "Регион: " + city.name,
      ];
      if (channels.length > 1) {
        params.push(
          `Телеканалы: ${channels.map((item) => item.export_name).join(", ")}`
        );
      } else {
        if (channels.length === 1)
          params.push(`Телеканал: ${channels[0].export_name}`);
      }
      if (shows.length > 1) {
        params.push(
          `Телепередачи: ${shows.map((item) => item.name).join(", ")}`
        );
      } else {
        if (shows.length === 1) params.push(`Телепередача: ${shows[0].name}`);
      }

      let req = {
        jsonArray: [[...headers], ...tableItems],
        params,
        reportName: reportName,
        indicator: this.indicator,
      };

      let response = await axios.post("jsonToExcel", req, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.filename}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    async saveChartToPng() {
      const canvas = await html2canvas(
        document.querySelector(`#highcharts_png`), { scale: 1 }
      );
      Utils.saveAs(canvas.toDataURL(), `${this.filename}.png`);
    },
    stopCube() {
      this.chart_loading = null;
      this.isStoped = true;
      this.axiosCancel();
    },
    copyToClipboard(containerid) {
      var range = document.createRange();
      let containerNode = document.getElementById(containerid); //// this part
      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.snackbarCopy = true;
    },
    graphTypeClick(isGraph) {
      this.visionType = isGraph ? "graph" : "table";
    },
    tableItems() {
      let items = [];
      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.categories.length;
        for (let i = 0; i < length; i++) {
          items.push({
            num: j * length + (i + 1),
            time: moment(chart.xAxis.categories[i], "DD.MM.YYYY HH:mmZ").format(
              "DD.MM.YYYY HH:mm"
            ),
            y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
            y2: chart.series[1].data[i].y, //.toString().replace(/\./g, ","),
          });
        }
      }
      return items;
    },
    tableItemsShow() {
      let items = [];
      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.categories.length;
        for (let i = 0; i < length; i++) {
          items.push({
            num: j * length + (i + 1),
            time: moment(chart.xAxis.categories[i], "DD.MM.YYYY HH:mmZ").format(
              "DD.MM.YYYY HH:mm"
            ),
            y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
            y2: chart.series[1].data[i].y.toString().replace(/\./g, ","),
          });
        }
      }
      return items;
    },

    async cubeData(req) {
      this.chart_loading = true;
      this.isStoped = false;
      this.reportUuid = uuidv4();
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      req.set("uuid", this.reportUuid);
      req.set("indicator", "IncomesOutcomes");
      req.set("detalisation", 1);
      req.set("requestedReport", this.reportName);
      //TODO replace api url

      let cubeObj = {};
      req.forEach(function (value, key) {
        cubeObj[key] = value;
      });
      cubeObj["email"] = this.$store.getters.StateUser.email;

      cubeObj["action"] = "MakeReport";
      // eslint-disable-next-line no-undef
      let reportUniqid = Utils.generateUID();
      cubeObj["uniqid"] = reportUniqid;
      cubeObj["uid"] = this.$store.getters.StateUser.uid;
      cubeObj["ym_uid"] = this.check_cookie_name("_ym_uid");
      // eslint-disable-next-line no-undef
      if (process.env.VUE_APP_ALTERNATE_UI != "true") mh_px(300, cubeObj);
      // eslint-disable-next-line no-unused-vars
      let timeReq = performance.now();
      const CancelToken = axios.CancelToken;
      let that = this;
      let res = await axios
        .post("reports/cubedata", req, {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            that.axiosCancel = c;
          }),
        })
        .catch(function () {
          this.chart_loading = null;
          alert("Произошла ошибка");
          return;
        });
      if (res.data.message === "The report has been queued") {
        this.reportRequest = res.data.request;
        // window.location.href = this.$route.path + "/" + res.data.uuid;
        this.$router.push(this.$route.path + "/" + res.data.uuid);
        this.chartWithUuid();
        return;
      }
      if (this.isStoped) return;

      this.filter_loading = false;
      this.report = req.get("report_type");
      this.selectedCityId = req.get("geo");
      this.selectedChannelsIds = req.get("tv")?.split(",");
      this.selectedShowsIds = [req.get("programIds")];
      this.selectedSourceId = req.get("source_id");
      this.helper = req.get("helper");
      this.coef = req.get("useCoeff");
      this.chart_loading = false;
      timeReq = performance.now() - timeReq;
      if (process.env.VUE_APP_ALTERNATE_UI != "true")
        // eslint-disable-next-line no-undef
        mh_px(300, {
          uniqid: reportUniqid,
          action: "DataReport",
          //email: this.$store.getters.StateUser.email,
          uid: this.$store.getters.StateUser.uid,
          msecs: timeReq,
        });
      this.indicator = req.get("indicator");
      this.data = res.data;
      //this.aggregatedData = this.aggregateData(this.data, 15); //оказывается это в другой отчет
      this.data.load = true;
      setTimeout(() => this.handleResize());
    },
  },
  data: () => ({
    reportRequest: {},
    contentWidth: null,
    axiosCancel: null,
    reportName: "incomes",
    videoPlayerOn: false, // если true - открыт диалог проигрывания видео
    videoPlayerChannel: null,
    videoPlayerChannelName: null,
    videoPlayerValueName: null, // название отображаемого показтеля
    videoPlayerDt: null,
    videoPlayerProgram: null, // расписание передач
    videoPlayerValues: null, // помнутные значения выбранного показателя
    snackbarCopy: false,
    range: {},
    cities: [],
    prog: {},
    toggle_graphType: 0,
    report: null,
    selectedCityId: null,
    tableSearch: null,
    isStoped: false,
    visionType: "graph",
    filter_loading: false,
    data: {},
    indicator: "",
    chart_loading: null,
    pageData: {
      title: "Приток-отток зрителей",
      type: "incomes",
      many_channel: false,
    },
  }),
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    hasPriv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_incomes
      ];
    },
    filename() {
      const city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });

      const channels = this.channels.filter((el) => {
        return this.selectedChannelsIds.includes(el.id);
      });
      const shows = this.shows?.filter((el) => {
        return this.selectedShowsIds.includes(el.id);
      });
      const filename = Utils.createFilename({
        range: this.range,
        cities: city,
        channels: channels,
        shows: shows,
        indicator: this.chartTitle,
        reportTime: this.reportTime,
      });
      return filename;
    },
    subtitle() {
      let start = moment(this.range.start).locale("ru").format("DD MMM YYYY");
      let end = moment(this.range.end).locale("ru").format("DD MMM YYYY");
      let startTime = moment(this.range.start).locale("ru").format("HH:mm");
      let endTime = moment(this.range.end).locale("ru").format("HH:mm");
      let city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });
      return `${city.name}, ${start} - ${end}, ${startTime} - ${endTime}`;
    },
    tableHeaders() {
      let headers = [
        {
          text: "№",
          value: "num",
          visible: true,
        },
        {
          text: "Дата и время",
          value: "time",
          visible: true,
        },
        {
          text: "Приток",
          value: "y",
          visible: true,
        },
        {
          text: "Отток",
          value: "y2",
          visible: true,
        },
      ];

      return headers.filter((el) => {
        return el.visible;
      });
    },

    heightChart() {
      let h = 500;
      return h;
    },
    chartOptions() {
      const that = this;
      let charts = [];
      let data = this.data.charts ?? [];
      // let data = this.aggregatedData.charts ?? [];
      for (let i = 0; i < data.length; i++) {
        let categories = data[i].categories;
        let catLength = categories.length;
        const categoriesDays = (catLength / 1240) | 0;
        let ticks = 24,
          mult = 60 * (categoriesDays + 1);
        if (categoriesDays == 0) {
          ticks = 12;
          mult = (catLength / 8) | 0;
          if (catLength <= 15) {
            ticks = 15;
            mult = 1;
          }
        }
        if (this.isMobile) {
          mult *= 4;
          ticks = 6;
        }
        const tickPositions = new Array(ticks)
          .fill()
          .map((item, i) => i * mult);
        charts.push({
          id: i,
          chart: {
            zoomType: "x",
            type: "column",
            events: {},
            height: this.heightChart,
          },
          title: { text: "" },
          colors: CONSTS.CHART_INOUT_COLORS,
          subtitle: { text: "" },
          exporting: {
            sourceWidth: 1200,
            chartOptions: {
              chart: {
                events: {},
              },
            },
          },
          xAxis: {
            tickPositions,
            tickWidth: 1,
            categories,
            labels: {
              rotation: 0,
              useHTML: true,
              formatter: function () {
                const value = this.value;
                if (this.isFirst) {
                  return `
                  <div style="padding-top: 5px">
                    <span style="padding-right: 5px; margin-left: -20px; font-size: 1.1em; font-weight: bold;">час\u00a0</span>
                    <b style="font-size: 1.1em;">${value?.substring(11, 13)}</b>
                    <br>
                    <span style="padding-right: 6px; margin-left: -20px;">мин\u00a0</span>${value?.substring(
                      14,
                      16
                    )}
                  </div>  
                  `;
                }
                return `
                  <div style="padding-top: 4px; margin-left: 10px;">
                  <span style=""><b style="font-size: 1.1em;">${value?.substring(
                    11,
                    13
                  )}</b></span>
                  <br>
                  <span style="">${value?.substring(14, 16)}</span>  
                  </div>
                `;
              },
            },
          },
          yAxis: {
            reversedStacks: false,
            title: { text: "Проценты" },
            max: 20,
            min: -20,
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#808080",
              },
            ],
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
              },
            },
            column: {
              grouping: false,
            },
            series: {
              events: {
                click: function (event) {
                  const videoChannelId = data[i].series[0].video_channel_id;
                  const channelName = data[i].series[0].subtitle ?? null;
                  const programs = data[i].programs;
                  const values = that.prepareValuesData(
                    categories,
                    data[i].series[0],
                    data[i].series[1]
                  );
                  const dt = moment(
                    event.srcElement.point.category + ":00Z",
                    "DD.MM.YYYY HH:mm:ssZ"
                  )
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss");
                  that.playPoint(
                    videoChannelId,
                    dt,
                    channelName,
                    "inout",
                    programs,
                    values
                  );
                },
              },
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              dataLabels: { enabled: false },
              turboThreshold: 0,
            },
            area: {
              marker: {
                radius: 2,
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
            },
          },
          tooltip: {
            valueSuffix: " %",
            positioner: function (labelWidth, labelHeight, point) {
              const maxWidth = that.contentWidth + 48;
              let tooltipX = point.plotX;
              let tooltipY = point.plotY;
              if (tooltipY + labelHeight > 550) tooltipY = 550 - labelHeight;
              if (tooltipX > maxWidth - labelWidth)
                tooltipX = maxWidth - labelWidth;
              return {
                x: tooltipX,
                y: tooltipY,
              };
            },
          },
          legend: {
            useHTML: true,
            labelFormatter: function () {
              if (this.name == "Приток") return this.name;
              const video_channel_id = this.options.video_channel_id;
              const icon = video_channel_id ? "&#xF0567;" : "&#xF0568;";
              const color = video_channel_id ? "#4CAF50" : "#BDBDBD";
              const str = `${this.name}&nbsp;&nbsp;<span style='color: ${color}; font-size:1.3em; font-family: "Material Design Icons"'>${icon}</span>`;
              return str;
            },
          },
          series: data[i].series,
        });
      }
      return charts;
    },
    chartTitle() {
      if (this.prog) {
        return this.prog.name;
      }
      return "Приток-отток";
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
  },
  watch: {
    indicator() {
      if (this.indicator === "All") {
        this.toggle_graphType = 1;
      }
    },
    toggle_graphType() {
      this.visionType = this.toggle_graphType === 0 ? "graph" : "table";
    },
  },
};
</script>
