<template>
  <v-menu
      offset-y
      v-model="opened"
      :close-on-content-click="false"
      @input="searchStr = ''"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :label="label"
          :hide-details="isMobile ? false : 'auto'"
          outlined
          class="mh-dropdown-slot"
          dense
          :append-icon="opened ? 'mdi-menu-up' : 'mdi-menu-down'"
          v-on:click:append="opened = true"
          :disabled="disabled"
          readonly
          :value="formatTextFieldValue()"
          v-bind="attrs"
          v-on="on"
      ><template v-slot:append-outer>
        <v-tooltip
            top
            content-class="text-center"
            v-if="invalidItems.length > 0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="warning"
                style="position: absolute; right: 50px"
                v-bind="attrs"
                v-on="on"
            >mdi-alert</v-icon
            >
          </template>
          <span class="caption"
          >Некоторые телеканалы недоступны в выбранном городе.<br />Отчет по
              ним не будет построен</span
          >
        </v-tooltip>
      </template></v-text-field
      >
    </template>
    <v-card outlined elevation="0">
      <v-card-title class="pb-0">
        <v-text-field
            class="mb-2"
            clearable
            label="Поиск"
            hide-details="auto"
            autofocus
            outlined
            dense
            v-model="searchStr"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="scroll">
        <v-list
            v-if="
            selectedAll ||
            (showSelectAll && itemsFiltered.length > 0 && multiple)
          "
            dense
        >
          <v-list-item dense link @click="selectAll">
            <v-list-item-action>
              <v-checkbox :input-value="selectedAll"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                  selectedAll ? "Выбраны все" : "Выбрать все"
                }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-virtual-scroll
            bench="2"
            :items="itemsFiltered"
            :item-height="50"
            :height="
            itemsFiltered.length < 5 ? itemsFiltered.length * 60 + 50 : 300 + 50
          "
        >
          <template v-slot:default="{ item }">
            <v-list-item
                v-if="typeof item === 'object'"
                dense
                link
                @click="selectItem(item[itemValue], item.invalid)"
                :class="
                isSelectOneItem(item[itemValue])
                  ? 'blue lighten-5'
                  : item.invalid
                  ? 'orange lighten-5'
                  : ''
              "
            >
              <v-list-item-action>
                <v-checkbox
                    v-if="multiple"
                    readonly
                    :input-value="
                    item.invalid ||
                    (selectedItems === null
                      ? false
                      : selectedItems.includes(item[itemValue]))
                  "
                    @input="selectItem(item[itemValue], item.invalid)"
                ></v-checkbox>
                <v-icon color="primary" v-if="isSelectOneItem(item[itemValue])">
                  mdi-check
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="item-text">{{
                    item[itemText]
                  }}</v-list-item-title>
                <p v-if="item.invalid" class="caption invalid-item" style="">
                  недоступен в этом городе
                </p>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else dense>
              <v-divider v-if="item === '%divider%'"></v-divider>

              <v-list-item-content
                  @click="selectChildren(item)"
                  :class="getParentClass(item) + ' item-group'"
                  v-else
              >
                {{ item }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
        <v-list v-if="itemsFiltered.length === 0 && !selectedAll" dense>
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>Ничего не найдено</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="multiple">
        <v-col style="border-top: 1px solid rgb(231, 231, 231)" class="pt-6">
          <v-row class="justify-space-between">
            <v-btn
                color="grey lighten-4"
                elevation="0"
                small
                @click="clearDropdown"
            >Очистить</v-btn
            >
            <v-btn
                small
                color="primary"
                class="mh-primary-border"
                elevation="0"
                @click="
                opened = false;
                searchStr = '';
              "
            >Применить</v-btn
            >
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import retypeLangRu from "../services/retypeLangRu";
import retypeLangEn from "../services/retypeLangEn";
import { mapActions } from "vuex";
export default {
  name: "MhDropDown",
  props: {
    value: {
      type: [Object, String, Array],
      default: function () {
        return null;
      },
    },
    label: {
      type: String,
      default: "Поле",
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemGroup: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    items: {
      type: [Array, Object],
      default: function () {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    selectAllToNull: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCity: {
      type: Boolean,
      default: false,
    },
    isTV: {
      type: Boolean,
      default: false,
    },
    parentClickable: {
      type: Boolean,
      default: false,
    },
    highlightParents: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      searchStr: "",
      selectedAll: false,
      opened: false,
      invalidItems: [],
      list: [],
      last5: [],
      selectedItems: this.value,
    };
  },
  computed: {
    last5tvs() {
      return this.$store.getters.last5tvs;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    itemsFiltered() {
      let search = this.searchStr;
      let queryTextRu = "";
      let queryTextEn = "";
      let last5 = this.last5;
      let items = this.items;
      if(items.length) {
        if (this.isCity || this.isTV) {
          let pre_items= items.map((el) => el[this.itemValue]);
          let last5new = [];
          let storelast=[];
          if(this.isCity) {
            storelast=this.$store.getters.last5cities;
          }
          if(this.isTV) {
            storelast=this.$store.getters.last5tvs;
          }

          pre_items.forEach((el) => {
            if (storelast.includes(el)) {
              last5new.push(el);
            }
          });
          last5 = last5new;
        }
      }

      if (search) {
        search = search.toLocaleLowerCase();
        queryTextRu = retypeLangRu(search);
        queryTextEn = retypeLangEn(search);
        items = this.items.filter((el) => {
          let itemText = el[this.itemText].toLocaleLowerCase();
          return (
              itemText.includes(queryTextRu) || itemText.includes(queryTextEn)
          );
        });
      }
      let resultList = [];
      if (this.selectedItems) {
        resultList = ["Выбрано"];
        if (this.multiple) {
          resultList = [
            resultList[0],

            ...this.items.filter((el) => {
              if (search) {
                let itemText = el[this.itemText].toLocaleLowerCase();
                return (
                    this.selectedItems.includes(el[this.itemValue]) &&
                    (itemText.includes(queryTextRu) ||
                        itemText.includes(queryTextEn))
                );
              } else {
                return this.selectedItems.includes(el[this.itemValue]);
              }
            }),
          ];
          if (this.isTV && this.invalidItems) {
            resultList = resultList.concat(this.invalidItems);
          }
        } else {
          let itemVybrano = this.items.find(
              (el) => el[this.itemValue] == this.selectedItems
          );
          if (search) {
            let itemText = itemVybrano[this.itemText].toLocaleLowerCase();
            if (
                itemText.includes(queryTextRu) ||
                itemText.includes(queryTextEn)
            ) {
              resultList.push(itemVybrano);
            }
          } else {
            resultList.push(itemVybrano);
          }
        }
        if (resultList.length == 1) {
          resultList = [];
        }
      } else {
        if (this.isTV && this.invalidItems && this.invalidItems.length > 0) {
          resultList = ["Выбрано"];
          resultList = resultList.concat(this.invalidItems);
        }
      }

      if (last5.length > 0 && (this.isCity || this.isTV)) {
        if (search.length == 0) {
          resultList.push("Последние");
          let that = this;
          last5.forEach((lastEl) => {
            if (lastEl != this.selectedItems)
              resultList.push(
                  that.items.find((el) => el[this.itemValue] == lastEl)
              );
          });
        }
      }
      if (this.itemGroup !== "") {
        items.forEach((el) => {
          if (!resultList.includes(el[this.itemGroup])) {
            resultList.push(el[this.itemGroup]);
          }
          if (this.selectedItems) {
            if (!this.selectedItems.includes(el[this.itemValue])) {
              resultList.push(el);
            }
          } else resultList.push(el);
        });
      } else {
        if (resultList[0] === "Выбрано") {
          resultList.push("%divider%");
        }
        items.forEach((el) => {
          if (this.selectedItems) {
            if (!this.selectedItems.includes(el[this.itemValue])) {
              resultList.push(el);
            }
          } else resultList.push(el);
        });
      }
      return resultList;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  mounted() {
    if (this.isCity) {
      this.last5 = this.$store.getters.last5cities;
    }
    if (this.isTV) {
      this.last5 = this.$store.getters.last5tvs;
      this.$eventHub.$on("invalidTVSbyCity", this.addInvalidSelectedItems);
    }
  },
  beforeDestroy() {
    if (this.isTV) {
      this.$eventHub.$off("invalidTVSbyCity");
    }
  },
  methods: {
    ...mapActions([
      "last5cities",
      //"last5tvs", - вызывает ошибку, что делает ?
      "storeLastTvs",
      "storeLastCities",
    ]),
    getParentClass(name) {
      if (name == "Выбрано") return "";
      if (!this.parentClickable) return "";
      let className = "parentClickable ";
      if (this.highlightParents.includes(name)) className += "parentSelected";
      return className;
    },
    selectChildren(item) {
      if (!this.parentClickable) return;
      this.$emit("children", item);
    },
    addInvalidSelectedItems(items) {
      if (this.isTV) {
        let newInvalidItems = [];
        let notInvalidItems = [];
        for (let item of items) {
          item.invalid = true;
          newInvalidItems.push(item);
        }
        let invalidParents = this.invalidItems.map((el) => {
          return el.parent;
        });
        let that = this;
        this.items.forEach((el) => {
          if (invalidParents.includes(el.parent)) {
            notInvalidItems.push(el[that.itemValue]);
          }
        });
        if (this.multiple) {
          notInvalidItems.forEach((el) => {
            this.selectItem(el);
          });
        }

        this.invalidItems = newInvalidItems;
      }
    },
    isSelectOneItem(val) {
      if (!this.multiple) {
        if (val) return this.selectedItems == val;
      }
      return false;
    },
    clearDropdown() {
      this.selectedAll = true;
      this.selectAll();
      this.selectedItems = null;
      this.invalidItems = [];
      this.searchStr = "";
    },
    selectAll() {
      // if (val) {
      //   this.selectedAll = true;
      // } else {
      this.selectedAll = !this.selectedAll;
      // }
      if (this.selectedAll) {
        this.selectedItems = [];
        this.items.forEach((item) => {
          this.selectedItems.push(item[this.itemValue]);
        });
      } else {
        this.selectedItems = null;
      }
      if (this.selectAllToNull) {
        this.selectedItems = null;
      }
      this.$emit("all", this.selectedAll);
    },
    async selectItem(id, isInvalid) {
      if (this.multiple) {
        if (isInvalid) {
          let invIndex = this.invalidItems.findIndex(
              (el) => el[this.itemValue] == id
          );
          if (invIndex != -1) {
            this.invalidItems.splice(invIndex, 1);
            return;
          }
        }
        if (this.selectedItems === null) {
          this.selectedItems = [id];
          if (this.isTV) {
            let ind = this.last5.indexOf(id);
            if (ind > -1) {
              this.last5.splice(ind, 1);
            }
            await this.storeLastTvs(this.last5);
          }
        } else {
          let index = this.selectedItems.indexOf(id);
          if (index !== -1) {
            let toLast = this.selectedItems.splice(index, 1);
            toLast = toLast[0];
            if (this.isTV) {
              let ind = this.last5.indexOf(toLast);
              if (ind > -1) {
                this.last5.splice(ind, 1);
              }
              if (toLast) {
                this.last5.unshift(toLast);
                if (this.last5.length > 5) {
                  this.last5.pop();
                }
              }
              await this.storeLastTvs(this.last5);
            }
          } else {
            this.selectedItems.push(id);
            if (this.isTV) {
              let ind = this.last5.indexOf(id);
              if (ind > -1) {
                this.last5.splice(ind, 1);
              }
              await this.storeLastTvs(this.last5);
            }
          }
        }
      } else {
        if (this.isCity || this.isTV) {
          let ind = this.last5.indexOf(this.selectedItems);
          if (ind > -1) {
            this.last5.splice(ind, 1);
          }
          if (this.selectedItems) {
            this.last5.unshift(this.selectedItems);
            if (this.last5.length > 5) {
              this.last5.pop();
            }
          }
          if (this.isCity) {
            await this.storeLastCities(this.last5);
          }
          if (this.isTV) {
            await this.storeLastTvs(this.last5);
          }
        }
        if (this.selectedItems == id) {
          //this.selectedItems = null;
        } else {
          this.selectedItems = id;
        }
        this.opened = false;
      }
    },
    formatTextFieldValue() {
      if (this.multiple) {
        if (this.selectedAll) {
          return "Все";
        }
        if (this.selectedItems === null) return null;
        else if (this.selectedItems.length > 1) {
          return "Для отчета: " + this.selectedItems.length;
        } else if (this.selectedItems.length === 1) {
          let index = this.items.findIndex((el) => {
            return el[this.itemValue] === this.selectedItems[0];
          });
          return this.items?.[index]?.[this.itemText];
        } else {
          return null;
        }
      } else {
        if (this.selectedItems !== null) {
          let index = this.items.findIndex((el) => {
            return el[this.itemValue] === this.selectedItems;
          });
          return this.items?.[index]?.[this.itemText];
        } else return null;
      }
    },
  },
  watch: {
    last5tvs(newval, oldval) {
      if (newval != oldval) {
        if (this.isTV) {
          this.last5 = this.last5tvs;
        }
      }
    },
    selectedItems() {
      if (this.selectedItems == null) {
        this.$emit("input", null);
      } else if (this.selectedItems.length === 0) {
        this.$emit("input", null);
      } else {
        this.$emit("input", this.selectedItems);
        if (
            this.selectedItems.length === this.items.length &&
            this.showSelectAll &&
            !this.selectAllToNull
        )
          this.selectedAll = true;
      }
    },
    value() {
      this.selectedItems = this.value;
    },
  },
};
</script>

<style lang="scss" >
.item-text {
  font-size: 0.875rem !important;
}
.item-group {
  font-size: 0.8rem !important;
  color: black !important;
  padding: 0 !important;
  opacity: 0.58 !important;
}
.parentClickable {
  text-decoration: underline;
  cursor: pointer;
}
.parentSelected {
  font-weight: bold;
  color: #1976d2;
  text-decoration-color: #1976d2;
  display: inline-block;
}
.caption {
  &.invalid-item {
    position: absolute;
    bottom: -17px;
  }
}
.v-virtual-scroll {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
.mh-dropdown-slot {
  .v-input__slot {
    cursor: pointer !important;
  }
}
.v-application--is-ltr .v-list-item__action {
  margin-right: 8px !important;
}
</style>
