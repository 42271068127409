<template>
 <div>

 </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  mounted() {
    this.$router.push({ name: "indicators" });
  },
};
</script>
