<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="mt-0 text-center justify-center">
        <v-img
          :width="420"
          style="margin: 140px auto 0"
          :src="
            isAlternate
              ? require('@/assets/4042.svg')
              : require('@/assets/404.svg')
          "
        />
      </v-col>

      <v-col class="mb-0 text-center justify-center" cols="12">
        <div class="text-md-body-1 body-2 pt-4 mw-600 mr-auto ml-auto">
          {{ text }}
        </div>
      </v-col>
      <v-col class="mb-4 text-center justify-center" cols="12">
        <div class="pt-0 mt-0 mr-auto ml-auto">
          <v-btn elevation="0" color="primary" @click="toMainPage"
            >На Главную</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.mw-600 {
  max-width: 600px;
}
</style>
<script>
import router from "@/router";

export default {
  name: "InDevelop",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "Такой страницы не существует",
    },
  },
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
  },
  methods: {
    async toMainPage() {
      console.log("main");
      await router.push({ name: "indicators" });
    },
  },
  data: () => ({}),
};
</script>
