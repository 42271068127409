<template>
  <v-dialog persistent v-model="dialogError" max-width="590">
    <v-card class="pb-2">
      <v-card-text class="body-2 black--text pt-6 pb-1">
        <v-row class="justify-start justify-sm-space-around">
          <v-col
            v-if="$vuetify.breakpoint.width > 450"
            cols="auto"
            class="pr-1 pl-1"
          >
            <v-icon large color="error">mdi-information</v-icon>
          </v-col>
          <v-col cols="auto" class="pr-1 pl-1"> </v-col>
          <v-col cols="auto" class="pr-1 pl-1">
            <p v-if="!errorMsg">
              При построении отчета возникла ошибка.<br />
              Пожалуйста, обратитесь в службу поддержки
            </p>
            <p v-if="errorMsg" class="mt-1">
              {{ errorMsg }}
            </p>
          </v-col>
          <v-col cols="auto">
            <v-btn class="black--text" outlined @click="onErrorHandler">
              Понятно
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="scss"></style>

<script>
export default {
  name: "DialogError",
  props: {
  },
  mounted() {
    this.onErrorHandler();
    this.$eventHub.$on("errorEvent", this.onErrorHandler);
  },
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    isError() {
      return this.$store.getters.isError;
    },
    errorMsg() {
      return this.$store.getters.errorMsg;
    },
    errorCode() {
      return this.$store.getters.errorCode;
    },
  },
  methods: {
    async onErrorHandler() {
      await this.$store.dispatch("resetError");
    },
  },
  data: () => ({
    dialogError: false,
  }),
  watch: {
    isError() {
      this.dialogError = this.isError;
    },
  }
};
</script>
