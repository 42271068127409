import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import filters from "./filters";
import users from "./users";
import stories from "./stories";
import loader from "./loader";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    filters,
    users,
    stories,
    loader,
  },
  plugins: [createPersistedState()],
});
