import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import Tilemap from "highcharts/modules/tilemap"
import Wordcloud from "highcharts/modules/wordcloud"
import HighchartsCustomEvents from "highcharts-custom-events";

Maps(Highcharts);
Wordcloud(Highcharts);
Tilemap(Highcharts);
HighchartsCustomEvents(Highcharts);
Highcharts.setOptions({
  lang: {
    contextButtonTitle: 'Контекстного меню графика',
    printChart: 'Распечатать график',
    downloadJPEG: "Скачать JPEG картинку",
    downloadPDF: "Скачать PDF документ",
    downloadPNG: "Скачать PNG картинку",
    downloadSVG: "Скачать SVG векторную картинку",
    loading: 'Загрузка...',
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    shortMonths: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    resetZoom: "Сбросить масштаб",
    resetZoomTitle: null
  },
  credits: {
    enabled: false
  }
});

Vue.prototype.$eventHub = new Vue(); // Global event bus
Vue.config.productionTip = false;
Vue.mixin({
  methods: {
    check_cookie_name: function (name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return "null";
    },
  },
});
new Vue({
  router,
  store,
  vuetify,
  HighchartsVue,
  render: (h) => h(App),
}).$mount("#app");
