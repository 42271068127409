const state = {
  filters: { // Продублировать в LoginForm.vue (и руки оторвать)
    selectedStartDate: null,
    selectedEndDate: null,
    selectedStartTime: null,
    selectedEndTime: null,
    selectedThrough: null,
    selectedWeekdays: null,
    selectedIndicator: null,
    selectedSourceId: null,
    selectCityId: null,
    selectedTVId: null,
    selectProgrammId: null,
    selectedAggregateTv: null,
    selectedAggregateShows: null,
    selectedDetalisation: null,
    selectedDemoAge: null,
    selectedDemoGroup: null,
    selectedShow: null,
    selectedShows: null,
  },
  calendarStart: null,
  calendarEnd: null,
  cities: [],
  tvs: [],
  programs: [],
  lastCities: [],
  lastTVs: [],
};
const getters = {
  last5cities: (state) => state.lastCities,
  last5tvs: (state) => state.lastTVs,
  getFilters: (state) => state.filters,
  getCitiesList: (state) => state.cities,
  getTvsList: (state) => state.tvs,
  getProgramsList: (state) => state.programs,
  getCalendarStart: (state) => {
    if (!state.filters.selectedStartDate) return null;
    return `${state.filters.selectedStartDate} ${state.filters.selectedStartTime}:00`;
  },
  getCalendarEnd: (state) => {
    if (!state.filters.selectedEndDate) return null;
    return `${state.filters.selectedEndDate} ${state.filters.selectedEndTime}:59`;
  },
};
const actions = {
  async storeCalendarStart({ commit }, start) {
    commit, start // заменено на фильтры
    //await commit('setCalendarStart', start)
  },
  async storeCalendarEnd({ commit }, end) {
    commit, end // заменено на фильтры
    //await commit('setCalendarEnd', end);
  },
  async storeFilters({ commit }, filters) {
    let splitSource = [0, "mh"];
    if (filters.selectedSourceId) {
      splitSource = filters.selectedSourceId.split("%");
    }
    let selTVID = 0;
    if (filters.selectedTVId) {
      if (Array.isArray(filters.selectedTVId)) {
        selTVID = filters.selectedTVId.join(",");
      } else selTVID = filters.selectedTVId;
    }
    let settings = {};

    if ("selectedSourceId" in filters)
      settings["selected_source_id"] = splitSource[0];
    if ("selectedSourceId" in filters)
      settings["selected_helper_type"] = splitSource[1];
    if ("selectCityId" in filters)
      settings["selected_geo_id"] = filters.selectCityId ?? 0;
    if ("selectedTVId" in filters)
      settings["selected_tv_id"] = selTVID;
    if ("selectedIndicator" in filters)
      settings["selected_indicator"] = filters.selectedIndicator;
    if ("selectedWeekdays" in filters)
      settings["selected_weekdays"] = filters.selectedWeekdays;
    if ("selectedThrough" in filters)
      settings["selected_through"] = filters.selectedThrough;
    if ("selectedStartDate" in filters)
      settings["selected_start_date"] = filters.selectedStartDate;
    if ("selectedEndDate" in filters)
      settings["selected_end_date"] = filters.selectedEndDate;
    if ("selectedStartTime" in filters)
      settings["selected_start_time"] = filters.selectedStartTime;
    if ("selectedEndTime" in filters)
      settings["selected_end_time"] = filters.selectedEndTime;
    if ("selectedAggregateTv" in filters)
      settings["selected_aggregate_tv"] = filters.selectedAggregateTv;
    if ("selectedAggregateShows" in filters)
      settings["selected_aggregate_shows"] = filters.selectedAggregateShows;
    if ("selectedDetalisation" in filters)
      settings["selected_detalisation"] = filters.selectedDetalisation;
    if ("selectedDemoAge" in filters)
      settings["selected_demo_age"] = filters.selectedDemoAge;
    if ("selectedDemoGroup" in filters)
      settings["selected_demo_group"] = filters.selectedDemoGroup;
    if ("selectedShows" in filters)
      settings["selected_shows_id"] = filters.selectedShows;
    if ("selectedShow" in filters)
      settings["selected_show_id"] = filters.selectedShow;

    await commit("setFilters", filters);
    await commit("setSettings", settings);
  },
  async storeCities({ commit }, cities) {
    await commit("setCitiesList", cities);
  },
  async storeTvs({ commit }, tvs) {
    await commit("setTvsList", tvs);
  },
  async storePrograms({ commit }, programs) {
    await commit("setProgramsList", programs);
  },
  async storeLastTvs({ commit }, tvs) {
    let settingsTV = {};
    settingsTV["last5_tv_id"] = Array.isArray(tvs) ? tvs.join(",") : tvs;
    await commit("setSettings", settingsTV);
    await commit("setLastTvs", tvs);
  },
  async storeLastCities({ commit }, cities) {
    let settingsCity = {};
    settingsCity["last5_city_id"] = Array.isArray(cities)
      ? cities.join(",")
      : cities;
    await commit("setSettings", settingsCity);
    await commit("setLastCities", cities);
  },
};
const mutations = {
  setCalendarStart(state, start) {
    state.calendarStart=start;
  },
  setCalendarEnd (state, end) {
    state.calendarEnd=end;
  },
  setFilters(state, filters) {
    for (const key in filters) {
      state.filters[key] = filters[key];
    }
    //state.filters = filters;
  },
  setCitiesList(state, cities) {
    state.cities = cities;
  },
  setTvsList(state, tvs) {
    state.tvs = tvs; // too much data!!!
    state.tvs = [];
  },
  setLastCities(state, cities) {
    state.lastCities = cities;
  },
  setLastTvs(state, tvs) {
    state.lastTVs = tvs;
  },
  setProgramsList(state, programs) {
    state.programs = programs;
  },
  async clearFilters(state) {
    console.log("clear")
    state.filters = {
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
      selectedThrough: null,
      selectedWeekdays: null,
      selectedIndicator: null,
      selectedSourceId: null,
      selectCityId: null,
      selectedTVId: null,
      selectProgrammId: null,
      selectedDetalisation: null,
      selectedAggregateTv: null,
      selectedAggregateShows: null,
      selectedDemoAge: null,
      selectedDemoGroup: null,
      selectedShow: null,
      selectedShows: null,
    };
    state.cities = [];
    state.tvs = [];
    state.programs = [];

  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
